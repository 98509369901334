export const SIGN_UP = {
  'HEADING': 'Create an account',

  'HEADING_UMI': 'Ready to launch a market test?',

  'HAVE_ACCOUNT': 'Already have an account?',

  'SIGN_UP_LINKEDIN': 'Sign up with LinkedIn',

  'SIGN_UP_EMAIL': 'Sign up with Email Address',

  'BUTTON': 'Create an account',

};
