export const PROJECT_PITCH = {

  'DESCRIPTION': {
    'HEADING': 'Mon projet',
    'SUB_HEADING': 'Il sera présenté aux répondants',
    'TITLE_NOT_FILLED': 'Remplir le titre',
    'SUMMARY_NOT_FILLED': 'Remplir le résumé',
    'SOLUTION_NOT_FILLED': 'Remplir la solution',
    'PROBLEM_NOT_FILLED': 'Remplir la problématique',
    'MEDIA': 'Ajouter des médias',
    'TITLE_FILLED': 'Titre',
    'SUMMARY_FILLED': 'Résumé',
    'MAIN_MEDIA': 'Média principal'
  },

  'QUESTIONNAIRE': {
    'HEADING': 'Mes questions',
    'SUB_HEADING': 'Elles seront posées aux répondants',
    'MESSAGE_HEADING': 'Avez-vous des commentaires à apporter sur votre questionnaire ?',
    'NO_ACCESSIBILITY': 'Non renseignée pour le moment',
    'ALT_DESCRIPTION': 'Description pour les malvoyants'
  },

  'MODAL': {
    'HEADING_SUBMIT': 'Soumission du projet',
    'SUBMIT_TEXT': 'Sélectionnez les langues que vous voulez valider :',
    'SUBMIT_TEXT_BOLD': 'Attention, il ne vous sera plus possible modifier le contenu du projet dans une langue validée.',
    'SUBMIT_TEXT_FOOTER': 'En cliquant sur « confirmer », vous autorisez UMI à diffuser votre questionnaire, anonymement,' +
      ' sur les canaux les plus adaptés pour collecter les feedbacks de votre Test Marché.'
  },

  'POPOVER': {
    'REQUEST_PROOFREADING': 'Une demande de relecture sera envoyée à votre Project manager.'
  }

};
