export const MARKET_TEST_METHODOLOGY = {

  'HEADING': {
    'CONTEXT': 'Context',
    'METHODOLOGY': 'Methodology',
    'RESULT': 'Results'
  },

  'CONTEXT': {
    'DETECTING_MARKET': 'I want to innovate on a topic and I have not yet identified the precise needs of the market players.',

    'VALIDATING_MARKET': 'I have identified potential needs: unresolved issues, specific expectations…',

    'SOURCING_SOLUTIONS': 'I have a problem to solve for which I want to identify solutions / suppliers.',

    'IDENTIFYING_RECEPTIVE': 'I have a solution for which I am looking for potential application markets. ',

    'VALIDATING_INTEREST': 'I have an innovative solution to propose in response to a need. I want to ' +
      'verify / demonstrate that my project is of interest to market players. I have already identified potential markets.',

    'OPTIMIZING': 'I have an innovative project that I have already validated with the market: there is a proven need ' +
      'and confirmation of the relevance of my solution. I now want to adjust my value proposition.',
  },

  'METHODOLOGY': {
    'DETECTING_MARKET': 'I will describe the context and let the market players, who are relevant for this subject, ' +
      'express themselves in order to' +
      '<br>- confirm that there are opportunities for innovation' +
      '<br>- identify their needs in relation to this subject' +
      '<p class="p-top-20 m-no-bottom">I will also:</p>' +
      '- identify current and future trends / areas of development' +
      '<br>- prioritize the first pre-identified issues' +
      '<br>- identify active market players in the field' +
      '<br>- anticipate the potential impact on the relevant markets' +
      '<br>- …' +
      '<br>At this stage, I will not present a solution.',

    'VALIDATING_MARKET': 'I will describe these potential needs and present them to relevant market players to confirm if ' +
      'they exist and their criticality.' +
      '<p class="p-top-20 m-no-bottom">I will also:</p>' +
      '- rank their needs' +
      '<br>- identify the solutions they use or know about' +
      '<br>- know the limits of these solutions according to them' +
      '<br>- …' +
      '<br>At this stage, I will not present a solution.',

    'SOURCING_SOLUTIONS': 'I will describe my expectations (specifications, requirements, ...) to:' +
      '<br>- identify solutions / suppliers that can solve my problem' +
      '<br>- evaluate the problem-solution fit with my specifications' +
      '<p class="p-top-20 m-no-bottom">I will also:</p>' +
      '- determine the maturity level of the proposed solutions' +
      '<br>- discover who has a similar problem' +
      '<br>- identify other market players working on the subject' +
      '<br>- …',

    'IDENTIFYING_RECEPTIVE': 'I will describe my solution (principle, specifications...) and submit it to stakeholders in different ' +
      'markets in order to:' +
      '<br>- identify those who perceive an interest for it in their field' +
      '<br>- detect the problems that it could solve' +
      '<br>- evaluate their willingness to adopt it' +
      '<p class="p-top-20 m-no-bottom">I will also:</p>' +
      '- rank features / properties / options...' +
      '<br>- determine the expected specifications' +
      '<br>- identify the potential adoption barriers' +
      '<br>- identify existing solutions that are used and their limits' +
      '<br>- evaluate the volume at stake' +
      '<br>- …',

    'VALIDATING_INTEREST': 'I will describe the problem I think I am solving and the solution that I’m proposing in order to: ' +
      '<br>- confirm the existence and criticality of the need' +
      '<br>- validate that the market perceives its value and is ready to adopt my solution' +
      '<p class="p-top-20 m-no-bottom">I will also:</p>' +
      '- rank market issues' +
      '<br>- identify existing solutions that are used and their limits' +
      '<br>- rank the functionalities / properties... of my solution' +
      '<br>- identify the perceived strengths and weaknesses' +
      '<br>- validate the willingness to pay' +
      '<br>- prioritize the target markets according to their level of interest' +
      '<br>- …',

    'OPTIMIZING': 'I will describe my value proposition in order to rank and prioritize the features that are favored by the market ' +
      'players: strengths, advantages, argumentation...' +
      '<p class="p-top-20 m-no-bottom">I will also:</p>' +
      '-  rank the adoption barriers' +
      '<br>- validate the price positioning' +
      '<br>- validate the business model'
  },

  'RESULT': {
    'DETECTING_MARKET': 'I will know whether or not there are opportunities for innovation on this subject. If so, I will ' +
      'have the list of needs as expressed by the market players.' +
      '<br>I will be able to contact the professionals who expressed their interest.',

    'VALIDATING_MARKET': 'I will know if my hypotheses about the market needs are confirmed or invalidated, and thus, ' +
      'if it is advisable or not to propose a solution to meet those needs.' +
      '<br>I will be able to contact the professionals who expressed their interest.',

    'SOURCING_SOLUTIONS': 'I will get a list of potential solutions and suppliers.\n' +
      'I will be able to contact the professionals who expressed their interest.',

    'IDENTIFYING_RECEPTIVE': 'I will get a list of potential application markets and their respective needs.' +
      '<br> I will be able to contact the professionals who expressed their interest.',

    'VALIDATING_INTEREST': 'I will know the market’s level of interest in my project and can decide to continue on, ' +
      'by fine-tuning my value proposition, to pivot, or to abandon it without any regrets.' +
      '<br>I will be able to contact the professionals who expressed their interest.',

    'OPTIMIZING': 'I will have a value proposition that is perfectly aligned with market expectations and ready to launch.' +
      '<br>I will be able to contact the professionals who expressed their interest.',
  },

};
