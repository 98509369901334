export const MARKET_TEST_METHODOLOGY = {

  'HEADING': {
    'CONTEXT': 'Contexte',
    'METHODOLOGY': 'Méthodologie',
    'RESULT': 'Résultats'
  },

  'CONTEXT': {
    'DETECTING_MARKET': 'Je souhaite innover sur une thématique pour laquelle je n’ai pas encore ' +
      'identifié les besoins précis des acteurs du marché.',

    'VALIDATING_MARKET': 'J’ai identifié des besoins potentiels : problèmes non-résolus, attentes spécifiques...',

    'SOURCING_SOLUTIONS': 'J’ai un problème à résoudre pour lequel je souhaite identifier des solutions / fournisseurs. ',

    'IDENTIFYING_RECEPTIVE': 'J’ai une solution pour laquelle je recherche les marchés d’application potentiels.',

    'VALIDATING_INTEREST': 'J’ai une solution innovante à proposer en réponse à un besoin. Je souhaite vérifier / ' +
      'démontrer que mon projet présente un intérêt pour les acteurs du marché. J’ai déjà identifié les marchés potentiels.',

    'OPTIMIZING': 'J’ai un projet innovant dont j’ai déjà validé l’intérêt auprès du marché : besoin avéré et pertinence de ma ' +
      'solution confirmée. Je souhaite maintenant ajuster ma proposition de valeur.',
  },

  'METHODOLOGY': {
    'DETECTING_MARKET': 'Je vais décrire le contexte et laisser s\'exprimer les acteurs concernés par cette thématique afin : ' +
      '<br>- de confirmer qu’il y a des opportunités d’innovation,' +
      '<br>- d’identifier leurs besoins en lien avec cette thématique.' +
      '<p class="p-top-20 m-no-bottom">Je pourrai aussi :</p>' +
      '- identifier des tendances / axes de développement actuels et futurs,' +
      '<br>- hiérarchiser des premières idées d’enjeux pré-identifiés,' +
      '<br>- identifier les acteurs actifs dans le domaine,' +
      '<br>- anticiper les impacts potentiels sur les marchés concernés,' +
      '<br>- …' +
      '<br>À ce stade je ne vais pas présenter de solution.',

    'VALIDATING_MARKET': 'Je vais décrire ces besoins potentiels et les soumettre aux acteurs des marchés concernés afin ' +
      'de confirmer ou non leur existence et leur criticité.' +
      '<p class="p-top-20 m-no-bottom">Je pourrai aussi :</p>' +
      '- hiérarchiser leurs besoins,' +
      '<br>- identifier les solutions qu’ils utilisent ou connaissent,' +
      '<br>- connaître les limites de ces solutions d’après eux.”' +
      '<br>- …' +
      '<br>À ce stade je ne vais pas présenter de solution.',

    'SOURCING_SOLUTIONS': 'Je vais décrire mes attentes (spécifications, contraintes, …) afin :' +
      '<br>- d’identifier des solutions / fournisseurs pouvant résoudre mon problème,' +
      '<br>- d’évaluer leur niveau d’adéquation avec mon cahier des charges.' +
      '<p class="p-top-20 m-no-bottom">Je pourrai aussi :</p>' +
      '- déterminer le niveau de maturité des solutions proposées,' +
      '<br>- découvrir qui a un problème similaire,' +
      '<br>- identifier d’autres acteurs travaillant sur le sujet,' +
      '<br>- …',

    'IDENTIFYING_RECEPTIVE': 'Je vais décrire ma solution (principe, spécifications…) et la soumettre aux acteurs de différents marchés afin :' +
      '<br>- d’identifier ceux qui perçoivent un intérêt dans leur domaine,' +
      '<br>- de détecter les problématiques qu’elle pourrait résoudre,' +
      '<br>- d’évaluer son adoptabilité dans leur métier,' +
      '<p class="p-top-20 m-no-bottom">Je pourrai aussi :</p>' +
      '- hiérarchiser des fonctionnalités / propriétés / options...' +
      '<br>- préciser les spécifications attendues,' +
      '<br>- identifier les éventuels freins à l\'adoption,' +
      '<br>- identifier les solutions existantes utilisées et leurs limites,' +
      '<br>- évaluer les volumes en jeu,' +
      '<br>- …',

    'VALIDATING_INTEREST': 'Je vais décrire le problème que je pense résoudre et la solution que je propose en face afin :' +
      '<br>- de confirmer l’existence et la criticité du besoin,' +
      '<br>- de valider que le marché perçoit de la valeur et est prêt à adopter ma solution.' +
      '<p class="p-top-20 m-no-bottom">Je pourrai aussi :</p>' +
      '- hiérarchiser des problématiques marché,' +
      '<br>- identifier les solutions existantes utilisées et leurs limites,' +
      '<br>- hiérarchiser des fonctionnalités / propriétés… de ma solution,' +
      '<br>- identifier les points forts et points faibles perçus,' +
      '<br>- valider la propension à payer,' +
      '<br>- hiérarchiser les marchés visés selon leur niveau d’intérêt,' +
      '<br>- …',

    'OPTIMIZING': 'Je vais décrire ma proposition de valeur afin de hiérarchiser les éléments plébiscités par les acteurs ' +
      'du marché : points forts, avantages, arguments...' +
      '<p class="p-top-20 m-no-bottom">Je pourrai aussi :</p>' +
      '- hiérarchiser les freins à l\'adoption,' +
      '<br>- valider le positionnement prix,' +
      '<br>- valider le business model,'
  },

  'RESULT': {
    'DETECTING_MARKET': 'Je saurai s’il y a ou non des opportunités d’innovation sur cette thématique. ' +
      'J’aurai le cas échéant la liste des besoins exprimés par les acteurs du marché.' +
      '<br>Je pourrai recontacter les professionnels ayant manifesté un intérêt.',

    'VALIDATING_MARKET': 'Je saurai si mes hypothèses de besoins sont confirmées ou infirmées, et donc s’il ' +
      'est opportun de proposer une solution pour y répondre ou non.' +
      '<br>Je pourrai recontacter les professionnels ayant manifesté un intérêt.',

    'SOURCING_SOLUTIONS': 'J’obtiendrai une liste de solutions et fournisseurs potentiels.\n' +
      'Je pourrai recontacter les professionnels ayant manifesté un intérêt.',

    'IDENTIFYING_RECEPTIVE': 'J’obtiendrai une liste de marchés d’applications potentiels avec leurs besoins respectifs.' +
      '<br> Je pourrai recontacter les professionnels ayant manifesté un intérêt.',

    'VALIDATING_INTEREST': 'Je connaîtrai le niveau d’intérêt de mon projet pour le marché et pourrai décider de poursuivre en affinant' +
      ' ma proposition de valeur, de pivoter, ou d’abandonner sans regret.' +
      '<br>Je pourrai recontacter les professionnels ayant manifesté un intérêt.',

    'OPTIMIZING': 'J’obtiendrai une proposition de valeur parfaitement alignée avec les attentes marché et prête à être lancée.' +
      '<br>Je pourrai recontacter les professionnels ayant manifesté un intérêt.',
  },

};
