export const PROJECT = {

  'DROPDOWN_MENU': {
    'OPTION_A': 'Export project'
  },

  'PRESET': {
    'CAN_EDIT': 'Modify by client',
    'HIDE': 'Hide for client',
    'IMPORT': 'Import preset',
    'GENERATE_QUIZ': 'Generate quiz',
    'QUIZ_URL': 'URL to access the quiz:'
  },

  'MODAL': {
    'SELECT_FIELD': 'Select the information to export',
    'BASE': 'Basic innovation data',
    'ANON': 'Anonymize personal data'
  },

  'EXPORT': {
    'ONLY_RESPONDENTS': 'Only the respondents',
    'ALL_PROS': 'All the pros',
    'BATCHES': 'Emails batches',
    'REQUESTS': 'pros requests'
  },

  'CAMPAIGNS': 'Campaigns',
  'MISSION_TEAM': 'Mission team',

  'CAMPAIGN': {
    'CAMPAIGN': 'Campaign',
    'INSIGHTS': 'Insights',
    'PITCH_EDIT_DESCRIPTION': 'Please complete the edition of project description',
    'EDIT_ANSWER_TAGS': 'Edit answer tags',
    'ANONYMIZE_ANSWER': 'Make answers anonymous for the clients',
    'CREATE_CAMPAIGN': 'Please create a campaign to pursue the project ',
    'FIND_PROFESSIONALS': 'Find professionals',
    'ACTIVATE_AB_TESTING': 'Activate AB testing',
    'EDIT_WORKFLOW': 'Edit workflow emails',
    'CHOOSE_DEFAULT_WORKFLOW': 'Choose default email',
    'LAUNCH_AUTO_BATCH': 'Launch auto batch',
    'VALIDATE_INSIGHTS': 'Validate insights',
    'INSIGHTS_TO_VALIDATE': 'insights to validate'
  },

  'SOLUTION': 'Solution',
  'PROJECT': 'Project',
  'ISSUE': 'Issue',
  'MARKET_BENEFITS': 'Market benefits',
  'GALLERY': 'Gallery',
  'SHARE': {
    'TITLE': 'Share',
    'LINKEDIN': 'Share on LinkedIn',
    'TWITTER': 'Share on Twitter',
    'FACEBOOK': 'Share on Facebook',
    'MAIL': 'Share by email',
  },
  'SETTINGS': 'Settings',
  'STORYBOARD': 'Storyboard',

  'PREPARATION': {
    'CHOOSE_PRESET': 'Choose questionnaire',
    'EDIT_DESCRIPTION': 'Edit project description',
    'DESCRIPTION_TRANSLATION': 'Description translation',
    'EDIT_MARKET_TARGETING': 'Edit market targeting',
    'BLACKLIST_DOMAINS_EMAILS': 'Blacklist Emails / Domains',
    'UPDATE_STATUS': 'Update status'
  },


  'DELIVERY' : {
    'DELIVERY': 'Delivery',
    'PUBLIC': 'Public project',
    'UPDATE_ANSWER_TAGS': 'Update answer tags',
    'WRITE_CONCLUSIONS': 'Write conclusions',
    'WRITE_ENDING_MAIL': 'Write ending mail',
    'RESTITUTION': 'Project restitution',
    'CLIENT_SATISFACTION': 'Client satisfaction',
    'MESSAGE_FROM_CLIENT': 'Message from the client',
    'MESSAGE_FROM_OPERATOR': 'Message from the operator',
    'SATISFACTIONS': {
      'VERY_HAPPY': 'Very satisfying',
      'HAPPY': 'Satisfying',
      'NORMAL': 'Neutral',
      'BAD': 'Unsatisfying',
      'VERY_BAD': 'Very unsatisfying',
    },
    'OPERATOR_FEEDBACK': 'Feedback of the mission'
  },
  'CARDS': 'Innovation Cards',
  'QUESTIONNAIRE': 'Questionnaire',
  'SYNTHESIS': 'Synthesis',
  'PROJECT_TAGS': 'Project tags',
  'ANSWER_TAGS': 'Answer tags',
  'FOLLOW-UP': 'Follow up',
  'MAIL_CONFIG': 'Email Configuration',
  'STATS': {
    'COMPLETION': 'Completion',
    'ANSWERS': 'Answers',
    'TO_SEND': 'To send',
    'DELIVERED': 'Delivered',
    'OPEN_RATE': 'Open rate',
    'CLICK_RATE': 'Click rate',
    'QUESTIONNAIRE_QUALITY': 'Questionnaire quality',
    'ANSWER_RATE': 'Answer rate',
    'UPDATE': 'Update the stats',
    'UPDATED': 'Stats were updated on',
  },
  'ROADMAP': 'Roadmap',
  'NEW_CHANGES': 'This project has been updated by someone else'
};
