export const SHARED_TARGETING_WORLD = {

  'SELECT_CONTINENTS': 'Select the continents that you want to include.',

  'INCLUDE': 'Include',

  'EXCLUDE': 'Exclude',

  'SPECIFY_COUNTRIES_TO_EXCLUDE': 'Are there any specific countries you would like to exclude?',

  'SPECIFY_COUNTRIES_TO_INCLUDE': 'Are there any specific countries you would like to include?',

  'MODAL': {
    'HEADING': 'Select the country or continent.'
  },

  'PLACEHOLDER': {
    'TO_EXCLUDE_COUNTRY': 'Enter the country name to be excluded',
    'TO_INCLUDE_COUNTRY': 'Enter the country name to be included',
  },

  'COUNTRIES_SELECTED': 'Countries Selected',
  'COUNTRY_SELECTED': 'Country Selected',

  'TREE-VIEW' :{
    'SEARCH': 'Start typing to search',
  },

  'BUBBLES':{
    'SEEMORE': 'See details',
  }
};
