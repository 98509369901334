export const ADMIN_EXECUTIVE_REPORT = {

  'INSERT_SNIPPET': 'Insert snippet',

  'OBJECTIVE': {
    'OBJECTIVE': 'Objective',
    'LEADER': 'Project leader',
    'CONTACT': 'Contact'
  },

  'PITCH': {
    'TITLE': 'Pitch du projet',
    'PROJECT_PITCH': 'Project pitch'
  },

  'TARGETING': {
    'TARGETING': 'Targeting abstract',
    'TITLE': 'Panel of professionals'
  },

  'PROFESSIONAL': {
    'ABSTRACT': 'Professional abstract',
    'SOME_PROFILES': 'Some profiles',
    'CHOOSE_PRO': 'Choose a pro',
  },

  'CONCLUSION': {
    'CONCLUSION': 'Conclusion',
    'OPERATOR': 'Choose operator'
  },

  'MODAL': {
    'RESET': 'Do you really want to reset this section?'
  },

  'SAVE': 'The executive report has been saved successfully.',

  'WANT_TO_DISPLAY': 'What would you want to display?',

  'SYNTHESIS_SECTIONS': 'Synthesis sections',

  'RAW_DATA': 'Raw data',

  'RESET_SECTION': 'Reset section',

  'CHANGE_VISUAL': 'Change visualisation',

  'TITLE': 'Title',

  'ABSTRACT': 'Abstract',

  'NAME': 'Name',

  'JOB': 'Job',

  'CUSTOM_SECTION': 'Add custom section',

  'KPI_SECTION': {
    'KPI': 'KPI',
    'LEGEND': 'Legend',
    'EXAMPLES': 'Examples'
  },

  'QUOTE_SECTION': {
    'QUOTATION': 'Quotation',
    'SHOW_QUOTES': 'Show quotes',
  },

  'BAR_SECTION': {
    'SHOW_EXAMPLES': 'Show examples below cases',
    'CASE_1': 'Case 1',
    'CASE_2': 'Case 2',
    'CASE_3': 'Case 3',
    'EXAMPLE_1': 'Example 1',
    'EXAMPLE_2': 'Example 2',
    'EXAMPLE_3': 'Example 3'
  },

  'RANKING_SECTION': {
    'LEGEND_1': 'Legend 1',
    'LEGEND_2': 'Legend 2',
    'LEGEND_3': 'Legend 3',
    'ELEMENT_1': 'Element 1',
    'ELEMENT_2': 'Element 2',
    'ELEMENT_3': 'Element 3'
  },

  'PIE_SECTION': {
    'LEGEND_1': 'Legend 1',
    'LEGEND_2': 'Legend 2',
    'LEGEND_3': 'Legend 3',
    'LEGEND_4': 'Legend 4',
    'SHOW_RESPONSES': 'Show number favorable responses',
    'FAVOURABLE': ' of responses are favorable'
  },

  'LIKERT-SCALE_SECTION': {
    'RESULT': 'Result',
    'LEGEND': 'Legend',
    'TOTALLY_INVALIDATED': 'TOTALLY INVALIDATED',
    'INVALIDATED': 'INVALIDATED',
    'UNCERTAIN': 'UNCERTAIN',
    'VALIDATED': 'VALIDATED',
    'TOTALLY_VALIDATED': 'TOTALLY VALIDATED'
  },
};

