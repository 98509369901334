export const TABLE_FR = {

  STATUS: {
    STATUS: 'Statut',
    TO_MODIFY: 'A modifier',
    MODIFIED: 'Modifié',
    VALIDATED: 'Validé',
    REJECTED: 'Rejeté',
    SUBMITTED: 'A regarder',
    DISQUALIFIED: 'Disqualifié',
    REJECTED_GMAIL: 'Rejeté par Mail',
    REJECTED_UMIBOT: 'Rejeté auto',
    VALIDATED_UMIBOT: 'Validé auto'
  },

  HEADING: {
    EMAILS: 'Emails',
    STATUS: 'Statut',
    SIGNATURES: 'Signatures',
    NAME: 'Nom',
    COUNTRY: 'Pays',
    EMAIL_ADDRESS: 'Adresse e-mail',
    JOB_TITLE: 'Poste',
    COMPANY: 'Entreprise',
    CAMPAIGNS: 'Campagnes',
    CONTACT: 'Contact',
    DOMAIN: 'Domaine',
    CREATED: 'Créé le',
    TITLE: 'Titre',
    OWNER: 'Propriétaire',
    UPDATED: 'Modifié le',
    SECTORS: 'Secteurs',
    INDUSTRY: 'Industrie',
    FEEDBACK: 'Feedback',
    SAVED: 'Sauvegardé',
    SCORE: 'Score de Validation',
    LANGUAGE: 'Langue',
    AUTHOR: 'Auteur',
    CONTENT: 'Contenu',
    SECTIONS: 'Sections',
    PROJECTS: 'Projets',
    AMBASSADOR: 'Ambassadeur',
    TTR:  'Temps de sortie',
    OBJECTIVE: 'Objectif',
    TYPE: 'TYPE'
  },

  TITLE: {
    PROFESSIONALS: 'professionnels',
    USERS: 'utilisateurs',
    PROJECTS: 'projets',
    AMBASSADORS: 'ambassadeurs',
    REQUESTS: 'requests',
    RESULTS: 'résultats',
    ANSWERS: 'réponses'
  },

  MESSAGES: {
    FETCHING: 'Chargement des résultats....',
    NO_RESULT: 'Aucun résultat trouvé.',
    NO_RESULT_SEARCHING: 'Aucun résultat trouvé. Essayez d\'ajuster votre recherche pour trouver ce que vous cherchez.'
  },

  SELECTED_ROWS: 'sélectionné(s)',

  SELECT_ALL: 'Tout sélectionner',

  CLEAR_ALL: 'Tout effacer',

  CHOOSE: 'Choisir',

  AT: 'à',

  PAGINATION: {
    PER_PAGE: 'par page',
    START: 'Début',
    END: 'Fin',
    ALL: 'Tous',
    PREVIOUS: 'Précédent',
    NEXT: 'Suivant',
    PAR_PAGE: 'par page'
  }

};
