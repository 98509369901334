export const LOG_IN = {
  'TITLE': 'Login',

  'DOES_NOT_HAVE_ACCOUNT': 'Don\'t have an account?',

  'LOG_IN_BTN': 'Sign in',

  'HEADING': 'Welcome back!',

  'LOG_IN_LINKEDIN': 'Sign in with LinkedIn',

  'SIGN_UP': 'Create an account',

  'SUCCESS_EMAIL': 'We have received your email and our team will contact you soon.',

  'ERROR_EMAIL': 'Sorry, we could not process your request at the moment, please try later.'

};
