export const MARKET_REPORT = {

  'RESULT': {
    'SOLUTION': 'Solution',
    'NEEDS_EXPRESSED': 'Needs expressed',
    'VALIDATING_INTEREST': {
      'TITLE': 'Level of market interest',
      'BAR': {
        'LABEL_A': 'Completely invalidated',
        'LABEL_B': 'Invalidated',
        'LABEL_C': 'Uncertain',
        'LABEL_D': 'Validated',
        'LABEL_E': 'Completely validated',
      }
    },
    'VALIDATING_MARKET': {
      'TITLE': 'Level of needs',
      'BAR': {
        'LABEL_A': 'Completely invalidated',
        'LABEL_B': 'Invalidated',
        'LABEL_C': 'Uncertain',
        'LABEL_D': 'Validated',
        'LABEL_E': 'Completely validated',
      }
    },
    'DETECTING_MARKET': {
      'TITLE': 'Innovation potential on this subject / market',
      'BAR': {
        'LABEL_A': 'Completely invalidated',
        'LABEL_B': 'Invalidated',
        'LABEL_C': 'Uncertain',
        'LABEL_D': 'Validated',
        'LABEL_E': 'Completely validated',
      }
    },
  },

  'TITLE': 'Market potential of your project',

  'CUSTOM_FILTERS': 'Custom filters',

  'RESET_FILTERS': 'Reset filters',

  'FILTER_EVERYTHING': 'Filter',

  'MESSAGE': {
    'SUBMITTED': 'Your project is under review by our team. We\'ll come back soon!',
    'EDITING': 'Once this page is available, you will find here the conclusions and the statistics of your Market Test.',
    'REVIEWING': 'You have some modification to do, please check your e-mail and re-submit your project.',
    'EVALUATING': 'We are collecting market insights' +
      '. We\'ll come back soon!'
  },

  'MESSAGE_ADMIN': {
    'NOT_SUBMITTED': 'The client hasn\'t submitted the project yet. You will be able to edit the market report after he submits his project.',
    'PREVIEW_MODE': 'The partial synthesis of this project is available at the client side.',
    'DONE': 'This projects is marked as finished, and full synthesis is available at the client side.'
  },

  'BUTTON': {
    'DOWNLOAD': 'Download',
    'STATUS_TO_DONE': 'Finalize synthesis',
    'STATUS_TO_EVALUATING': 'Modify synthesis'
  },

  'PROFESSIONAL': {
    'SIDEBAR': 'Professional',
    'TITLE': 'Professionals',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'JOBTITLE': {
    'SIDEBAR': 'Job',
    'TITLE': 'Job',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'JOBTYPOLOGY': {
    'SIDEBAR': 'Job typology',
    'TITLE': 'Job typology',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'SENIORITYLEVEL': {
    'SIDEBAR': 'Seniority level',
    'TITLE': 'Seniority level',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'COMPANY': {
    'SIDEBAR': 'Organization',
    'TITLE': 'Organization',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'ENTERPRISESIZE': {
    'SIDEBAR': 'Organization size',
    'TITLE': 'Organization size',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'VALUECHAIN': {
    'SIDEBAR': 'Value chain',
    'TITLE': 'Value chain',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'ENTERPRISETYPE': {
    'SIDEBAR': 'Status',
    'TITLE': 'Status',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'INSIGHT': 'Insight',

  'CONCLUSION': 'Final conclusion',

  'COMMENT_HEADING': 'Space for your comments',

  'KEY_LEARNING': 'Key learnings',

  'MENU_BAR': {
    'BUTTON_END': 'End Project',
    'FILTER': 'Filters',
  },

  'HIDDEN':{
    'MESSAGE': 'The question was hidden for',
    'RESPONDENTS': 'respondent(s)',
  },

  'MODAL': {
    'END_INNOVATION_TITLE': 'Confirmation Board',
    'END_INNOVATION_CONTENT': 'Do you really want to end this project? Please keep in mind that after this you won\'t be able to make any further changes.',
    'EXPORT_INNOVATION_TITLE': 'Export Board',
    'EXPORT_INNOVATION_NOTE': 'We remind you that according to the regulation n ° 2016/679, said general regulation on the protection of the data, the personal data contained in the complete summary must not be used outside of your company.',
    'EXPORT_INNOVATION_CONTENT' : {
      'A': 'All Answers',
      'B': 'Executive Report',
      'D': 'Share link',
      'AROUND': 'Around',
      'PAGES': 'pages'
    },
    'BUTTON_CONFIRM': 'Confirm',
  },

  'RESTITUTION_LANG': 'Language of restitution',
  'EDIT_ANSWER': 'Edit answer',
  'REASSIGN_ANSWER': 'Reassign answer',
  'ORIGIN_LANG': 'Native language',

  'TRANSLATE_DEEPL_FR': 'Translate in french',
  'TRANSLATE_DEEPL_EN': 'Translate in english',
  'TRANSLATE_DEEPL_ERROR': 'Unable to translate this language.',
  'TRANSLATE_DEEPL_SAVE': 'Save manual translation.',
  'TRANSLATE_DEEPL_CLICK_TO': 'Click to translate manually and paste below.',

  'ORIGINAL_TEXT': 'Original text',

  'NUMBER_ANSWERS': 'respondents answered the question',
  'POSITIVE_ANSWER': 'Positive answer',

  'GEOGRAPHICAL_DISTRIBUTION': {
    'TITLE': 'Geographical distribution',
  },

  'ANONYMOUS': 'Non Disclosed',

  'NOT_ALLOWED': 'You are not allowed to perform this action anymore.',

  'MESSAGE_SYNTHESIS_VISIBLE': 'The partial synthesis is visible to client.',

  'MESSAGE_SYNTHESIS_NOT_VISIBLE': 'The synthesis is not visible to client.',

  'MESSAGE_SYNTHESIS': 'The project has been successfully ended, and the complete synthesis is available at the client side.',

  'PRINT_DATE': 'Market test -',

  'EXECUTIVE': {
    'HEADING': 'Executive Report',
    'TITLE': 'Objective',
    'CHOOSE_TEMPLATE': 'Choose template',
    'VALID_BUTTON': 'Valid Template',
    'CONCLUSION': 'Conclusion abstract',
    'ABSTRACT': 'Abstract',
    'NO_TAGS': 'Add the tags for this question',
    'DELETE_BUTTON': 'Delete Executive Template',
  },

  'EXECUTIVE_TEMPLATE': {
    'PROFESSIONAL_HEADING': 'Panel of professionals',
    'ANSWERS': 'answers',
    'REMAINING': {
      'AND': 'and',
      'OTHERS': 'others',
      'MORE': 'more ...'
    },
    'CONCLUSION_HEADING': 'Conclusion',
    'UMI_CONTACT': {
      'A': 'Do you want to launch a market test?',
      'B': 'Contact us',
      'C': ''
    }
  },

  'EXECUTIVE_PLACEHOLDER': {
    'CONCLUSION': 'Describe the conclusion of this innovation campaign within 1200 words.',
  },

  'TARGET_COUNTRIES': 'Targeted countries',

 // 'TITLE': 'Exploring market potential',
  'COLLAPSE': 'Collapse',

  'EXPAND': 'Expand',

  'FILTERS': 'Filters',

  'SYNTHESIS_FRAME': 'from professionals',

  'PIE_CHART': 'of responses are favorable',

  'COMMENTS': 'comments',

  'ANSWER': 'answer',

  'PROS': 'professional(s)',

  'ANSWERS': 'answer(s)',

  'SEE_ANSWERS': 'Show answer',

  'BTN_EDITION_MODE': 'Go to Edition mode',

  'BTN_VIEW_MODE': 'Go to view mode',

  'BTN_ANSWERS_OPEN': 'Show respondents',

  'BTN_ANSWERS_CLOSE': 'Hide respondents',

  'BTN_PROFESSIONAL_OPEN': 'Show respondents',

  'BTN_PROFESSIONAL_CLOSE': 'Hide respondents',

  'BTN_COMMENTS_OPEN': 'comment(s)',

  'TOGGLE_COMMENTS_TRANSLATE': 'Translated comment',

  'TOGGLE_COMMENTS_ORIGINAL': 'Original comment',

  'TOGGLE_ANSWERS_TRANSLATE': 'Translated answer',

  'TOGGLE_ANSWERS_ORIGINAL': 'Original answer',

  'TOGGLE_QUESTIONS_TRANSLATE': 'Translated questions',

  'TOGGLE_QUESTIONS_ORIGINAL': 'Original questions',

  'BTN_COMMENTS_CLOSE': 'Hide Comments ({{length}})',

  'BTN_RECOMPUTE': 'Recompute infographics',

  'VOTES': 'votes',

  'GRADE': 'Rating',

  'NO_GRADE': 'No rating',

  'POPUP': {
    'PROFIL': 'Profil',
    'RECEIVED': 'Received at',
    'JOB': 'Job',
    'COUNTRY': 'Country',
    'BACK': 'Back'
  },

  'BUTTON_EXPORT': 'Export',

  'BUTTON_SAVE_VIEW': 'Save view',

  'VIEWS': 'My views',

  'EDIT_VIEW': 'Edit view',

  'CHANGE_NAME': 'Change name',

  'TOOLTIP_DELETE_VIEW': 'Delete view',

  'TOOLTIP_LOAD_VIEW': 'Load view',

  'TOOLTIP_EDIT_VIEW': 'Edit view',

  'TOOLTIP_UNHIDE_PROFESSIONAL': 'Unhide professional',

  'TOOLTIP_HIDE_PROFESSIONAL': 'Hide professional',

  'TOOLTIP_HIDE_ANSWER': 'Hide answer',

  'VIEW_PLACEHOLDER': 'Name of your view',

  'SEE_MORE': 'See more',

  'SEE_LESS': 'See less',

  'NO_DATA': 'No data',

  'PANEL': 'Panel of respondents',

  'VISIBLE_TAG': 'visible \non pro card',

  'NOT_VISIBLE_TAG': 'masked on \npro card',

  'VISIBLE_FILTER': 'visible on \nclient side',

  'NOT_VISIBLE_FILTER': 'masked on \nclient side',

  'SIDEBAR': {
    'FILTERS': 'Filters',
    'MAKE_VISIBLE': 'Make project visible to client',
    'MAKE_INVISIBLE': 'Make project invisible to client',
    'GENERATE_REPORT': 'Generate executive report',
    'RESET_REPORT': 'Reset executive report',
    'END_PROJECT': 'End project',
    'CONFIRMATION': 'Confirmation',
    'REALLY_MAKE_VISIBLE': 'Do you really want to make this project visible to the client?',
    'REALLY_MAKE_INVISIBLE': 'Do you really want to make this project invisible to the client?',
    'REALLY_RESET_REPORT': 'Do you really want to reset the executive report?',
    'REALLY_END_PROJECT': 'Do you really want to end this project? Please keep in mind that after this you won\'t be able to make any further changes.',
    '': '',
  },

  'LIKERT-SCALE_SECTION': {
    'TOTALLY_INVALIDATED': 'TOTALLY INVALIDATED',
    'INVALIDATED': 'INVALIDATED',
    'UNCERTAIN': 'UNCERTAIN',
    'VALIDATED': 'VALIDATED',
    'TOTALLY_VALIDATED': 'TOTALLY VALIDATED',
  }

};
