export const PRESETS = {

  'NO_PRESETS': 'No questionnaire. Try to add some!.',

  'ALREADY_EXIST': 'The preset with the same name already exists.',

  'ADDED': 'The preset has been added successfully.',

  'DELETED': 'The preset has been deleted successfully.',

  'CLONED': 'The preset has been cloned successfully.',

  'NO_MULTIPLE_CLONE': 'Sorry. This functionality is not available on multiple presets.',

  'MODAL': {
    'DELETE': 'Do you really want to delete the selected preset(s)?',
    'CLONE': 'Do you really want to clone the selected preset?',
  },



  'PRESET': {
    'ADD': 'Add a preset',
    'FILTER': 'Search',
    'DELETE_MODAL': {
      'TITLE': 'Are you sure?',
      'CONTENT': 'Are you sure you want to definitively delete this preset ? It may be used for other projects !'
    },
    'CLONE_MODAL': {
      'TITLE': 'Clone of preset',
      'CONTENT': 'Please give a new name to the cloned preset'
    },
    'NONE': {
      'TITLE': 'You have no preset yet',
      'CONTENT': 'Click the button to create your first'
    },
    'NEW': 'New preset',
    'GO': 'Create the preset',
    'GIVE_NAME': 'Give a name to the preset',
    'DONT_USE_SPACES': 'Please don\'t use space character.',
    'UNIQUE_NAME': 'Be careful, this name must be different from the already existing configurations',
    'TAKEN_NAME': 'This name is already used, please choose another name',
    'EDIT': {
      'SAVE': 'Save the preset',
      'ADD': 'Add sections to the preset',
      'SECTION_PLACEHOLDER': 'marketPotential'
    }
  },
  'SECTION': {
    'ADD': 'Add a section',
    'FILTER': 'Search',
    'DELETE_MODAL': {
      'TITLE': 'Are you sure?',
      'CONTENT': 'Are you sure you want to definitively delete this section ? It may be used for other projects !'
    },
    'CLONE_MODAL': {
      'TITLE': 'Clone of section',
      'CONTENT': 'Please give a new name to the cloned section'
    },
    'DESCRIPTION': {
      'nothing': 'Nothing',
      '1st': 'Problem',
      '2nd': 'Solution'
    },
    'NONE': {
      'TITLE': 'You have no section yet',
      'CONTENT': 'Click the button to create your first section'
    },
    'NEW': 'New section',
    'GO': 'Create the section',
    'GIVE_NAME': 'Give a name to the section',
    'DONT_USE_SPACES': 'Please don\'t use space character.',
    'UNIQUE_NAME': 'Be careful, this name must be different from the already existing sections',
    'EDIT': {
      'SAVE': 'Save the section',
      'ADD': 'Add questions to the section',
      'TITLE': 'Title of the section in the quiz',
      'DESCRIPTION': 'Description of the section',
      'QUESTION_PLACEHOLDER': 'relevantProblematic'
    }
  },
  'QUESTION': {
    'ADD': 'Add a question',
    'FILTER': 'Search',
    'DELETE_MODAL': {
      'TITLE': 'Are you sure?',
      'CONTENT': 'Are you sure you want to definitively delete this question ? It may be used for other projects !'
    },
    'CLONE_MODAL': {
      'TITLE': 'Clone of question',
      'CONTENT': 'Please give a new name to the cloned question'
    },
    'NONE': {
      'TITLE': 'You have no question yet',
      'CONTENT': 'Click the button to create your question'
    },
    'NEW': 'New question',
    'GO': 'Create the question',
    'GIVE_NAME': 'Give a name to the question',
    'DONT_USE_SPACES': 'Please don\'t use space character.',
    'UNIQUE_NAME': 'Be careful, this name must be different from the already existing questions',
    'TYPE': {
      'CHOOSE': 'Choose the type of question',
      'list': 'List of items',
      'clearbit': 'List of companies',
      'radio': 'Unique choice',
      'scale': 'Rating',
      'stars': 'Stars rating',
      'textarea': 'Text',
      'toggle': 'Toggle',
      'checkbox': 'Multiple choice',
      'ranking': 'Ranking',
      'likert-scale': 'Likert scale',
      'yes-no': 'Yes/No',
      'matrix': 'Matrix',
      'custom-content': 'Custom block'
    },
    'EDIT': {
      'SAVE': 'Save the question',
      'TITLE': 'Title in the infographics',
      'SUBTITLE': 'Subtitle in the infographics',
      'LABEL': 'Label of the question',
      'CAN_COMMENT': 'Possibility to add a comment',
      'CHOICES': 'Possible answers',
      'ADD': 'Add a new choice',
      'PARAMETERS': {
        'TITLE': 'Parameters',
        'UNITY': 'Unity'
      }
    }
  },
  'SECTIONS': 'Sections',
  'QUESTIONS': 'Questions'
};
