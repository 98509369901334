export const COMMON = {
  'CHANGE_WITHOUT_SAVE': 'Souhaitez vous vraiment changer sans sauvegarder? Tous vos changements seront perdus.',
  'QUIT_WITHOUT_SAVE': 'Souhaitez vous vraiment quitter sans sauvegarder? Tous vos changements seront perdus.',
  'SAVE': 'Sauvegarder',
  'DOWNLOAD': 'Télécharger',
  'UPLOAD': 'Uploader',
  'IMPORT': 'Importer',
  'SAVE_DRAFT': 'Sauvegarder en brouillon',
  'LAST_SAVED_AT': 'Sauvegardé à',
  'UPDATE': 'Mettre à jour',
  'EDIT': 'Éditer',
  'SEND': 'Envoyer',
  'CHANGE': 'Changer',
  'DELETE': 'Supprimer',
  'SUBMIT': 'Confirmer',
  'LOG_IN': 'Connexion',
  'SIGN_UP': 'Inscription',
  'SIGN_OUT': 'Se déconnecter',
  'LOG_OUT': 'Se déconnecter',
  'GENERATE': 'Générer',
  'ADD': 'Ajouter',
  'MORE': 'Voir Plus',
  'ALL': 'Tous',
  'OF': 'de',
  'ADDED': 'Ajouté le',
  'CREATED': 'Créé le',
  'UPDATED': 'Modifié le',
  'LAUNCHED': 'Lancé le',
  'EXPIRATION': 'Expire le',
  'EXPIRATION-SPECIFY': 'Ou renseigner une date d\'expiration',
  'REASON': 'Raison',
  'CLOSE': 'Fermer',
  'CLONE': 'Clôner',
  'PREVIEW': 'Prévisualiser',
  'NO': 'Non',
  'YES': 'Oui',
  'ACCEPT': 'Accepter',
  'PAGINATION': 'Lignes par pages',
  'FILTER': 'Filtrer',
  'RESET': 'Réinitialiser',
  'SEARCH': 'Recherche',
  'TAGS': 'Tags',
  'TRANSLATE': 'Traduire en Français',
  'SEARCHES': 'Recherches',
  'PATENTS': 'Brevets',
  'INVITE': 'Inviter',
  'REINVITE': 'Inviter à nouveau',
  'I_FINISHED': 'J\'ai terminé',
  'SYNTHESIS': 'Synthèse',
  'SEARCH_INNOVATION': 'Rechercher une innovation ici',
  'LOADING': 'Chargement...',
  'MEDIA_NOT_AVAILABLE': 'Médias non disponible',
  'PROGRESS': 'Évolution',
  'PROFILE-URL': 'Profil Linkedin',
  'URL_LINKEDIN': 'Lien du profil',
  'ADD_URL_LINKEDIN': 'Ajouter un lien',
  'NO_URL_LINKEDIN': 'Pas de lien de profil',
  'STATUS': 'Status',
  'ADD_NEW_INFO': 'Ajouter une nouvelle info',
  'ALREADYEXIST': 'Cette nature de donnée existe déjà !',

  'BLACKLIST': {
    'EMAILS': 'E-mails blacklistés',
    'DOMAINS': 'Domaines blacklistés',
    'COUNTRIES': 'Pays blacklistés',
    'ACCEPTATION': 'Pourcentage d\'acceptation de mails'
  },
  'EDIT-BLACKLIST': 'Modifier email blacklisté',
  'EXCLUDE': 'Exclure',
  'EXCLUDE-EMAILS': 'Exclure des emails',
  'EXCLUDE-DOMAINS': 'Exclure des domaines',
  'EXCLUDE-COUNTRIES': 'Exclure des pays',
  'NEVER': 'Jamais',
  'COMMENT': 'Commentaire',
  'COMMENTS': 'Commentaires',

  'OLD_PASSWORD': 'Ancien mot de passe',
  'NEW_PASSWORD': 'Nouveau mot de passe',
  'CONFIRM_PASSWORD': 'Confirmez le mot de passe',

  'PASSWORD_PLACEHOLDER': 'Entrez votre mot de passe',
  'OLD_PASSWORD_PLACEHOLDER': 'Entrez votre ancien mot de passe',
  'NEW_PASSWORD_PLACEHOLDER': '9+ caractères',
  'CONFIRM_PASSWORD_PLACEHOLDER': 'Entrez le mot de passe de confirmation',

  'PASSWORD_CONFIRM': 'Confirmation du mot de passe',

  'DOMAIN': 'Domaine',

  'TERMS': {
    'A': 'En cliquant sur "Inscription", j\'accepte',
    'B': 'termes et conditions d\'utilisation.',
  },

  'TOP_PROFILE': 'Top Profil',

  'HEADINGS': {
    'BENEFITS': 'Avantages marché',
    'ISSUE': 'Problématique',
    'SOLUTION': 'Solution'
  },

  'PAGE_TITLE': {
    'LOG_IN': 'Connexion',
    'RESET': 'Réinitialiser le mot de passe',
    'FORGET': 'Demander le mot de passe',
    'LOG_OUT': 'Se déconnecter',
    'SIGN_UP': 'S\'Inscrire',
    'WELCOME': 'Bienvenue',
    'ACCOUNT': 'Profil',
    'SHARED_REPORTS': 'Rapports partagés',
    'DISCOVER': 'Showroom des innovations',
    'PROJECTS': 'Projets',
    'MY_PROJECTS': 'Mes projets',
    'PROJECT': 'Projet',
    'REPORT': 'Rapport',
    'DISCOVER_DESCRIPTION': 'Innovation',
    'PROFESSIONALS': 'Professionnels',
    'USERS': 'Utilisateurs',
    'COMMUNITY_PROJECTS': 'Projets | Community',
    'ANSWER_TAGS': 'Tags réponse | Projet',
    'NEW_PROJECT': 'Ajouter un projet',
    'NOT_FOUND': 'Page non trouvée',
    'NOT_AUTHORIZED': 'Accès refusé',
    'DEMO': 'Demo'
  },

  'LABEL': {
    'RESULT': 'Résultat',
    'DEACTIVATED': 'Désactivé',
    'ACTIVATED': 'Activé',
    'EMAILS_NOTIFICATIONS': 'Notifications par mails',
    'CLIENT_COMMENTS': 'Commentaires clients',
    'ADD': 'Ajouter',
    'FROM': 'De',
    'TO': 'À',
    'SUBJECT': 'Sujet',
    'PENDING': 'En attente',
    'TENTATIVE_LEFT': 'tentative(s) restante(s)',
    'FEEDBACK_COLLECTION': 'Collecte de feedback',
    'RSETITUTION': 'Restitution',
    'SELECT_COLUMN_TO_EDIT': 'Sélectionnez une colonne',
    'ACTIVITY_FIELDS': 'Champs d\'activité',
    'SEND': 'Envoi',
    'RESPONDENTS': 'Répondants',
    'SELECTED': 'Sélectionné(s)',
    'CONFIGURE': 'Configurer',
    'FIRSTNAME': 'Prénom',
    'LASTNAME': 'Nom',
    'EMAIL': 'Email',
    'PASSWORD': 'Mot de passe',
    'PROS_CONTACT': 'Nombre de pros',
    'PHONE': 'Téléphone',
    'JOBTITLE': 'Poste',
    'COMPANY': 'Nom de l\'entreprise',
    'DOMAIN': 'Nom de domaine',
    'SECTORS': 'Secteur',
    'COUNTRY': 'Pays',
    'LANGUAGE' : 'Langue',
    'DATE': 'Date',
    'TIME': 'Heure',
    'SELECT': 'Sélectionner',
    'STARTED': 'Lancé',
    'CONTENT': 'Contenu',
    'OPERATOR': 'Opérateur',
    'ADMIN': 'Affecter comme administrateur',
    'PROFILE_URL': 'URL du profil',
    'CAMPAIGN_NAME': 'Nom de la campagne',
    'NAME': 'Nom',
    'TYPE': 'Type',
    'SELECT_WORKFLOW': 'Sélectionner l\'email',
    'SELECT_ALL': 'Sélectionner tout',
    'CHOOSE_LANGUAGE': 'Sélectionnez la langue :',
    'TECHNOLOGIES': 'Technologies',
    'SEARCH_BY': 'Recherche par',
    'SIGNATURE_NAME': 'Nom Signature',
    'PRESET': 'Preset',
    'PRESET_NAME': 'Nom du Preset',
    'TAG_NAME': 'Nom du tag',
    'CREATED': 'Créé',
    'CREATED_ON': 'Créé le',
    'COLLABORATORS': 'Collaborateurs',
    'DOMAIN_NAME': 'Nom de domaine',
    'ANSWER': 'Réponse',
    'PROJECT': 'Projet',
    'MARKET_TEST_BY': 'Test Marché par',
    'PROJECT_LEADER': 'Chef de projet',
    'HELP': 'Aide',
    'EXAMPLE': 'Exemples',
    'COMMENT': 'Commentaire',
    'COMMENTS': 'Commentaires',
    'COMMENTS_PITCH': 'Commentaires & suggestions',
    'SUGGESTION': 'Suggestion',
    'OR': 'Ou',
    'PREPARATION': 'Préparation',
    'LOADING_EDITOR': 'chargement de l\'éditeur...',
    'TRY_AGAIN': 'Essayez encore',
    'CUSTOM': 'Personnalisé',
    'INFOGRAPHIC_TITLE': 'Titre de l\'infographie',
    'INFOGRAPHIC_SUBTITLE': 'Sous-titre de l\'infographie',
    'OPTION_COLOR': 'Option Couleur',
    'FORGOT_PASSWORD': 'Mot de passe oublié ?'
  },

  'PLACEHOLDER': {
    'PROS_CONTACT': 'Entrez le nombre de pros à contacter',
    'PASSWORD_SIGNUP': '9+ caractères',
    'JOBTITLE': 'Entrez votre titre de poste',
    'COMPANY': 'Entrez le nom de votre entreprise',
    'DOMAIN': 'Entrez le nom du domaine de votre entreprise',
    'COUNTRY': 'Entrez votre pays',
    'LANGUAGE': 'Entrez votre langue',
    'CAMPAIGN': 'Entrez le nom de la campagne',
    'ACTIVITY_FIELDS': 'Entrez votre secteur d\'activité',
    'TECHNOLOGIES': 'Entrez la technologie',
    'SEARCH': 'Rechercher ici',
    'SIGNATURE_NAME': 'Entrez le nom de la signature',
    'PRESET_NAME': 'Entrer le nom du preset',
    'EXISTING_TAG': 'Ajouter un tag existant',
    'INPUT_LIST_DEFAULT': 'Entrez la réponse ici',
    'CLIENT_NAME': 'Nom du client',
    'CLIENT_EMAIL': 'Client email',
    'CHOOSE_SALE': 'Choose sale',
    'AUTO_SUGGESTION': 'Commencez à taper pour rechercher...',
    'PROJECT_TITLE': 'Ajoutez le titre du projet ici...',
    'TITLE': 'Ajoutez le titre ici...',
    'MEDIA_URL': 'Collez ici l\'adresse de votre vidéo Youtube ou Vimeo',
    'MEDIA_SLIDE': 'Faites glisser votre photo ici',
    'SELECT_A_SCOPE': 'Choisir un scope',
    'INPUT_VALUE': 'Entrez la valeur',
    'SUBTITLE': 'Sous-titre',
  },

  'REQUIRED': {
    'FIRSTNAME': 'Le prénom est requis.',
    'LASTNAME': 'Le nom est requis.',
    'EMAIL': 'L\'adresse email est requise.',
    'PHONE': 'Le numéro de téléphone est requis.',
    'PASSWORD': 'Le mot de passe est requis.',
    'OLD_PASSWORD': 'Un ancien mot de passe est requis.',
    'NEW_PASSWORD': 'Un nouveau mot de passe est requis.',
    'CONFIRM_PASSWORD': 'Confirmer le mot de passe est requis.',
    'COMPANY': 'Le nom de l\'entreprise est requis.',
    'JOBTITLE': 'Le poste est requis.',
    'MESSAGE': 'Tous les champs sont obligatoires',
    'ACCEPT': 'Veuillez accepter les termes et conditions.',
    'COUNTRY': 'Le pays est requis.',
    'LANGUAGE': 'La langue est requise.',
    'URL': 'Le profil est requis.',
    'PROS_CONTACT': 'La valeur doit être supérieure à 0.',
    'BATCH_IMMEDIATE': 'Laissez ce champ vide pour envoyer le batch immédiatement.',
    'BATCH_DELAY': 'L\'heure choisie doit être au minimum dans 7 minutes, sinon le batch risque de ne pas être envoyé',
    'FIELD': 'Ce champ est requis'
  },

  'INVALID': {
    'EMAIL': 'Veuillez renseigner une adresse email valide',
    'PASSWORD_TOO_SHORT': 'Le mot de passe doit avoir au minimum 9 caractères.',
    'MUST_CONTAIN': 'Le mot de passe doit contenir un symbole spécial ($@&!#).',
    'DOMAIN': 'Veuillez entrer un domaine valide.',
    'ALREADY_USER_EMAIL': 'L\'utilisateur avec cette adresse e-mail existe déjà.',
  },

  'BUTTON': {
    'OUR_METHODOLOGY': 'Notre méthodologie',
    'CONTINUE': 'Continuer',
    'ADD': 'Ajouter',
    'SAVE': 'Sauvegarder',
    'CANCEL': 'Annuler',
    'CONFIRM': 'Confirmer',
    'SEND_NOW': 'Envoyer maintenant',
    'APPLY': 'Appliquer',
    'IMPORT': 'Importer',
    'EXPORT': 'Exporter',
    'SEND': 'Envoyer',
    'PRINT': 'Imprimer',
    'PREVIEW': 'Prévisualiser',
    'PREVIOUS': 'Retour',
    'NEXT': 'Suivant',
    'SUBMIT': 'Soumettre',
    'SIGN_IN': 'Se Connecter',
    'SIGN_UP': 'Commencer',
    'OPEN': 'Ouvrir',
    'DELETE': 'Supprimer',
    'SELECT': 'Sélectionner',
    'CLOSE': 'Fermer',
    'GENERATE': 'Générer',
    'DOWNLOAD': 'Télécharger',
    'SHARE': 'Partager',
    'SEE_MORE': 'En voir plus',
    'SEE_LESS': 'En voir moins',
    'EDIT': 'Éditer',
    'SHOW': 'Montrer',
    'START': 'Démarrer',
    'CREATE': 'Créer',
    'VIEW_LIKE_CLIENT': 'Afficher comme client',
    'BACK_TO_CAMPAIGNS': 'Retour aux campagnes',
    'COLLABORATOR': 'Ajouter des collaborateurs',
    'UPDATE': 'Mise à jour',
    'IMPORT_PROJECT': 'Importer un projet',
    'IMPORT_PROS': 'Importer une liste de pros',
    'EXPORT_PROJECT': 'Exporter le project',
    'IMPORT_WORKFLOW': 'Importer un email',
    'EXPORT_WORKFLOW': 'Exporter tous les emails',
    'IMPORT_QUESTIONNAIRE': 'Importer un questionnaire',
    'EXPORT_QUESTIONNAIRE': 'Exporter tous les questionnaires',
    'GENERATE_VIDEO': 'Générer une vidéo',
    'GENERATE_PDF': 'Générer PDF',
    'REINVITE': 'Renvoyer l\'invitation',
    'ADD_LANG_ENGLISH': 'Ajouter l\'anglais',
    'ADD_LANG_FRENCH': 'Ajouter le français',
    'SHARE_LINK': 'Cliquez pour copier le lien',
    'COPY_LINK': 'Copier le lien',
    'COPIED': 'Copié',
    'GENERATING_LINK': 'en cours de création...',
    'SAVING_PROJECT': 'sauvegarde en cours...',
    'ADDING_LANG': 'en cours de création...',
    'GENERATING_FILE': 'en cours de création...',
    'GENERATING_VIDEO': 'en cours de création...',
    'CONTACT_COMMERCIAL': 'Contacter mon commercial',
    'EXAMPLE': 'Exemple',
    'REQUEST_PROOFREADING': 'Demander une relecture',
    'REQUESTING_PROOFREADING': 'demande en cours...',
    'VALIDATE_DEFINITIVELY': 'Valider mon questionnaire',
    'VALIDATING_DEFINITIVELY': 'en cours de soumission...',
    'COMMENT': 'Commentaire',
    'SENDING_MESSAGE': 'en cours d\'envoi...',
    'SEND_MESSAGE': 'Envoyer le message',
    'RETURN_HOMEPAGE': 'Retour à la page d\'accueil',
    'RELOAD_PAGE': 'Rechargez la page',
    'RETURNING_HOMEPAGE': 'retour à la page d\'accueil...',
    'CREATE_AN_ACCOUNT': 'Inscription',
    'CREATING_AN_ACCOUNT': 'en cours de créer...',
    'LOGIN': 'Connexion',
    'LOGGING': 'en cours de connexion...',
    'UPLOAD_PICTURE': 'Télécharger la photo',
    'UPLOAD_VIDEO': 'Télécharger la video',
    'I_UNDERSTAND': 'J’ai compris',
  },

  'PROJECT': {
    'OWNER': 'Propriétaire',
    'TITLE': 'Titre',
    'DOMAIN': 'Domaine',
    'MISSION': 'Mission'
  },

  'PROJECT_STATE': {
    'EDITING': 'Description',
    'SUBMITTED': 'Soumis',
    'REVIEWING': 'Révision',
    'EVALUATING': 'Lancé',
    'DONE': 'Terminé'
  },

  'PROJECT_STATUS': {
    '0': 'Idée',
    '1': 'Développement en cours',
    '2': 'Déjà disponible'
  },

  'LINKS': 'Liens',
  'HELP': 'Aide',
  'OR': 'Ou',

  'LANGUAGE': 'Langue',

  'LANG': {
    'FR': 'Français',
    'EN': 'Anglais',
    'AR': 'Arabe',
    'ZH': 'Chinois',
    'DA': 'Danois',
    'NL': 'Néerlandais',
    'FI': 'Finnois',
    'DE': 'Allemand',
    'IT': 'Italien',
    'JA': 'Japonais',
    'KO': 'Coréen',
    'EL': 'Grec',
    'NO': 'Norvégien',
    'PL': 'Polonais',
    'PT': 'Portugais',
    'RU': 'Russe',
    'ES': 'Espagnol',
    'SV': 'Suédois',
    'TR': 'Turc',
    'UK': 'Ukrainien',
  },

  'USERS': 'Utilisateurs',

  'USER': {
    'DELETE': 'Supprimer le(s) utilisateur(s)',
    'DELETE_BOARD': {
      'HEADING': 'Suppression',
      'CONTENT': 'Voulez-vous vraiment supprimer le(s) utilisateur(s) sélectionné(s) ?'
    }
  },

  'AMBASSADOR': 'Ambassadeur',

  'PROFESSIONALS': 'Professionnels',

  'COMMUNITY': 'Communauté',

  'PROFESSIONAL': {
    'PITCH-DELETE': 'Êtes-vous sûr de vouloir supprimer le(s) professionnel(s)',
    'PITCH-DELETE-ALL': 'Êtes-vous sûr de vouloir supprimer tous les professionnels',
    'DELETE': 'Supprimer le(s) professionnel(s)',
    'DELETE_BOARD': {
      'HEADING': 'Suppression',
      'CONTENT': 'Voulez-vous vraiment supprimer le(s) professionnel(s) sélectionné(s) ?'
    }
  },

  'HOME': 'Accueil',
  'ADMIN': 'Admin Side',
  'MY_PROJECT': 'Mon projet',

  'MY_PROJECTS': 'Mes projets',

  'MY_USERS': 'Mes utilisateurs',

  'MY_ACCOUNT': 'Mon compte',

  'PROJECTS': 'Projets',

  'MISSIONS': 'Missions',

  'CAMPAIGNS': 'Campagnes',

  'DISCOVER': 'Découvrir',

  'PRESET': 'Configuration',

  'PRESETS': 'Settings',

  'QUESTIONNAIRE': 'Questionnaire(s)',

  'ADD-ANSWER': 'Ajouter une réponse',
  'PRO-QUIZ-LINK': 'Lien vers le quiz du professionnel',
  'PRO-PASSWORD-RESET-LINK': 'Lien de réinitialisation du mot de passe',
  'CLIENT_SIDE': 'Client Side',
  'EDIT_USER': 'Modifier l\'utilisateur',

  'EDIT-COUNTRY': 'Modifier le filtre du pays',

  'ADD-TAGS': 'Ajouter des tags',

  'UMI_WORD': 'Le mot d’UMI',

  'SORT': {
    'BY_NAME': 'Nom',
    'BY_COMPANY': 'Entreprise',
    'BY_AUTHOR': 'Auteur',
    'BY_DOMAIN': 'Domain',
    'BY_CREATION_DATE': 'Création',
    'BY_UPDATE_DATE': 'Mise à jour'
  },

  'MAIL_EDITOR': {
    'QUESTION': 'Dans quelle langue souhaitez-vous communiquer avec le propriétaire du projet ?',
    'SUBJECT': 'Sujet',
    'CC': 'CC',
    'CC_PLACEHOLDER': 'email en copie',
    'SUBJECT_PLACEHOLDER': 'Entrez l\'objet du mail',
    'MESSAGE': 'Message',
  },

  'TAG': {
    'ADD_TAGS': 'Ajouter des tags',
    'EDIT_TAG': 'Modifier le tag',
    'LABEL_EN_PLACEHOLDER': 'Entrez le label en anglais',
    'LABEL_FR_PLACEHOLDER': 'Entrer le label en français',
    'DESC_EN_PLACEHOLDER': 'Entrez la description en anglais',
    'DESC_FR_PLACEHOLDER': 'Entrez la description en français',
    'CHOOSE_TAG': 'Choisissez le tag à associer à cet tag',
    'TAG_PLACEHOLDER': 'Ajouter un tag existante',
    'NO_TAGS_POOL': 'Pas de tags pour cette innovation. Essayez d\'en ajouter !',
    'CREATE_NEW_TAG': 'Voulez-vous créer un nouveau tag ?',
  },

  'LOGOUT': {
    'TEXT': 'Vous serez redirigé automatiquement vers la page de connexion.'
  },

  'NOT_FOUND': {
    'TEXT': 'Retour à la page d\'accueil.',
    'PAGE_NOT_FOUND': 'Ce n\'est pas la page Web que vous recherchez.',
  },

  'CAMPAIGN': {
    'LABEL_REQUIRED': 'Le nom de la campagne est requis.',
    'DELETE_BOARD': {
      'CONTENT': 'Voulez-vous vraiment supprimer la campagne ?'
    }
  },

  'MODAL': {
    'TITLE_DELETE': 'Supprimer',
    'TITLE_CONFIRMATION': 'Confirmation',
    'TITLE_IMPORT': 'Importation',
    'TITLE_BATCH': 'Batch',
    'TITLE_SHARE': 'Partage',
    'TITLE_SELECT': 'Sélection',
    'TITLE_CREATE': 'Création',
    'TITLE_VIDEO_STORYBOARD': 'Sélectionnez le type de vidéo à générer',
    'ARE_YOU_SURE': 'Êtes-vous sûr de vouloir supprimer cet élément ?',
    'LOGIN': 'Bienvenue !'
  },

  'SEARCH_INPUT_PLACEHOLDER': 'Commencez à taper pour chercher....',

  'TOOLTIP': {
    'HISTORY': 'Historique',
    'EDIT': 'Éditer',
    'READONLY': 'Lecture seulement',
    'DELETE': 'Supprimer',
    'MOVE_UP': 'Remonter en haut',
    'MOVE_DOWN': 'Déplacer vers le bas',
    'ADVANCE_SETTINGS': 'Paramètres avancés',
    'SAVE_CHANGES': 'Sauvegarder les modifications',
    'COPY': 'Copie'
  },

  'SIGNATURES': {
    'NO_SIGNATURES_ADMIN': 'Pas de signature. Essayez d\'en ajouter !',
    'DELETE_SIGNATURES': 'Voulez-vous vraiment effacer la ou les signature(s) sélectionnée(s) ?'
  },

  'MEDIA': {
    'WRONG_FORMAT': 'Impossible de télécharger l\'image, le format n\'est pas valide.',
    'WRONG_FORMAT_VIDEO': 'Impossible de télécharger de la vidéo, le format n\'est pas valide.',
    'WRONG_SIZE': 'Impossible de télécharger l\'image, la taille n\'est pas valide.',
    'UPLOADING': 'Téléchargement de l\'image, veuillez patienter.',
    'UPLOADING_VIDEO': 'Téléchargement de la vidéo, veuillez patienter.'
  },

  'QUESTIONNAIRE_TYPE': {
    'CHOOSE': 'Choisissez le type de question',
    'list': 'Liste d\'items',
    'clearbit': 'Liste d\'entreprises',
    'radio': 'Choix unique',
    'scale': 'Notes',
    'stars': 'Notes étoiles',
    'textarea': 'Texte',
    'toggle': 'Toggle',
    'checkbox': 'Choix multiples',
    'ranking': 'Ranking',
    'likert-scale': 'Likert scale',
    'matrix': 'Matrice',
    'yes-no': 'Oui/Non',
    'content-block': 'Bloc de contenu'
  },

  'QUALIFYING_TYPE': {
    'jobTitle': 'Job',
    'company': 'Organisation',
    'jobTypology': 'Typologie métier',
    'seniorityLevel': 'Niveau hiérarchique',
    'enterpriseSize': 'Taille de l’organisation',
    'sectors': 'Secteur',
    'valueChain': 'Chaîne de valeur',
    'enterpriseType': 'Statut',
    'custom': 'CUSTOM'
  },

  'LIKERT_TYPE': {
    'AGREEMENT': 'Accord',
    'FREQUENCY': 'Fréquence',
    'SATISFACTION': 'Satisfaction',
    'USE': 'Utilité',
    'QUALITY': 'Qualité',
    'RELEVANCE': 'Pertinence',
    'IMPORTANCE': 'Important',
    'INTEREST': 'Intérêt',
    'CRITICALITY': 'Criticité',
    'ADOPTABILITY': 'Adoptabilité'
  },

};
