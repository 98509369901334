export const SHARED_PROJECT_SETTINGS = {

  'GEOGRAPHY': {
    'LABEL': 'What is geographical scope for this project?',
    'ENTIRE_WORLD': 'Worldwide',
    'SPECIFY_COUNTRIES_TO_EXCLUDE': 'Are there any specific countries you would like to exclude?',
    'NEW_COUNTRY_TO_EXCLUDE_PLACEHOLDER': 'Country name to be excluded',
    'COMMENT': 'Any additional comments for this section?',
    'SPECIFIC_REQUEST_PLACEHOLDER': 'Primary focus on US, EU, Korea, Japan, China',
    'ERROR': 'Geographical area is required.'
  },

  'NO': 'None',

  'NOT_ANSWER': 'You haven\'t answered this field.',

  'GEOGRAPHYs': 'Select the geographical area of the projects',

  'SPECIFIC_REQUEST': 'Specific request',

  'MARKET': {
    'LABEL': 'Which market sectors are you looking to target with your project?',
    'PLACEHOLDER': 'Micro electronics branch, photovoltaic sector, industrial security...',
    'ERROR': 'Market sector is required.'
  },

  'PROFESSIONALS': {
    'LABEL': 'What types of professionals would you like to hear from on this project?',
    'PLACEHOLDER': 'R&D Engineer, Head of Business Development, Director of Innovation…\n' +
      'Please feel free to include real LinkedIn profiles: John Doe at IBM http://linkedin.com/in/johndoe',
    'TO_EXCLUDE': 'Are there any specific people you would like to exclude?',
    'NEW_PROFESSIONAL_TO_EXCLUDE_PLACEHOLDER': 'John Doe (http://www.linkedin.com/in/johndoe)'
  },

  'COMPANIES': {
    'LABEL': 'What types of companies would you like to target/contact and are there any you would like to exclude?',
    'PLACEHOLDER': 'Industrial component manufacturers, consumer goods retail, software development studios... Ex: IBM, Intel',
    'TO_EXCLUDE': 'Indicate companies to be excluded',
    'NEW_COMPANY_TO_EXCLUDE_PLACEHOLDER': 'Company name to be excluded',
    'TO_INCLUDE': 'Example of companies to be targeted',
    'NEW_COMPANY_TO_INCLUDE_PLACEHOLDER': 'Company name to be included',
    'COMMENT': 'Any additional comments for this section?'
  },

  'OTHER': {
    'LABEL': 'Is there anything else that would be useful for us to know?',
    'PLACEHOLDER': 'Please feel free to include:\n' +
      'Acronyms (ex: ANDRH)\n' +
      'Standards you would like to check (ex: ISO27001)\n' +
      'Additional information about a product (ex: Ansys Maxwell is a competitor solution) \n' +
      'General terms or jargon (ex: Computational fluid dynamics, LEED Building, Brushless motors, etc.)\n'
  },

  'KEYWORDS': {
    'LABEL': 'Keywords',
    'PLACEHOLDER': 'ex: Computational fluid dynamics, LEED Building, Brushless motors'
  },

  'BLACKLIST': {
    'DOMAINS': 'Blacklist domains',
    'DOMAINS_PLACEHOLDER': 'Domain name to exclude',
    'EMAILS': 'Blacklist emails',
    'EMAILS_PLACEHOLDER': 'Email to exclude'
  },

  'BOT': {
    'HELP': 'Having any difficulty describing your project? Our operation team is always here to help!'
  },

  'CONTACT_INTERNAL': {
    'LABEL': 'I wish to collect the opinion of my collaborators',
    'NO': 'No, I don\'t want to collect the opinion of my collaborators.',
    'YES': 'Yes, I would like to collect the opinion of my collaborators.',
    'VALUE_FALSE': 'No',
    'VALUE_TRUE': 'Yes',
  },

};
