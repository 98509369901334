/**
 * Created by bastien on 31/01/2018.
 */
export const SEARCH = {
  "COUNTRY_LIST": "Country list",
  "REQUESTS": "requests",
  "NORESULT": "No result :-(",
  "PRO": "Professional",
  "SELECTED": "selected",
  "ADDTOCAMPAIGN": "Add to a campaign",
  "ADDTOCAMPAIGN2": "Add to the campaign",
  "SEARCHMAILS": "Search emails",
  "DELETE": "Delete",
  "AUTOMATED": "Automated",
  "BACK": "Back",
  "EXPORT": "Export",
  "ADD": "Add",
  "SAVE": "Save",
  "CONFIRM": "Confirm",
  "AT": "at",
  "NEW": "New search",
  "ADDTOQUEUE": "Add to queue",
  "STOPREQUEST": "Stop the request",
  "SETTINGS": "Settings",
  "APPLY": "Apply",
  "IGNORE": "Ignore",
  "STAR_PROFILES": "Stars Profiles: ",
  "COUNTRY": "Country",
  "LANGUAGE": "Language",
  "NUMBER": "Search :",
  "SMART": "Smart Search",
  "REGIONS": "Regions",
  "ENGINE": "Use :",
  "REMAINING_REQUESTS": "remaining requests",
  "ROBOT": {
    "HELLO": "Nice day, what are you gonna search today?",
    "SPARSE": "Warning: do not do more requests in this field!",
    "NOT_OK": "Warning : not enough unique results and/or too much duplicates !",
    "OK": "These requests look nice!",
    "ERROR": "There is not enough request for CAT to function properly",
    "OVEREXPLOITED": "There are too many similar requests, delete some !",
    "SUGGESTED": "Here are some keywords that may be useful !",
    "SEARCH_USE": "This search will use",
    "APPROX": "approximatively",
    "MORE_INFO": "Get more info"
  },
  "PERSON": {
    "FULLNAME": "Full Name",
    "FIRSTNAME": "Firstname",
    "LASTNAME": "Lastname",
    "JOBTITLE": "Job Title",
    "EMAIL": "Email",
    "CONFIDENCE": "Conf"
  },
  "COMPANY": {
    "COMPANY": "Company",
    "NAME": "Name",
    "DOMAIN": "Website",
    "INDUSTRY": "Industry",
    "EMPLOYEES": "Nb of employees",
    "PROFILE": "Profile",
    "PATTERNS": "Patterns"
  },
  "INPUT": "'Google-like' Search, on request per line",
  "TOOLTIPS": {
    "EDITCOMPANY": "Edit company",
    "EDITPRO": "Edit",
    "DELETECOMPANY": "Delete company",
    "DELETEPRO": "Delete"
  },
  "HISTORY": {
    "NORESULT": "You haven't done any research yet :-(",
    "SEARCHES": "Searches",
    "KEYWORDS": "Keywords",
    "SHOW": "Show the history",
    "HIDE": "Hide the history",
    "TYPE": "Type",
    "NBR": "Nb",
    "REQUEST": "Request",
    "METADATA": "Metadata",
    "DATE": "Date",
    "TARGETTING": "Targetting",
    "TIME": "Time",
    "STATUS": "Status",
    "COST": "Cost",
    "FLAG": "Email Status",
    "ALL": "Tous",
    "RESET": "Reset",
    "DONE": "OK",
    "PROCESSING": "Processing",
    "QUEUED": "Queued",
    "CANCELED": "Canceled",
    "CANCEL": "Cancel the requests",
    "STOP": "STOP the requests",
    "BACK_QUEUE": "Put back in queue",
    "SEARCH_MAILS": "Search emails",
    "PERPAGE": "per page",
    "PRO": "Professional",
    "LAUNCHED_BY": "Launched by",
    "PERSONBYCOUNTRIES": "Pros by countries",
    "PERSONBYREGIONS": "Pros by regions",
    "EMAILS_FOUND": "Emails found",
    "EMAILS_QUEUED": "Emails queued",
    "EMAILS_SEARCHING": "Emails searching",
    "PROS_ADDED": "Pros added",
    "NBSHIELD": "Under shield",
    "NBAMB": "Ambassadors"
  },
  "CAMPAIGN": {
    "TITLE": "Add to a campaign",
    "TITLE2": "professional(s)",
    "ACTIONS": "Actions for",
    "LANGUAGE": "Language of professionals",
    "CHOOSELANGUAGE": "Please choose a language before adding the professionals to the campaign.",
    "CREATED": "Created on",
    "UPDATED": "Updated on",
    "ANSWERS": "answer(s)",
    "NOCAMPAIGN": "No campaign is available to add the  professionals.",
    "ADDANDSEE": "Add and see the campaign"
  },
  "EDITPRO": {
    "TITLE": "Edit professional"
  },
  "EDITCOMPANY": {
    "TITLE": "Edit company"
  },
  "DELETEPRO": {
    "TITLE": "Delete",
    "WARNING": "Warning ! These professionals won't appear in the results anymore."
  },
  'POPOVER': {
    'REQUEST': 'No capital letters or "s" to artificially multiply the number of requests.',
    'PRACTICE' : 'Good request practices for CAT to work.',
    'KEYWORDS' : 'Keywords with numeric character will not be taken into account in the CAT process.',
    'MAX': '100 max request per day.',
    'SPELLING': 'New keywords can be strange due to spelling or typing mistakes in professional descriptions.'
  },

};
