export const NEW_PROJECT = {

  'HEADINGS': {
    'STEP_0': 'Votre futur Test Marché en 3 étapes',
    'STEP_1': '',
    'STEP_LAST': 'Dans quelle langue souhaitez-vous les résultats de votre Test Marché ?',
    'TITLE': 'Pour commencer…',
    'PRIMARY_OBJECTIVE': 'Quel est l\'objectif principal du test marché UMI ?',
    'SECONDARY_OBJECTIVE': 'J\'aimerais aussi...',
    'RESTITUTION_DATE': 'Et pour finir !'
  },

  'BUTTONS': {
    'STEP_0': 'J’ai compris',
    'STEP_1': 'Suite',
    'STEP_LAST': 'Tester le marché',
  },

  'STEP_0': {
    'PREPARATION': '1. Préparation',
    'COLLECTE': '2. Collecte',
    'ANALYSE': '3. Analyse'
  },

  'TITLE': {
    'REQUIRED': 'Le titre est requis'
  },

  'COLLABORATORS_QUESTION': 'Souhaitez-vous ajouter des collaborateurs ?',

  'COLLABORATORS_TERMS': 'Veuillez noter que les collaborateurs ont accès au projet ainsi qu’aux résultats et aux ' +
    'autres données sensibles. Voulez vous continuer ?',

  'TITLE_QUESTION': 'Quel titre souhaitez-vous donner à votre Test Marché ?',

  'TITLE_INTERNAL': '(titre à usage interne)',

  'COMMENT_PLACEHOLDER': 'Avez-vous des précisions à apporter ?',

  'RESTITUTION_DATE_QUESTION' : 'A quelle date souhaitez-vous idéalement obtenir les résultats ?',

  'CHOOSE_DATE': 'Choisissez une date',

  'ACCEPT_CONSENT': 'Vous devez accepter l\'accord.'

};
