export const WELCOME = {

  'WELCOME': 'Bienvenue',

  'I_UNDERSTAND': 'Je comprends, et j\'accepte les',

  'TERMS': 'conditions générales d\'utilisation.',

  'ADMIN_SENTENCE': 'Attendez ! Vous êtes administrateur-trice. Vous avez donc de supers pouvoirs, et "With great power comes great responsibility", ' +
  'faites attention à ce que vous ferez ici. Veuillez entrer le code que nous vous avons envoyé par email afin de vérifier votre identité :',

  'MAIN_SENTENCE': 'Merci de votre inscription ! Dans cette plateforme vous pouvez créer vos projets, en suivre la progression et voir la synthèse des résultats.'

};
