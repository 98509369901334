export const EXPLORATION = {
  'TITLE': 'Suivez votre exploration marché',

  'TABS': {
    "ANSWERS": 'Réponses',
    "RESPONDENTS": 'Répondants'
  },

  'MESSAGE': {
    'SUBMITTED': 'Votre projet est en cours de validation. Nous revenons vers vous rapidement !',
    'EDITING': 'Dès la première réponse collectée, vous pourrez consulter les avis des répondants sur cette page en temps réel.',
    'REVIEWING': 'Nous vous avons suggéré des modifications du projet par email. Une fois fait, soumettez-le de nouveau.'
  },

  'STATUS_TEXT': {
    'TITLE': 'Statut',
    'C': 'Emails envoyés',
    'D': 'Relance',
    'F': 'Dernière relance',
    'CREATED': 'Créé',
    'SUBMIT': 'Soumis',
    'REJECT': 'Rejetté',
    'VALIDATE': 'Validé',
    'FINISH': 'Terminé'
  },

  'CONTACT_BUTTON': 'Nous contacter',

  'ACCOUNT_MANAGER': 'Votre gestionnaire de compte:',

  'CLICK_MAP_TITLE': 'Cartographie des répondants',

  'MAP_TITLE': 'Distribution géographique',

  'COMPANIES_LIST_TITLE': 'Les entreprises à contacter',

  'INSIGHTS_TITLE': 'réponses',

  'ANALYTICS': {
    'TITLE': 'Statistiques',
    'PROFESSIONALS_REACHED': 'Professionnels contactés',
    'EMAILS_OPENED': 'Emails ouverts',
    'CLICKS': 'Visites',
    'ANSWERS': 'Réponses valides'
  },

  'TOOLTIP' : 'Nombre de feedbacks attendus : ',

  'CARD_TITLES': {
    'TIMELINE': 'Calendrier d\'avancement Test Marché',
    'FEEDBACK': 'Feedbacks',
    'PROGRESS': 'Avancement de la collecte'
  },

  'CARD_LABELS': {
    'IN_PROGRESS': 'Collecte en cours',
    'ACHIEVED': 'Objectif atteint',
    'EXCEEDED': 'Objectif dépassé'
  },

  'CARD_DESCRIPTIONS': {
    'IN_PROGRESS': 'Notre équipe Production parcourt le marché à la recherche des profils les plus adaptés. Tout est mis en oeuvre pour collecter les feedbacks qui guideront au mieux votre projet !',
    'ACHIEVED': 'Bonne nouvelle : votre projet a suscité l\'intérêt escompté !',
    'EXCEEDED': 'Fantastique, votre projet fait réagir : encore plus de feedbacks pour enrichir vos résultats !'
  }

};
