export const PROJECT = {

  'DROPDOWN_MENU': {
    'OPTION_A': 'Exporter le projet'
  },

  'PRESET': {
    'CAN_EDIT': 'Modifier par client',
    'HIDE': 'Cacher pour le client',
    'IMPORT': 'Importer un preset',
    'GENERATE_QUIZ': 'Générer un quizz',
    'QUIZ_URL': 'URL pour accéder au quiz :'
  },

  'MODAL': {
    'SELECT_FIELD': 'Sélectionnez les informations à exporter',
    'BASE': 'Informations de base de l\'innovation',
    'ANON': 'Rendre les données personnelles anonymes'
  },

  'EXPORT': {
    'ONLY_RESPONDENTS': 'Seulement les répondants',
    'ALL_PROS': 'Tous les pros',
    'BATCHES': 'batchs d\'emails',
    'REQUESTS': 'requêtes de pros'
  },

  'CAMPAIGNS': 'Campagnes',
  'MISSION_TEAM': 'Equipe mission',

  'SOLUTION': 'Solution',
  'PROJECT': 'Projet',
  'ISSUE': 'Problématique',
  'MARKET_BENEFITS': 'Avantages marché',
  'GALLERY': 'Galerie',
  'STORYBOARD': 'Storyboard',
  'SHARE': {
    'TITLE': 'Partager',
    'LINKEDIN': 'Partager sur LinkedIn',
    'TWITTER': 'Partager sur Twitter',
    'FACEBOOK': 'Partager sur Facebook',
    'MAIL': 'Partager par email',
  },

  'SETTINGS': 'Réglages',
  'QUESTIONNAIRE': 'Questionnaire',
  'PREPARATION': {
    'CHOOSE_PRESET': 'Choisir un questionnaire',
    'EDIT_DESCRIPTION': 'Editer la description du projet',
    'DESCRIPTION_TRANSLATION': 'Traduction de la description',
    'EDIT_MARKET_TARGETING': 'Editer le ciblage marché',
    'BLACKLIST_DOMAINS_EMAILS': 'Blacklister Emails / Domaines',
    'UPDATE_STATUS': 'Mettre à jour le statut'
  },

  'CAMPAIGN': {
    'CAMPAIGN': 'Campagne',
    'INSIGHTS': 'Réponses',
    'PITCH_EDIT_DESCRIPTION': 'Veuillez finir l\'édition de la description du projet',
    'EDIT_ANSWER_TAGS': 'Editer les tags pour les réponses',
    'ANONYMIZE_ANSWER': 'Rendre anonymes les réponses pour le client',
    'CREATE_CAMPAIGN': 'Veuillez créer une campagne pour poursuivre le projet',
    'FIND_PROFESSIONALS': 'Trouver des professionnels',
    'ACTIVATE_AB_TESTING': 'Activer l\'AB testing ',
    'EDIT_WORKFLOW': 'Editer les emails des workflows',
    'CHOOSE_DEFAULT_WORKFLOW': 'Choisir un email par défaut',
    'LAUNCH_AUTO_BATCH': 'Lancer auto batch',
    'VALIDATE_INSIGHTS': 'Validation des réponses',
    'INSIGHTS_TO_VALIDATE': 'réponses à valider'

  },

  'DELIVERY' : {
    'DELIVERY': 'Livrable',
    'PUBLIC': 'Projet public',
    'UPDATE_ANSWER_TAGS': 'Mettre à jour les tags pour les réponses',
    'WRITE_CONCLUSIONS': 'Rédiger les conclusions',
    'WRITE_ENDING_MAIL': 'Rédiger le mail final',
    'RESTITUTION': 'Restitution du projet',
    'CLIENT_SATISFACTION': 'Satisfaction du client',
    'MESSAGE_FROM_CLIENT': 'Message du client',
    'MESSAGE_FROM_OPERATOR': 'Message de l\'opérateur',
    'SATISFACTIONS': {
      'VERY_HAPPY': 'Très content',
      'HAPPY': 'Content',
      'NORMAL': 'Neutre',
      'BAD': 'Mécontent',
      'VERY_BAD': 'Très mécontent',
    },
    'OPERATOR_FEEDBACK': 'Compte-rendu de la mission'
  },
  'CARDS': 'Description',
  'SYNTHESIS': 'Synthèse',
  'PROJECT_TAGS': 'Tags projet',
  'ANSWER_TAGS': 'Tags réponses',
  'FOLLOW-UP': 'Follow up',
  'MAIL_CONFIG': 'Réglages Emails',
  'STATS': {
    'COMPLETION': 'Complétion',
    'ANSWERS': 'Réponses',
    'TO_SEND': 'Envoyables',
    'DELIVERED': 'Délivrés',
    'OPEN_RATE': 'Taux d\'ouverture',
    'CLICK_RATE': 'Taux de clic',
    'QUESTIONNAIRE_QUALITY': 'Qualité du questionnaire',
    'ANSWER_RATE': 'Taux de réponse',
    'UPDATE': 'Mettre à jour les stats',
    'UPDATED': 'Stats mises à jour le',
  },
  'ROADMAP': 'Roadmap',
  'NEW_CHANGES': 'Ce projet a été modifié par un autre utilisateur.'
};
