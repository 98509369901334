export const ADMIN_STORYBOARD = {

  'CHANGE_LANG': {
    'A': 'Vous voulez',
    'B': 'changer de langue',
    'C': '? Il va tout réinitialiser...'
  },

  'AUTOFILL': {
    'A': 'Essayez ',
    'B': 'le remplissage automatique',
    'C': 'et gagnez du temps !'
  },

  'SELECT_LANG': {
    'A': 'Pour commencer à éditer le Storyboard,',
    'B': 'sélectionnez la langue',
    'C': '...'
  },

  'MODAL':{
    'CHANGE_TITLE': 'Changer de langue',
    'SELECT_TITLE': 'Sélection de la langue',
    'CURRENT_LANG': 'Langue actuelle :',
    'ATTENTION': 'Attention : ',
    'ATTENTION_MSG': 'Il va tout réinitialiser et vous ne pourrez plus revenir en arrière.'
  },

  'LAST_GENERATE': 'Dernière génération',

  'AT': 'à',

  'TOAST': {
    'URL_COPIED': 'L\'URL a été copié avec succès.'
  },

  'BACK_TO': 'Retour au projet',

  'NO_QUESTIONS_MSG': 'Il n\'y a pas de questions pour remplir les sections du rapport exécutif.',

  'VIDEO_TYPE': {
    'TEST': 'Test',
    'FINAL': 'Définitive'
  },

  'MISSING_FIELDS': {
    'INTRO': 'Il manque des informations pour générer le fichier',
    'LANG': 'Il faut sélectionner une langue',
    'ANSWERS': 'Il faut des réponses validées',
    'CONCLUSION': 'Il faut une conclusion',
    'OBJECTIVE': 'Il faut un objectif',
    'SUMMARY': 'Il faut un résumé',
    'SALE': 'Il faut un commercial associé au projet',
    'OPERATOR': 'Il faut un opérateur associé au projet',
    'SECTIONS': 'Il faut des sections',
    'NAME': 'Il faut le nom du client',
    'EMAIL': 'Il faut l\'email du client',
    'ABSTRACT': 'Il faut le texte de présentation des profils',
    'LIST': 'Il faut au moins quatre profils de pros complets',
    'COUNTRY': 'Country is missing, adjust in the synthesis',
    'MEDIA': 'Il faut un média principal',
    'TITLE': 'Il faut un titre... no shit ?!',
  }

};
