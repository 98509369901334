export const SHARED_STANDARD_FILTERS = {

  'SELECT_CONTINENTS': 'Select the continents that you want to include.',

  'COUNTRIES_FILTER': 'Country is included in',
  'SENIORITY_FILTER': 'Seniority level is included in',
  'JOBTITLE_FILTER': {
    'CONTAINS_WORDS' : 'Job title contains the word',
    'CONTAINS' : 'Job title contains'
  },
  'COMPANY_FILTER': {
    'CONTAINS_WORDS' : 'Company name contains the word',
    'CONTAINS' : 'Company name contains'
  },
  'SECTORS_FILTER': {
    'IS_ONE_OF' : 'Sector is one of',
    'IS_INCLUDED_IN' : 'Sector in included in',
    'IS_EXACTLY' : 'Sector is exactly'
  },
  'OTHER_FILTER' : ' XX is included in',

  'NEXT': 'Next',

  'APPLY': 'Apply',

  'PREVIOUS': 'Previous',

  'EDIT': 'Edit',

  'DELETE' : 'Delete',

  'INCLUDE': 'Include',

  'EXCLUDE': 'Exclude',

  'SELECT' : 'Select the data label you want to use:',

  'SELECT_EMPTY' : '--Select a data label--',

  'SELECT_ENTRIES' : {
    'COUNTRIES' : 'Country',
    'JOBTITLE' : 'Job title',
    'SENIORITY': 'Seniority level',
    'COMPANY': 'Company',
    'SECTORS': 'Sector',
  },

  'RULES': {
    'COUNTRIES' : 'is included in',
    'SENIORITY' : 'is included in',
    'JOBTITLE' : {
      "0":'contains',
      "1":'contains the word'
    },
    'COMPANY' : {
      "0":'contains',
      "1":'contains the word'
    },
    'SECTORS' : {
      "0": 'is one of'
    }
  },

  'SPECIFY_COUNTRIES_TO_EXCLUDE': 'Are there any specific countries you would like to exclude?',

  'SPECIFY_COUNTRIES_TO_INCLUDE': 'Are there any specific countries you would like to include?',

  'MODAL': {
    'HEADING': 'Select the country or continent.'
  },

  'PLACEHOLDER': {
    'TO_EXCLUDE_COUNTRY': 'Enter the country name to be excluded',
    'TO_INCLUDE_COUNTRY': 'Enter the country name to be included',
  },

  'COUNTRIES_SELECTED': 'Countries selected',
  'COUNTRY_SELECTED': 'Country selected',

  'TREE-VIEW' :{
    'SEARCH': 'Start typing to search',
  },

  'BUBBLES':{
    'SEEMORE': 'See details',
  }
};
