export const PROJECTS_LIST = {
  'TITLE': 'Projects',

  'ADD_PROJECT': 'Add project',

  'DELETE_PROJECT_MODAL': {
    'TITLE': 'Delete Board',
    'CONTENT': 'Do you really want to delete this project?'
  },

  'UNTITLED': 'Untitled project',

  'NO_PROJECT': {
    'TITLE': 'No projects right now',
    'CONTENT': 'The projects you have added will be shown here. Try to add some.'
  },

  'DELETE_BUTTON': 'Delete Project'

};
