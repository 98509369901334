export const SHARE = {

  'MESSAGE': 'In this report, the personal data of professionals have been concealed.',

  'SYNTHESIS_LIST': {
    'BOT_TEXT': 'Here you will find all the results of the market test that have been shared to you.',
    'SHARED_TEXT': {
      'A': 'Shared by',
      'B': 'at'
    }
  },

  'NOT_FOUND': 'We are having trouble while fetching the data regarding this report. Please try to reload the page again or verify the URL!'

};
