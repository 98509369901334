export const SIDEBAR_ENTERPRISE = {
  'MODAL': {
    'TITLE': 'Vous allez créer un nouvel élément.',
    'CONTENT': 'Voulez-vous vraiment créer le nouveau \"{{type}}\" : \"{{value}}\"?'
  },

  'MODAL_CUSTOM' :{ //TODO: change to french if CSM ask...
    'TITLE': ' New custom data for predefined data label',
    'CONTENT': 'Do you really want to create the new custom data “\"{{type}}\" : \"{{value}}\"”?',
  }
};
