export const HEADER = {
  'EXAMPLE': 'Sample Result',
  'MENU': 'Menu',
  'PROFILE': 'Profile',
  'LOG_OUT': 'Sign out',
  'MY_ACCOUNT': 'My Account',
  'MY_PROJECTS': 'My Projects',
  'SHARED_REPORTS': 'Shared Reports',
  'DISCOVER': 'Innovation Portfolio',
  'CONTACT_US': 'Talk to us',
  'CLIENT_SIDE': 'Client Side',
  'ADMIN_SIDE': 'Admin Side'
};
