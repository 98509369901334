export const PROJECT_DOCUMENTS = {

  'MESSAGE_BOT': 'Once this page is available, you will find your Market Test documents.',

  'NAME': {
    'REPORT': 'Executive Report',
    'SHARE': 'Share link',
    'XLSX': 'All answers in Excel',
    'PDF': 'All answers in PDF',
    'VIDEO': 'Synthesis Video',
  },

  'DESCRIPTION': {
    'REPORT': 'Share your summary Market Test results',
    'SHARE': 'Share your Market Test results anonymised',
    'XLSX': 'All answers from your Market Test in Excel',
    'PDF': 'All answers from your Market Test in PDF',
    'VIDEO': 'Share your Market Test result thanks to a video ',
  },

  'IN_PROGRESS': 'under development...',

  'CONSENT': 'We remind you that according to the regulation n ° 2016/679, said general regulation on the protection of the data, ' +
    'the personal data contained in the complete summary must not be used outside of your company.',

  'POPOVER': {
    'NOT_OWNER': 'To download/share link, you have to be the owner of the project.',
    'NOT_CONSENT': 'To download/share link, please accept the above consent.',
    'NOT_BOTH': 'To download/share link, you have to be the owner of the project and please accept the above consent.'
  }

};
