export const SHARED_FOLLOW_UP = {

  'DESC': 'Up until now, your project has been anonymous and the respondents only know UMI.<br>' +
    'From here, we present you to the respondents you select through an introductory email.<br>' +
    'To up your chances of having further discussions, contact them within 2 weeks.',

  'ERROR_CC': 'You should add at least one person in copy.',

  'SELECT_CC': 'Select the person',

  'ADDED_CC': 'The person has been added successfully.',

  'REMOVED_CC': 'The person has been removed successfully.',

  'KAREN': 'The email will be sent from the address of our avatar, Karine Caulfield.',

  'RESPONDENTS_LIST': 'List of respondents',

  'REQUIRED_ENTITY': 'You should add your entity name.',

  'HEADINGS': {
    'STEP_INTRO': 'To get started',
    'STEP_SELECT': 'Select the respondents you would like to be introduced to',
    'STEP_CONFIGURE': 'Fill in the email elements',
    'STEP_SEND': 'Validate and send',
    'PERSON_COPY': 'Add the person to be put in copy (you or a colleague)',
    'CHOOSE_PHRASE': 'Choose the turn of phrase',
    'COMPANY_NAME': 'Verify the name of your company',
    'ADD_MANUALLY': 'If not in the list, add a person by hand'
  },

  'DISCUSSION': 'Continue the discussion',

  'INFORM': 'Inform about the project',

  'SUB_HEADINGS': {
    'STEP_INTRO': 'We send one last email to the respondents you select, with you in copy.\n' +
      'The objective is to let them know who is behind the project and up your chances of continuing ' +
      'the discussion with them.',
    'STEP_SEND': 'We choose the appropriate language for each respondent'
  },

  'STEP_INTRO': {
    'TITLE': {
      '0': 'Select',
      '1': 'Configure',
      '2': 'Send'
    },
    'DESC': {
      '0': 'Select the respondents',
      '1': 'Fill in the email elements',
      '2': 'Validate and send'
    }
  },

  'CONTACT_WITHOUT_OBJECTIVE': 'contact without objective',

  'CONTACTS_WITHOUT_OBJECTIVE': 'contacts without objective',

  'BUTTON_DONE': 'I\'m done!',

  'INTERVIEW': 'Propose an appointment',

  'OPENING': 'Leave an opening',

  'NOFOLLOW': 'Thank without further action',

  'BUTTON_SEE': 'See',

  'CONTACT_ADDED': 'contact added',

  'CONTACTS_ADDED': 'contacts added',

  'EMAIL_SENT': 'email sent',

  'EMAILS_SENT': 'emails sent',

  'WITHOUT_OBJECTIVE': 'without objective',

  'BUTTON': {
    'INTERVIEW': 'Appointment',
    'OPENING': 'Opening',
    'NO_FOLLOW': 'No further action',
    'WITHOUT_OBJECTIVE': 'Without objective',
    'INFORM_UMI': 'Inform UMI',
    'CONTACT': 'Contact the respondents',
    'READY_SEND': 'Ready to send?'
  },

  'MODAL': {
    'WARNING_1': 'Among the professionals you have selected, some of them already have an assigned objective:',
    'WARNING_2': 'Please confirm that you want to override these objectives with your new choice.',
    'EMAILS_READY': 'emails ready!',
    'ADD_CC': 'Add someone by hand',
    'READY_TO_SEND': 'Ready for us to send the emails?',
    'READY_TO_SEND_SUBHEADING': {
      'A': 'It will be sent immediately to',
      'B': 'respondents.'
    }
  }

};
