export const PROJECT_PITCH = {

  'DESCRIPTION': {
    'HEADING': 'My project',
    'SUB_HEADING': 'It will be presented to respondents',
    'TITLE_NOT_FILLED': 'Fill in the title',
    'SUMMARY_NOT_FILLED': 'Fill in the summary',
    'SOLUTION_NOT_FILLED': 'Fill in the solution',
    'PROBLEM_NOT_FILLED': 'Fill in the problem',
    'MEDIA': 'Add medias',
    'TITLE_FILLED': 'Title',
    'SUMMARY_FILLED': 'Summary',
    'MAIN_MEDIA': 'Main media'
  },

  'QUESTIONNAIRE': {
    'HEADING': 'My Questions',
    'SUB_HEADING': 'They will be asked to respondents',
    'MESSAGE_HEADING': 'Do you have any comments on your questionnaire?',
    'NO_ACCESSIBILITY': 'No information at the time',
    'ALT_DESCRIPTION': 'Description for visually impaired people'
  },

  'MODAL': {
    'HEADING_SUBMIT': 'Submission of the project',
    'SUBMIT_TEXT': 'Select languages you want to validate:',
    'SUBMIT_TEXT_BOLD': 'Warning, you will no longer be able to modify project\'s content in a validated language',
    'SUBMIT_TEXT_FOOTER': 'By clicking on "confirm", you authorize UMI to circulate your questionnaire, ' +
      'anonymously, using the most appropriate channels to collect feedback for your Market Test.'
  },

  'POPOVER': {
    'REQUEST_PROOFREADING': 'It will send the proofreading request to your Project manager.'
  },

};
