export const SHARED_FOLLOW_UP = {

  'DESC': 'Jusqu\'à présent, votre projet était anonyme pour les répondants.<br>' +
    'Pour la suite, nous vous présentons aux répondants que vous sélectionnez au travers d\'un email d\'introduction.<br>' +
    'Recontactez-les dans les 15 jours pour augmenter vos chances de poursuivre les échanges.',

  'ERROR_CC': 'Vous devez ajouter au moins une personne en copie.',

  'SELECT_CC': 'Sélectionnez la personne',

  'ADDED_CC': 'La personne a été ajoutée avec succès.',

  'REMOVED_CC': 'La personne a été supprimée avec succès.',

  'KAREN': 'L\'email sera envoyé depuis l\'adresse de notre avatar, Karine Caulfield.',

  'RESPONDENTS_LIST': 'Liste des répondants',

  'REQUIRED_ENTITY': 'Vous devez ajouter le nom de votre entité.',

  'HEADINGS': {
    'STEP_INTRO': 'Pour bien commencer',
    'STEP_SELECT': 'Sélectionnez les répondants avec qui vous souhaitez être mis en relation',
    'STEP_CONFIGURE': 'Définissez les éléments de l’email',
    'STEP_SEND': 'Confirmez et envoyez',
    'PERSON_COPY': 'Ajoutez des personnes à mettre en copie',
    'CHOOSE_PHRASE': 'Choisissez la tournure de l’email',
    'COMPANY_NAME': 'Vérifiez le nom de votre entité',
    'ADD_MANUALLY': 'Ou ajouter une personne manuellement'
  },

  'DISCUSSION': 'Poursuivre les échanges',

  'INFORM': 'Informer sur le projet',

  'SUB_HEADINGS': {
    'STEP_INTRO': 'Nous envoyons un dernier mail aux répondants sélectionnés, avec vous en copie. ' +
      'L\'objectif est de révéler qui est à l\'origine du projet et de vous faciliter la poursuite des échanges.',
    'STEP_SEND': 'Nous choisissons la langue qui convient le mieux aux répondants'
  },

  'STEP_INTRO': {
    'TITLE': {
      '0': 'Sélectionner',
      '1': 'Configurer',
      '2': 'Envoyer'
    },
    'DESC': {
      '0': 'Sélectionnez des répondants',
      '1': 'Définissez les éléments de l’email',
      '2': 'Confirmez et envoyez'
    }
  },

  'CONTACT_WITHOUT_OBJECTIVE': 'contact sans objectif',

  'CONTACTS_WITHOUT_OBJECTIVE': 'contacts sans objectif',

  'BUTTON_DONE': 'J\'ai fini !',

  'INTERVIEW': 'Proposer un rendez-vous',

  'OPENING': 'Laisser une ouverture',

  'NOFOLLOW': 'Remercier sans suite',

  'BUTTON_SEE': 'Voir',

  'CONTACT_ADDED': 'contact ajouté',

  'CONTACTS_ADDED': 'contacts ajoutés',

  'EMAIL_SENT': 'email envoyé',

  'EMAILS_SENT': 'emails envoyés',

  'WITHOUT_OBJECTIVE': 'sans objectif',

  'BUTTON': {
    'INTERVIEW': 'Rendez-vous',
    'OPENING': 'Ouverture',
    'NO_FOLLOW': 'Sans suite',
    'WITHOUT_OBJECTIVE': 'Sans objectif',
    'INFORM_UMI': 'Informer UMI',
    'CONTACT': 'Contacter les répondants',
    'READY_SEND': 'Prêt à envoyer ?'
  },

  'MODAL': {
    'WARNING_1': 'Parmi les professionnels que vous avez sélectionnés, certains ont déjà un objectif assigné :',
    'WARNING_2': 'Veuillez confimer que vous voulez remplacer ces objectifs par votre nouveau choix.',
    'EMAILS_READY': 'emails prêts !',
    'ADD_CC': 'Ajouter une personne manuellement',
    'READY_TO_SEND': 'Prêt à envoyer vos emails ?',
    'READY_TO_SEND_SUBHEADING': {
      'A': 'Votre message sera envoyé à',
      'B': 'contacts immédiatement..'
    }
  }
};
