export const PROJECT_SETUP = {

  'TITLE': 'Détails du projet',

  'TAB': {
    'PITCH': 'Description',
    'TARGETING': 'Ciblage',
    'NEWTARGETING': 'Ciblage'
  },

  'ADD_PROJECT_MODAL': {
    'HEADING': 'Ajouter la langue du projet',
    'INFO': 'Nous vous conseillons de rédiger votre projet dans votre langue maternelle, notre équipe se chargera ' +
      'de traduire en français ou en anglais.'
  },

  'BANNER_MESSAGES': {
    'SUBMITTED': 'Le projet est en cours d\'examen par notre équipe. Nous reviendrons bientôt vers vous !',
    'REVIEWING': 'Le projet a besoin de modifications. Veuillez lire nos commentaires/suggestions pour chaque section.',
    'EVALUATING': 'Il n\'est plus possible de modifier le projet car l\'exploration marché est en cours.',
    'DONE': 'Le test de marché complet du projet est disponible.'
  },

};

// Todo remove this after admin interface
export const SETUP = {





  'MESSAGE': {
    'EDITING': {
      'A': 'Veuillez décrire votre projet puis nous le soumettre. Votre étude sera lancée ensuite.',
      'B': 'la configuration de votre projet',
      'C': 'et le soumettre.'
    },
    'PREVIEW_MODE': 'La synthèse partielle de votre innovation est disponible.',
  },

  'PITCH_TAB': 'Description',

  'TARGETING_TAB': 'Ciblage',

  'OBJECTIVES_TAB': 'Objectifs',

  'SURVEY_TAB': 'Questionnaire',

  'DELETE_BUTTON': 'Supprimer le projet',

  'DELETE_PROJECT_MODAL': {
    'TITLE': 'Supprimer le forum',
    'CONTENT': 'Voulez-vous vraiment supprimer ce projet ?'
  },

  'EMAIL_DOMAINS': 'Domaines à utiliser',

  'ROADMAP': 'Roadmap',

  'EXAMPLE_TEXT': 'Cette section permet de construire la landing page qui sera envoyée aux professionnels. Vous ' +
    'y décrirez votre solution selon la méthodologie UMI. Pour vous aider dans la rédaction, vous pouvez vous appuyer ' +
    'sur les conseils associés à chaque section et cet',

  'INNOVATION_EXAMPLE': 'exemple d\'innovation'

};
