export const SIDEBAR = {

  'TITLE': {
    'SIGN_UP': 'S\'inscrire',
    'COLLABORATOR': 'Mes collaborateurs',
    'PREVIEW': 'Aperçu',
    'EXAMPLE': 'Exemple d\'innovation',
    'EDIT_USER': 'Modifier l\'utilisateur',
    'EDIT_PROFESSIONAL': 'Modifier Professionnel',
    'ADD_TAGS': 'Ajouter des tags',
    'EDIT_CAMPAIGN': 'Modifier la campagne',
    'EDIT_WORKFLOW': 'Modifier le modèle',
    'EDIT_INSIGHT': 'Modifier l\'aperçu',
    'ADD_PRO': 'Ajouter un professionnel',
    'NEW_BATCH': 'Nouveau batch manuellement',
    'EDIT_BATCH': 'Modifier le batch',
    'ADD_AMBASSADOR': 'Ajouter des Ambassadeurs',
    'CHANGE_PASSWORD': 'Changer le mot de passe',
    'INSIGHT': 'Insight',
    'SEARCH_HISTORY': 'Historique de recherche',
    'SHOWCASE_HISTORY': 'Historique de la vitrine',
    'EDIT_SIGNATURE': 'Modifier la signature',
    'EDIT_MISSION': 'Modifier la Mission'
  },

  'LABELS': {
    'CSV-IMPORT': 'Import csv file'
  },

  'INNOV_CARD_PREVIEW': {
    'BOT_MESSAGE': 'Pour avoir un aperçu de l\'innovation, vous devez d\'abord compléter la description.',
    'BENEFITS': 'Avantages marché',
    'ISSUE': 'Problématique',
    'SOLUTION': 'Solution',
  },

  'PROJECT_PITCH': {
    'EDIT': {
      'TITLE': 'Editer le titre',
      'SUMMARY': 'Editer le résumé',
      'CONTEXT': 'Editer le contexte',
      'ISSUE': 'Editer la problématique',
      'SOLUTION': 'Editer la solution',
      'MEDIA': 'Editer les médias',
    },
    'VIEW': {
      'TITLE': 'Titre',
      'SUMMARY': 'Résumé',
      'ISSUE': 'Problématique',
      'CONTEXT': 'Contexte',
      'SOLUTION': 'Solution',
      'MEDIA': 'Médias',
    },

    'REMAINING_TEXT': 'caractères restants',
    'SET_PRINCIPAL': 'En faire le média principal'
  }

};
