export const SHARED_TARGETING_WORLD = {

  'SELECT_CONTINENTS': 'Sélectionnez les continents que vous voulez inclure.',

  'INCLUDE': 'Inclure',

  'EXCLUDE': 'Exclure',

  'SPECIFY_COUNTRIES_TO_EXCLUDE': 'Y a-t-il des pays spécifiques que vous aimeriez exclure ?',

  'SPECIFY_COUNTRIES_TO_INCLUDE': 'Y a-t-il des pays spécifiques que vous aimeriez inclure ?',

  'MODAL': {
    'HEADING': 'Sélectionnez le pays ou le continent.'
  },

  'PLACEHOLDER': {
    'TO_EXCLUDE_COUNTRY': 'Entrez le nom du pays à exclure',
    'TO_INCLUDE_COUNTRY': 'Entrez le nom du pays à inclure',
  },

  'COUNTRIES_SELECTED': 'Pays sélectionnés',
  'COUNTRY_SELECTED': 'Pays sélectionné',

  'TREE-VIEW' :{
    'SEARCH': 'Commencez à taper pour rechercher',
  },

  'BUBBLES':{
    'SEEMORE': 'Voir le détail',
  }
};
