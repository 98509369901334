export const NEW_PROJECT = {

  'HEADINGS': {
    'STEP_0': 'Your future Market Test in 3 steps',
    'STEP_LAST': 'In which language would you like your Market Test results?',
    'TITLE': 'To begin with...',
    'PRIMARY_OBJECTIVE': 'What is the main objective of the UMI market test?',
    'SECONDARY_OBJECTIVE': 'I\'d also like to...',
    'RESTITUTION_DATE': 'And finally!'
  },

  'BUTTONS': {
    'STEP_0': 'I understood',
    'STEP_1': 'Continue',
    'STEP_LAST': 'Test the market',
  },

  'STEP_0': {
    'PREPARATION': '1. Preparation',
    'COLLECTE': '2. Collection',
    'ANALYSE': '3. Analysis'
  },

  'TITLE': {
    'REQUIRED': 'The title is required'
  },

  'TITLE_QUESTION': 'What do you want to call your Market Test?',

  'TITLE_INTERNAL': '(title for internal use)',

  'COLLABORATORS_QUESTION': 'Do you want to add any colleagues?',

  'COLLABORATORS_TERMS': 'Please note that anyone you add will have access to your project, including the results and ' +
    'other sensitive data. Do you want to continue?',

  'COMMENT_PLACEHOLDER': 'Is there anything else you\'d like to do or add ?',

  'RESTITUTION_DATE_QUESTION': 'Ideally, when would you like to have the final results?',

  'CHOOSE_DATE': 'Choose a date',

  'ACCEPT_CONSENT': 'You need to accept the consent.'

};
