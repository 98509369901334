export const EXPLORATION = {
  'TITLE': 'Follow your market exploration',

  'TABS': {
    "ANSWERS": 'Answers',
    "RESPONDENTS": 'Respondents'
  },

  'MESSAGE': {
    'SUBMITTED': 'Your project is under review by our team. We\'ll come back soon!',
    'EDITING': 'Once the first response is collected, you will be able to consult on this page the opinions of respondents in real time.',
    'REVIEWING': 'You have some modification to do, please check your e-mail and re-submit your project.'
  },

  'STATUS_TEXT': {
    'TITLE': 'Status',
    'C': 'Emails sent',
    'D': 'Relaunch',
    'F': 'Last relaunch',
    'CREATED': 'Created',
    'SUBMIT': 'Submitted',
    'REJECT': 'Rejected',
    'VALIDATE': 'Validated',
    'FINISH': 'Finished'
  },

  'CONTACT_BUTTON': 'Contact us',

  'ACCOUNT_MANAGER': 'Your account manager:',

  'ANALYTICS': {
    'TITLE': 'Analytics of your study',
    'PROFESSIONALS_REACHED': 'Professionals reached',
    'EMAILS_OPENED': 'Emails opened',
    'CLICKS': 'Visits',
    'ANSWERS': 'Validated answers'
  },

  'CLICK_MAP_TITLE': 'Where your insights came from',

  'MAP_TITLE': 'Geographical distribution',

  'COMPANIES_LIST_TITLE': 'Companies you asked to contact',

  'INSIGHTS_TITLE': 'insights of your study',

  'STATUS': {
    'STARTED': 'Started',
    'LAUNCHED': 'Launched'
  },

  'TOOLTIP' : 'Targeted number of feedback : ',

  'CARD_TITLES': {
    'TIMELINE': 'Collection timeline',
    'FEEDBACK': 'Feedback',
    'PROGRESS': 'Collection progress'
  },

  'CARD_LABELS': {
    'IN_PROGRESS': 'Collection in progress',
    'ACHIEVED': 'Target achieved',
    'EXCEEDED': 'Target exceeded'
  },

  'CARD_DESCRIPTIONS': {
    'IN_PROGRESS': 'Our Production team analyzes the market for the most suitable profiles. We do our best to gather the feedback that is best to guide your project!',
    'ACHIEVED': 'Good news: your project has generated the expected interest!',
    'EXCEEDED': 'Great news! Your project gets a reaction: even more feedback to improve/enrich your results!'
  }

};
