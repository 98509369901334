export const ADMIN_USERS = {
  'TITLE': 'Utilisateurs',

  'INVITE_USER': 'Inviter un utilisateur',

  'WAITING_INVITATIONS': 'Invitations en attente de réponse',

  'SEARCH': 'Rechercher un utilisateur',

  'LAUNCHED_PROJECTS': 'Projets lancés',

  'DOMAIN': 'Domaine',

  'DELETE': 'Supprimer l\'utilisateur',

  'PITCH-DELETE': 'Êtes-vous sûr de vouloir supprimer le(s) utilisateur(s)',

  'ADMIN': 'Affecter comme administrateur'

};
