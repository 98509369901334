export const PROJECTS_LIST = {
  'TITLE': 'Projets',

  'ADD_PROJECT': 'Ajouter un projet',

  'DELETE_PROJECT_MODAL': {
    'TITLE': 'Supprimer le forum',
    'CONTENT': 'Voulez-vous vraiment supprimer ce projet ?'
  },

  'UNTITLED': 'Projet sans nom',

  'NO_PROJECT': {
    'TITLE': 'Aucun projet pour l\'instant',
    'CONTENT': 'Les projets que vous avez ajoutés seront présentés ici. Essayez d\'en ajouter.'
  },

  'DELETE_BUTTON': 'Supprimer ce projet'

};
