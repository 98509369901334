export const SHARED_MAIL_EDITOR = {

  'INSERT_FIELD': 'Insérer un champ',

  'TEST_EMAILS': 'Tester les emails',

  'TEST_WITH_CONTACT': 'Tester avec un contact',

  'SUBJECT': 'Sujet',

  'SUBJECT_PLACEHOLDER': 'Entrez l\'objet du mail',

};
