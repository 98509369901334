export const PROJECT_SETUP = {

  'TITLE': 'Project details',

  'TAB': {
    'PITCH': 'Pitch',
    'TARGETING': 'Targeting',
    'NEWTARGETING': 'Targeting'
  },

  'ADD_PROJECT_MODAL': {
    'HEADING': 'Add project language',
    'INFO': 'We advise you to write your project in your mother tongue, our team will translate into French or English.'
  },

  'BANNER_MESSAGES': {
    'SUBMITTED': 'The project is under review by our team. We\'ll come back soon!',
    'REVIEWING': 'The project needs to be modified. Please read our comments/suggestions for each pitch section respectively.',
    'EVALUATING': 'The market exploration is under progress. You can\'t make changes in the project anymore.',
    'DONE': 'The complete market test of the project is available.'
  },

};

// Todo remove this after admin interface
export const SETUP = {





  'MESSAGE': {
    'EDITING': {
      'A': 'Please finish',
      'B': 'setup of your project',
      'C': 'and submit it.'
    },
    'PREVIEW_MODE': 'The partial synthesis of your project is available.',
  },

  'PITCH_TAB': 'Pitch',

  'TARGETING_TAB': 'Targeting',

  'OBJECTIVES_TAB': 'Objectives',

  'SURVEY_TAB': 'Survey',

  'PREVIEW': 'Preview',

  'PREVIEW_BUTTON': 'Preview',

  'DELETE_BUTTON': 'Delete Project',

  'DELETE_PROJECT_MODAL': {
    'TITLE': 'Delete Board',
    'CONTENT': 'Do you really want to delete this project?'
  },

  'EMAIL_DOMAINS': 'Domains to be used',

  'ROADMAP': 'Roadmap',

  'EXAMPLE_TEXT': 'This section allows you to build the landing page that will be sent to professionals. You will describe ' +
    'your solution according to the UMI methodology. To help you in the writing, you can rely on the advice associated ' +
    'with each section and this',

  'INNOVATION_EXAMPLE': 'innovation example',

};
