export const COMMON = {
  'CHANGE_WITHOUT_SAVE': 'Do you really want to change without saving? All the changes will be lost.',
  'QUIT_WITHOUT_SAVE': 'Do you really want to quit without saving? All the changes will be lost.',
  'SAVE': 'Save',
  'DOWNLOAD': 'Download',
  'UPLOAD': 'Upload',
  'IMPORT': 'Import',
  'SAVE_DRAFT': 'Save as draft',
  'LAST_SAVED_AT': 'Last saved at',
  'UPDATE': 'Update',
  'EDIT': 'Edit',
  'SEND': 'Send',
  'CHANGE': 'Change',
  'DELETE': 'Delete',
  'LOG_IN': 'Sign In',
  'SIGN_UP': 'Sign Up',
  'LOG_OUT': 'Sign out',
  'SIGN_OUT': 'Sign Out',
  'MORE': 'Show',
  'OF': 'of',
  'ADD': 'Add',
  'ALL': 'All',
  'ADDED': 'Added on',
  'CREATED': 'Created on',
  'GENERATE': 'Generate',
  'UPDATED': 'Updated on',
  'LAUNCHED': 'Launched on',
  'EXPIRATION': 'Expires on',
  'EXPIRATION-SPECIFY': 'Or specify a date',
  'REASON': 'Reason',
  'CLOSE': 'Close',
  'CLONE': 'Clone',
  'PREVIEW': 'Preview',
  'NO': 'No',
  'YES': 'Yes',
  'ACCEPT': 'Accept',
  'PAGINATION': 'Rows per page',
  'FILTER': 'Filter',
  'RESET': 'Reset',
  'SEARCH': 'Search',
  'TAGS': 'Tags',
  'TRANSLATE': 'Translate in English',
  'SEARCHES': 'Searches',
  'PATENTS': 'Patents',
  'INVITE': 'Invite',
  'REINVITE': 'Invite again',
  'I_FINISHED': 'I finished',
  'SYNTHESIS': 'Synthesis',
  'SEARCH_INNOVATION': 'Search an innovation here',
  'LOADING': 'Loading...',
  'MEDIA_NOT_AVAILABLE': 'Media not available',
  'PROGRESS': 'Progress',
  'PROFILE-URL': 'Linkedin profile',
  'URL_LINKEDIN': 'Profile link',
  'ADD_URL_LINKEDIN': 'Add a link',
  'NO_URL_LINKEDIN': 'No Profile link',
  'STATUS': 'Status',
  'ADD_NEW_INFO': 'Add a new info',
  'ALREADYEXIST': 'This data label already exists !',

  'BLACKLIST': {
    'EMAILS': 'Blacklisted e-mails',
    'DOMAINS': 'Blacklisted domains',
    'COUNTRIES': 'Blacklisted countries',
    'ACCEPTATION': 'Acceptance mails percentage'
  },

  'EDIT-BLACKLIST': 'Edit blacklisted e-mail',
  'EXCLUDE': 'Exclude',
  'EXCLUDE-EMAILS': 'Exclude emails',
  'EXCLUDE-DOMAINS': 'Exclude domains',
  'EXCLUDE-COUNTRIES': 'Exclude countries',

  'NEVER': 'Never',

  'COMMENT': 'Comment',
  'COMMENTS': 'Comments',
  'OLD_PASSWORD': 'Old Password',
  'NEW_PASSWORD': 'New Password',
  'CONFIRM_PASSWORD': 'Confirm Password',

  'PASSWORD_PLACEHOLDER': 'Enter your password',
  'OLD_PASSWORD_PLACEHOLDER': 'Enter your old password',
  'NEW_PASSWORD_PLACEHOLDER': '9+ characters',
  'CONFIRM_PASSWORD_PLACEHOLDER': 'Enter the confirmation password',


  'PASSWORD_CONFIRM': 'Password confirmation',
  'DOMAIN': 'Domain',

  'TERMS': {
    'A': 'By clicking "Create and account" I agree to',
    'B': 'terms and conditions of use.',
  },

  'TOP_PROFILE': 'Top profile',

  'HEADINGS': {
    'BENEFITS': 'Benefits',
    'ISSUE': 'Issue',
    'SOLUTION': 'Solution'
  },

  'PAGE_TITLE': {
    'LOG_IN': 'Login',
    'RESET': 'Reset Password',
    'FORGET': 'Request Password Reset',
    'LOG_OUT': 'Sign Out',
    'SIGN_UP': 'Sign Up',
    'WELCOME': 'Welcome',
    'ACCOUNT': 'Profile',
    'SHARED_REPORTS': 'Shared Reports',
    'DISCOVER': 'Innovation Portfolio',
    'PROJECTS': 'Projects',
    'MY_PROJECTS': 'My projects',
    'PROJECT': 'Project',
    'REPORT': 'Report',
    'DISCOVER_DESCRIPTION': 'Innovation',
    'PROFESSIONALS': 'Professionals',
    'USERS': 'Users',
    'COMMUNITY_PROJECTS': 'Projects | Community',
    'ANSWER_TAGS': 'Answer Tags | Project',
    'NEW_PROJECT': 'Add Project',
    'NOT_FOUND': 'Page Not Found',
    'NOT_AUTHORIZED': 'Access Denied',
    'DEMO': 'Demo'
  },

  'LABEL': {
    'RESULT': 'Result',
    'DEACTIVATED': 'Deactivated',
    'ACTIVATED': 'Activated',
    'EMAILS_NOTIFICATIONS': 'Emails notifications',
    'CLIENT_COMMENTS': 'Clients\' comments',
    'TENTATIVE_LEFT': 'attempt(s) left',
    'ADD': 'Add',
    'FROM': 'From',
    'PENDING': 'Pending',
    'TO': 'To',
    'SUBJECT': 'Subject',
    'FEEDBACK_COLLECTION': 'Feedback collection',
    'RSETITUTION': 'Restitution',
    'ACTIVITY_FIELDS': 'Activity Fields',
    'SEND': 'Send',
    'RESPONDENTS': 'Respondents',
    'SELECTED': 'Selected',
    'CONFIGURE': 'Configure',
    'FIRSTNAME': 'First Name',
    'LASTNAME': 'Last Name',
    'EMAIL': 'E-mail Address',
    'PASSWORD': 'Password',
    'PROS_CONTACT': 'Pros Number',
    'PHONE': 'Phone Number',
    'JOBTITLE': 'Job Title',
    'TECHNOLOGIES': 'Technologies',
    'COMPANY': 'Company Name',
    'DOMAIN': 'Domain Name',
    'SECTORS': 'Sectors',
    'COUNTRY': 'Country',
    'DATE': 'Date',
    'TIME': 'Time',
    'STARTED': 'Started',
    'CONTENT': 'Content',
    'OPERATOR': 'Operator',
    'ADMIN': 'Affect as administrator',
    'PROFILE_URL': 'Profile URL',
    'CAMPAIGN_NAME': 'Campaign Name',
    'NAME': 'Name',
    'TYPE': 'Type',
    'SELECT_WORKFLOW': 'Select email',
    'SELECT': 'Select',
    'SELECT_ALL': 'Select all',
    'CHOOSE_LANGUAGE': 'Choose Language:',
    'SEARCH_BY': 'Search by',
    'SIGNATURE_NAME': 'Signature Name',
    'LANGUAGE': 'Language',
    'PRESET': 'Preset',
    'PRESET_NAME': 'Preset Name',
    'TAG_NAME': 'Tag Name',
    'CREATED': 'Created',
    'CREATED_ON': 'Created on',
    'COLLABORATORS': 'Collaborators',
    'DOMAIN_NAME': 'Domain Name',
    'ANSWER': 'Answer',
    'PROJECT': 'Project',
    'MARKET_TEST_BY': 'Market test by',
    'PROJECT_LEADER': 'Project leader',
    'HELP': 'Help',
    'EXAMPLE': 'Examples',
    'COMMENT': 'Comment',
    'COMMENTS': 'Comments',
    'COMMENTS_PITCH': 'Comments & propositions',
    'SUGGESTION': 'Proposition',
    'OR': 'Or',
    'PREPARATION': 'Preparation',
    'LOADING_EDITOR': 'loading editor...',
    'TRY_AGAIN': 'Try again',
    'SELECT_COLUMN_TO_EDIT': 'Select a column',
    'SELECT_A_SCOPE': 'Select a scope',
    'INPUT_VALUE': 'Input the value',
    'CUSTOM': 'Custom',
    'INFOGRAPHIC_TITLE': 'Infographic Title',
    'INFOGRAPHIC_SUBTITLE': 'Infographic Subtitle',
    'OPTION_COLOR': 'Option Color',
    'FORGOT_PASSWORD': 'Forgot password?'
  },

  'PLACEHOLDER': {
    'TITLE': 'Add the title here...',
    'PROS_CONTACT': 'Enter the number of pros to contact',
    'PASSWORD_SIGNUP': '9+ characters',
    'JOBTITLE': 'Enter your job title',
    'COMPANY': 'Enter your company name',
    'DOMAIN': 'Enter your company domain',
    'COUNTRY': 'Enter your country',
    'LANGUAGE': 'Enter your language',
    'CAMPAIGN': 'Enter the campaign name',
    'ACTIVITY_FIELDS': 'Enter your activity field',
    'TECHNOLOGIES': 'Enter the technology',
    'SEARCH': 'Search here',
    'SIGNATURE_NAME': 'Enter the signature name',
    'PRESET_NAME': 'Enter the preset name',
    'EXISTING_TAG': 'Add an existing tag',
    'INPUT_LIST_DEFAULT': 'Enter the input here',
    'CLIENT_NAME': 'Client name',
    'CLIENT_EMAIL': 'Client email',
    'CHOOSE_SALE': 'Choose sale',
    'AUTO_SUGGESTION': 'Start typing to search...',
    'PROJECT_TITLE': 'Add the project title here...',
    'MEDIA_URL': 'Place the address of your Youtube or Vimeo video here',
    'MEDIA_SLIDE': 'Drag and drop your picture here',
    'INPUT_VALUE': 'Input the value',
    'SUBTITLE': 'Subtitle',
  },

  'REQUIRED': {
    'FIRSTNAME': 'First name is required.',
    'LASTNAME': 'Last name is required.',
    'EMAIL': 'Email address is required.',
    'PHONE': 'Phone is required.',
    'PASSWORD': 'Password is required.',
    'OLD_PASSWORD': 'Old password is required.',
    'NEW_PASSWORD': 'New password is required.',
    'CONFIRM_PASSWORD': 'Confirm password is required.',
    'COMPANY': 'Company is required.',
    'JOBTITLE': 'Job title is required.',
    'MESSAGE': 'All fields are mandatory.',
    'ACCEPT': 'Please accept the terms and conditions.',
    'COUNTRY': 'Country is required.',
    'LANGUAGE': 'Language is required.',
    'URL': 'Profile is required.',
    'PROS_CONTACT': 'Value should be greater than 0.',
    'BATCH_IMMEDIATE': 'Leave it blank to send the batch immediately.',
    'BATCH_DELAY': 'The chosen hour must in at least 7 minutes, otherwise the batch may not be sent.',
    'FIELD': 'This field is required'
  },

  'INVALID': {
    'EMAIL': 'Please enter a valid email address.',
    'PASSWORD_TOO_SHORT': 'Password must have at least 9 characters.',
    'MUST_CONTAIN': 'Password must contain one special character ($@&!#).',
    'DOMAIN': 'Please enter a valid domain.',
    'ALREADY_USER_EMAIL': 'The user with this email address is already exists.',
  },

  'BUTTON': {
    'OUR_METHODOLOGY': 'Our methodology',
    'CONTINUE': 'Continue',
    'ADD': 'Add',
    'SAVE': 'Save',
    'CANCEL': 'Cancel',
    'CONFIRM': 'Confirm',
    'APPLY': 'Apply',
    'IMPORT': 'Import',
    'EXPORT': 'Export',
    'SELECT': 'Select',
    'GENERATE': 'Generate',
    'SEND': 'Send',
    'PRINT': 'Print',
    'PREVIEW': 'Preview',
    'PREVIOUS': 'Previous',
    'NEXT': 'Next',
    'SUBMIT': 'Submit',
    'SIGN_IN': 'Sign In',
    'SEND_NOW': 'Send now',
    'SIGN_UP': 'Sign Up',
    'OPEN': 'Open',
    'DELETE': 'Delete',
    'CLOSE': 'Close',
    'DOWNLOAD': 'Download',
    'SHARE': 'Share',
    'SEE_MORE': 'See more',
    'SEE_LESS': 'See less',
    'EDIT': 'Edit',
    'SHOW': 'Show',
    'START': 'Start',
    'CREATE': 'Create',
    'VIEW_LIKE_CLIENT': 'View like client',
    'BACK_TO_CAMPAIGNS': 'Back to campaigns',
    'COLLABORATOR': 'Add collaborators',
    'UPDATE': 'Update',
    'IMPORT_PROJECT': 'Import project',
    'EXPORT_PROJECT': 'Export project',
    'IMPORT_PROS': 'Import a list of pros',
    'IMPORT_WORKFLOW': 'Import email',
    'EXPORT_WORKFLOW': 'Export all emails',
    'IMPORT_QUESTIONNAIRE': 'Import questionnaire',
    'EXPORT_QUESTIONNAIRE': 'Export all the questionnaires',
    'GENERATE_VIDEO': 'Generate video',
    'GENERATE_PDF': 'Generate PDF',
    'REINVITE': 'Reinvite',
    'ADD_LANG_ENGLISH': 'Add English',
    'ADD_LANG_FRENCH': 'Add French',
    'SHARE_LINK': 'Click to copy share link to clipboard',
    'COPY_LINK': 'Copy share link',
    'COPIED': 'Copied to clipboard',
    'GENERATING_LINK': 'generating the link...',
    'SAVING_PROJECT': 'saving the project...',
    'ADDING_LANG': 'adding the language...',
    'GENERATING_FILE': 'generating the file...',
    'GENERATING_VIDEO': 'generating the video...',
    'CONTACT_COMMERCIAL': 'Contact commercial',
    'EXAMPLE': 'Example',
    'REQUEST_PROOFREADING': 'Request a proofreading',
    'REQUESTING_PROOFREADING': 'requesting the proofreading...',
    'VALIDATE_DEFINITIVELY': 'Validate my survey',
    'VALIDATING_DEFINITIVELY': 'submitting the project...',
    'COMMENT': 'Comment',
    'SENDING_MESSAGE': 'sending the message...',
    'SEND_MESSAGE': 'Send message',
    'RETURN_HOMEPAGE': 'Return to the homepage',
    'RELOAD_PAGE': 'Reload the page',
    'RETURNING_HOMEPAGE': 'returning to the homepage...',
    'CREATE_AN_ACCOUNT': 'Create an account',
    'CREATING_AN_ACCOUNT': 'is creating account...',
    'LOGIN': 'Login',
    'LOGGING': 'is logging...',
    'UPLOAD_PICTURE': 'Upload a picture',
    'UPLOAD_VIDEO': 'Upload a video',
    'I_UNDERSTAND': 'I understand',
  },

  'PROJECT': {
    'OWNER': 'Owner',
    'TITLE': 'Title',
    'DOMAIN': 'Domain',
    'MISSION': 'Mission'
  },

  'PROJECT_STATE': {
    'EDITING': 'Description',
    'SUBMITTED': 'Submitted',
    'REVIEWING': 'Reviewing',
    'EVALUATING': 'Launched',
    'DONE': 'Completed'
  },

  'PROJECT_STATUS': {
    '0': 'Idea',
    '1': 'Development in progress',
    '2': 'Already available'
  },


  'LINKS': 'Links',
  'HELP': 'Help',
  'OR': 'Or',

  'LANGUAGE': 'Language',

  'LANG': {
    'FR': 'French',
    'EN': 'English',
    'AR': 'Arabic',
    'ZH': 'Chinese',
    'DA': 'Danish',
    'NL': 'Dutch',
    'FI': 'Finnish',
    'DE': 'German',
    'IT': 'Italian',
    'JA': 'Japanese',
    'KO': 'Korean',
    'EL': 'Greek',
    'NO': 'Norwegian',
    'PL': 'Polish',
    'PT': 'Portuguese',
    'RU': 'Russian',
    'ES': 'Spanish',
    'SV': 'Swedish',
    'TR': 'Turkish',
    'UK': 'Ukrainian',
  },

  'USERS': 'Users',

  'USER': {
    'DELETE': 'Delete user(s)',
    'DELETE_BOARD': {
      'HEADING': 'Delete Board',
      'CONTENT': 'Do you really want to delete the selected user(s)?'
    }
  },

  'AMBASSADOR': 'Ambassador',

  'PROFESSIONALS': 'Professionals',

  'COMMUNITY': 'Community',

  'PROFESSIONAL': {
    'PITCH-DELETE': 'Are you sure you want to delete the professionnal(s)?',
    'PITCH-DELETE-ALL': 'Are you sure you want to delete all the professionnals?',
    'DELETE': 'Delete professionnal(s)',
    'DELETE_BOARD': {
      'HEADING': 'Delete Board',
      'CONTENT': 'Do you really want to delete the selected professional(s)?'
    }
  },

  'HOME': 'Home',
  'ADMIN': 'Admin Side',
  'MY_PROJECT': 'My project',

  'MY_PROJECTS': 'My projects',

  'MY_USERS': 'My users',

  'MY_ACCOUNT': 'My account',

  'PROJECTS': 'Projects',

  'MISSIONS': 'Missions',

  'CAMPAIGNS': 'Campaigns',

  'DISCOVER': 'Discover',

  'PRESET': 'Preset',

  'EDIT_USER': 'Edit User',

  'EDIT-COUNTRY': 'Edit country\'s filter',

  'ADD-TAGS': 'Add tags',

  'PRESETS': 'Settings',

  'QUESTIONNAIRE': 'Questionnaire(s)',

  'ADD-ANSWER': 'Add an answer',
  'PRO-QUIZ-LINK': 'Link to the professional\'s quiz',
  'PRO-PASSWORD-RESET-LINK': 'Password reset link',
  'CLIENT_SIDE': 'Client Side',
  'UMI_WORD': 'A word from UMI',

  'SORT': {
    'BY_NAME': 'Name',
    'BY_COMPANY': 'Company',
    'BY_AUTHOR': 'Author',
    'BY_DOMAIN': 'Domain',
    'BY_CREATION_DATE': 'Date',
    'BY_UPDATE_DATE': 'Default'
  },

  'MAIL_EDITOR': {
    'QUESTION': 'Which language would you like to communicate with the project owner?',
    'SUBJECT': 'Subject',
    'CC': 'CC',
    'CC_PLACEHOLDER': 'email in copy',
    'SUBJECT_PLACEHOLDER': 'Enter the subject of the mail',
    'MESSAGE': 'Message',
  },

  'TAG': {
    'ADD_TAGS': 'Add Tags',
    'EDIT_TAG': 'Edit Tag',
    'LABEL_EN_PLACEHOLDER': 'Enter the label in english',
    'LABEL_FR_PLACEHOLDER': 'Enter the label in french',
    'DESC_EN_PLACEHOLDER': 'Enter the description in english',
    'DESC_FR_PLACEHOLDER': 'Enter the description in french',
    'CHOOSE_TAG': 'Choose the tag to associate with this actual tag',
    'TAG_PLACEHOLDER': 'Add an existing tag',
    'NO_TAGS_POOL': 'No tags for this innovation. Try to add some!',
    'CREATE_NEW_TAG': 'Do you want to create a new tag?',
  },

  'LOGOUT': {
    'TEXT': 'You will be redirected automatically to the login page.',
  },

  'NOT_FOUND': {
    'TEXT': 'Back to homepage.',
    'PAGE_NOT_FOUND': 'This is not the web page you are looking for.',
  },

  'CAMPAIGN': {
    'LABEL_REQUIRED': 'The campaign name is required.',
    'DELETE_BOARD': {
      'HEADING': 'Delete Board',
      'CONTENT': 'Do you really want to delete the campaign?'
    },
  },

  'MODAL': {
    'TITLE_DELETE': 'Delete Board',
    'TITLE_CONFIRMATION': 'Confirmation Board',
    'TITLE_IMPORT': 'Import Board',
    'TITLE_BATCH': 'Batch Board',
    'TITLE_SHARE': 'Share Board',
    'TITLE_SELECT': 'Selection Board',
    'TITLE_CREATE': 'Creation Board',
    'TITLE_VIDEO_STORYBOARD': 'Select the video type to generate',
    'ARE_YOU_SURE': 'Are you sure you want to delete this element ?',
    'LOGIN': 'Welcome back!'
  },

  'SEARCH_INPUT_PLACEHOLDER': 'Start typing to search...',

  'TOOLTIP': {
    'HISTORY': 'History',
    'EDIT': 'Edit',
    'READONLY': 'Readonly',
    'DELETE': 'Delete',
    'MOVE_UP': 'Move up',
    'MOVE_DOWN': 'Move down',
    'ADVANCE_SETTINGS': 'Advance settings',
    'SAVE_CHANGES': 'Save changes',
    'COPY': 'Copy'
  },

  'SIGNATURES': {
    'NO_SIGNATURES_ADMIN': 'No signatures. Try to add some!.',
    'DELETE_SIGNATURES': 'Do you really want to delete the selected signature(s)?'
  },

  'MEDIA': {
    'WRONG_FORMAT': 'Could not upload image, the format is invalid.',
    'WRONG_FORMAT_VIDEO': 'Could not upload video, the format is invalid.',
    'WRONG_SIZE': 'Could not upload image, the size is invalid.',
    'UPLOADING': 'Uploading picture, please be patient.',
    'UPLOADING_VIDEO': 'Uploading video, please be patient.'
  },

  'QUESTIONNAIRE_TYPE': {
    'CHOOSE': 'Choose the type of question',
    'list': 'List of items',
    'clearbit': 'List of companies',
    'radio': 'Unique choice',
    'scale': 'Rating',
    'stars': 'Stars rating',
    'textarea': 'Text',
    'toggle': 'Toggle',
    'checkbox': 'Multiple choice',
    'ranking': 'Ranking',
    'likert-scale': 'Likert scale',
    'matrix': 'Matrix',
    'yes-no': 'Yes/No',
    'content-block': 'Content block'
  },

  'QUALIFYING_TYPE': {
    'jobTitle': 'Job',
    'company': 'Organization name',
    'jobTypology': 'Job Typology',
    'seniorityLevel': 'Seniority Level',
    'enterpriseSize': 'Organization size',
    'sectors': 'Sector',
    'valueChain': 'Value Chain',
    'enterpriseType': 'Status',
    'custom': 'CUSTOM'
  },

  'LIKERT_TYPE': {
    'AGREEMENT': 'Agreement',
    'FREQUENCY': 'Frequency',
    'SATISFACTION': 'Satisfaction',
    'USE': 'Use',
    'QUALITY': 'Quality',
    'RELEVANCE': 'Relevance',
    'IMPORTANCE': 'Importance',
    'INTEREST': 'Interest',
    'CRITICALITY': 'Criticality',
    'ADOPTABILITY': 'Adoptability'
  },


};
