export const COMMENT_CARD = {

  'PROPOSE_REPLACE': 'Suggest to replace',
  'PROPOSE_MODIFY': 'Suggest a modification',
  'REPLACE_FROM': 'Replace',
  'REPLACE_TO': 'by',
  'REPLY': 'reply',
  'REPLIES': 'replies'

};
