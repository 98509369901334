export const SHOWCASE = {

  'MODIFY': 'Modifier',

  'BUTTON_SAVE': 'Sauvegarder',

  'SEE_MORE': 'Voir plus',

  'MAIN_TITLE': 'Découvrez nos projets d\'innovation',

  'PROFESSIONALS_TITLE': 'Contributeurs',

  'PROFESSIONALS': 'répondants',

  'INNOVATIONS_TITLE': 'Tests Marché',

  'INNOVATIONS': 'projets testés',

  'COUNTRIES_TITLE': 'Ciblage',

  'COUNTRIES': 'pays ciblés',

  'CLIENTS_TITLE': 'Clients',

  'SECTORS_MODAL': {
    'TITLE': 'Choisir les secteurs pour créer la vitrine'
  },

  'CREATE_SHOWCASE': 'Prêt à créer votre vitrine !',

  'ERROR_STATS': 'Nous avons de la difficulté à trouver les statistiques.',

  'TOOLTIP': {
    'MODIFY_TAGS': 'Cliquez pour éditer les tags.',
    'EDIT_ANSWERS': 'Cliquez pour sélectionner différentes réponses.',
    'EDIT_INNOVATIONS': 'Cliquez pour sélectionner différentes innovations.',
    'EDIT_CLIENTS': 'Cliquez pour sélectionner différentes clients.'
  },

  'NAME_PLACEHOLDER': 'Nom',

};
