export const DISCOVER = {

  'TAGS': {
    'HEADING': 'Select one or more sectors to see specific projects',
    'HEADING_2': 'sector(s) selected.'
  },

  'BUTTON_SHARE': 'Share',

  'BUTTON_SHOWCASE': 'Get more stats',

  'MODAL_SHARE': {
    'TITLE': 'Share this page with your colleagues',
  },

  'NO_INNOVATION': 'We are unable to find innovations based on your query.',

  'RECOMMENDATION': 'Recommended',

  'SUGGESTED_TAGS': 'Related sector(s)',

  'LEARN_MORE': 'Learn more',

  'LATEST': 'Latest',

  'TRENDING': 'Trending',

  'BUTTON_COMMUNITY': 'Become an Ambassador',

  'COMMUNITY_TEXT': 'Want to get the latest innovations in your mailbox?',

  'TOOLTIP_TEXT': 'Click to copy share link to clipboard',

  'TOOLTIP_TEXT_COPIED': 'Copied to clipboard',

  'FILTER_TEXT': 'See all sectors',

  'FILTER_TEXT_USE_CASE': 'Filter By Use Case',

  'SEARCH_INPUT_PLACEHOLDER': 'Start typing to search innovations...',

  'RESULT': 'results ',

  'FOR': 'for ',

  'NO_RESULT': 'No result found',

  'NO_RESULT_HEADING': 'No result found',

  'NO_RESULT_TEXT': 'Try adjusting your search or filter to find what you\'re looking for.',

  'HIGHLIGHT_SECTOR_HEADING': 'Select one or more sectors to see specific innovations',

  'RELATED_SECTORS': 'Suggested sector(s)',

  'ALL_SECTORS': 'Select one or more sectors',

  'FETCHING_ERROR': 'We are having trouble while fetching the innovations.',

  'FETCHING_ERROR_SUB': 'It could be because of server connection problem.'

};
