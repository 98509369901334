export const MY_ACCOUNT = {
  'TITLE': 'Profile',

  'PARA': 'Add information about yourself.',

  'CHANGE_PASSWORD': 'Change password',

  'DELETE': 'Delete my account',

  'DELETE_MODAL': {
    'BOARD_TITLE': 'Delete Board',
    'TITLE': 'Do you really want to delete your account?',
    'CONTENT': 'It is sad to see you leave us. All your personal data will be ' +
    'deleted. Anyway, if you ever want to come back, you are ' +
    'very welcome!'
  },

  'JOB_TITLE_EXAMPLE': 'Aerodynamics Engineer',

  'CHANGE': 'Change Password',

};
