export const PROJECT_SETTINGS = {

  'TITLE': 'Title',

  'PRINCIPAL_OBJECTIVE': 'Main objective',

  'SECONDARY_OBJECTIVE': 'Secondary objectives',

  'ROADMAP': 'Roadmap',

  'RESTITUTION_DATE': 'Delivery date',

  'REPORTING_LANG': 'Result language',

  'OWNER': 'Owner',

  'COLLABORATORS': 'Collaborators',

  'COLLABORATORS_CONSENT': 'Please note that collaborators have access to your project, including the results and other sensitive ' +
    'data. Do you want to continue?',

  'OPERATOR': 'Project manager',

  'COMMERCIAL': 'Commercial',

  'LANGUAGE': 'Language',

  'AUTHORISATION': 'Authorisation',

  'HEADING': {
    'TITLE': 'Internal project title',
    'PRINCIPAL_OBJECTIVE': 'Main objective',
    'SECONDARY_OBJECTIVE': 'Secondary Objectives',
    'ROADMAP': 'Forecast roadmap',
    'RESTITUTION_DATE': 'Desired delivery date',
    'REPORTING_LANG': 'Language of the Market Test results',
    'OWNER': 'Owner',
    'COLLABORATORS': 'Collaborators',
    'OPERATOR': 'Project manager',
    'COMMERCIAL': 'Commercial',
    'LANGUAGE': 'Working language',
    'AUTHORISATION': 'Permission to distribute'
  },

  'NOT_ASSIGNED': 'Not Assigned.',

  'NO_COLLABORATORS': 'No Collaborators.',

  'NO_SECONDARY': 'No Secondary Objectives.',

  'TOOLTIP_PENDING': 'This collaborator has not an account yet.',

  'PROJECT_IN': 'Project in ',

  'AUTHORISATION_TEXT': 'In order to optimize feedbacks, I authorize UMI to make my project visible anonymously on its networks:',

  'SOCIAL': 'Social networks',

  'UMI': 'Website',

  'COMMUNITY': 'UMI Community',

  'MODAL_DELETE': {
    'COLLABORATOR': 'Do you really want to delete this collaborator?',
    'CARD': 'Do you really want to delete the project in this language?'
  },

  'MESSAGE': {
    'PENDING_EMAIL': 'The invitation is already sent to this email address.',
    'ALREADY_ADDED': 'The email address is already added to the list.',
    'ALREADY_EXISTS': 'The collaborator already exists.'
  },

  'SELECT_COMMERCIAL': 'Select commercial',

  'SELECT_OPERATOR': 'Select Project manager',

  'RESTITUTION_DATE_COMMENT': 'Is there anything else you\'d like to do or add ?',

  'PRIMARY_OBJECTIVE_CONSENT': 'A change in your use case affects your questionnaire. Do you want to continue?',

  'SECONDARY_OBJECTIVE_CONSENT': 'A change in your secondary objectives may affect your questionnaire. Do you want to continue?',

};
