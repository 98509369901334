export const SIGN_UP = {
  'HEADING': 'S\'Inscrire',

  'HEADING_UMI': 'Prêt à tester le marché de votre projet ?',

  'HAVE_ACCOUNT': 'Vous avez déjà un compte ?',

  'SIGN_UP_LINKEDIN': 'S\'inscrire avec LinkedIn',

  'SIGN_UP_EMAIL': 'S\'inscrire avec l\'adresse email',

  'BUTTON': 'Inscription',

};
