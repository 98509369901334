export const SIDEBAR = {

  'TITLE': {
    'SIGN_UP': 'Sign Up',
    'COLLABORATOR': 'Collaborators Management',
    'PREVIEW': 'Preview',
    'EXAMPLE': 'Innovation Example',
    'EDIT_USER': 'Edit User',
    'EDIT_PROFESSIONAL': 'Edit Professional',
    'ADD_TAGS': 'Add Tags',
    'EDIT_CAMPAIGN': 'Edit Campaign',
    'EDIT_INSIGHT': 'Edit Insight',
    'EDIT_WORKFLOW': 'Edit Email',
    'ADD_PRO': 'Add Professional',
    'NEW_BATCH': 'New Batch Manually',
    'EDIT_BATCH': 'Edit Batch',
    'ADD_AMBASSADOR': 'Add Ambassador',
    'CHANGE_PASSWORD': 'Change Password',
    'INSIGHT': 'Insight',
    'SEARCH_HISTORY': 'Search History',
    'SHOWCASE_HISTORY': 'Showcase History',
    'EDIT_SIGNATURE': 'Edit Signature',
    'EDIT_MISSION': 'Edit Mission'
  },

  'LABELS': {
    'CSV-IMPORT': 'Import csv file'
  },

  'INNOV_CARD_PREVIEW': {
    'BOT_MESSAGE': 'To preview the innovation you must have to describe it first.',
    'BENEFITS': 'Benefits',
    'ISSUE': 'Issue',
    'SOLUTION': 'Solution',
  },

  'PROJECT_PITCH': {
    'EDIT': {
      'TITLE': 'Edit title',
      'CONTEXT': 'Edit context',
      'SUMMARY': 'Edit summary',
      'ISSUE': 'Edit issue',
      'SOLUTION': 'Edit solution',
      'MEDIA': 'Edit medias',
    },
    'VIEW': {
      'TITLE': 'Title',
      'SUMMARY': 'Summary',
      'CONTEXT': 'Context',
      'ISSUE': 'Issue',
      'SOLUTION': 'Solution',
      'MEDIA': 'Medias',
    },
    'REMAINING_TEXT': 'characters remaining',
    'SET_PRINCIPAL': 'Set as main media'
  }

};
