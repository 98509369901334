export const FORGET_PASSWORD = {
  'TITLE': 'Demander le mot de passe',

  'HEADING_A': 'Mot de passe oublié ?',
  'HEADING_B': 'Entrez votre adresse e-mail ci-dessous et nous vous ramènerons sur la bonne voie.',

  'BUTTON_TEXT': 'M\'envoyer mon mot de passe',

  'SIGN_IN_TEXT': 'Se connecter',

  'SUCCESS_TEXT': {
    'A': 'Si un compte',
    'B': 'est associé à cette adresse e-mail,',
    'C': 'nous vous enverrons des instructions pour réinitialiser votre mot de passe.'
  }

};
