export const MARKET_TEST_OBJECTIVES = {

  'HEADINGS': {
    'CATEGORY': 'Que souhaitez-vous faire ?'
  },

  'CATEGORY': {
    'TITLE': {
      'INNOVATE': 'Je souhaite innover',
      'INNOVATION': 'J’ai une innovation'
    },
    'DESCRIPTION': {
      'INNOVATE': 'Je suis en phase d’exploration pour un futur projet.',
      'INNOVATION': 'J’ai un projet concret que je souhaite tester.'
    }
  },

  'WHAT_NEXT': 'Et ensuite ?',

  'ALSO_LIKE': 'J’aimerais aussi…',

  'SEVERAL_CHOICES': 'Plusieurs choix possibles'

};
