import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { NotFoundComponent } from './not-found.component';
import { ButtonModule } from '@umius/umi-common-component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        RouterModule,
        ButtonModule
    ],
  declarations: [
    NotFoundComponent
  ],
  exports: [
    NotFoundComponent
  ]
})

export class NotFoundModule {}
