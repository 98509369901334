export const SHOWCASE = {

  'MODIFY': 'Modify',

  'BUTTON_SAVE': 'Save',

  'SEE_MORE': 'See more',

  'MAIN_TITLE': 'Discover our innovation projects',

  'PROFESSIONALS_TITLE': 'Contributors',

  'PROFESSIONALS': 'respondents',

  'INNOVATIONS_TITLE': 'Market Tests',

  'INNOVATIONS': 'projects tested',

  'COUNTRIES_TITLE': 'Targeted',

  'COUNTRIES': 'countries surveyed',

  'CLIENTS_TITLE': 'Clients',

  'SECTORS_MODAL': {
    'TITLE': 'Choose sectors to create the showcase'
  },

  'CREATE_SHOWCASE': 'Ready to create your showcase!',

  'ERROR_STATS': 'We are having trouble while finding the stats.',

  'TOOLTIP': {
    'MODIFY_TAGS': 'Click to edit tags.',
    'EDIT_ANSWERS': 'Click to select different answers.',
    'EDIT_INNOVATIONS': 'Click to select different innovations.',
    'EDIT_CLIENTS': 'Click to select different clients.'
  },

  'NAME_PLACEHOLDER': 'Name',

};
