import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class IndexService {

  constructor(private _http: HttpClient) { }

  public getWholeSet(config: { type: string, fields?: Array<string>, filterBy?: any }): Observable<any> {
    return this._http.post('/index/getWholeSet', { query: config });
  }

}
