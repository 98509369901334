export const DISCOVER = {

  'MENU_BAR_TITLE': 'Découvrez les innovations',

  'SECTORS': {
    'HEADING': 'Sélectionnez le(s) secteur(s) pour voir les projets liés',
    'HEADING_2': 'secteur(s) sélectionné(s).'
  },

  'BUTTON_SHARE': 'Partager la page',

  'BUTTON_SHOWCASE': 'Obtenir plus de stats',

  'MODAL_SHARE': {
    'TITLE': 'Partagez cette page avec vos collègues',
  },


  'NO_INNOVATION': 'Nous ne pouvons pas trouver d\'innovations basées sur votre requête.',

  'RECOMMENDATION': 'Recommandé',

  'SUGGESTED_SECTORS': 'Secteur(s) associé(s)',

  'LEARN_MORE': 'En savoir plus',

  'LATEST': 'Récent',

  'TRENDING': 'Tendance',

  'BUTTON_COMMUNITY': 'Découvrir la communauté',

  'COMMUNITY_TEXT': 'Vous souhaitez recevoir nos dernières innovations par email ?',

  'TOOLTIP_TEXT': 'Cliquez pour copier le lien de partage dans le presse-papiers',

  'TOOLTIP_TEXT_COPIED': 'Copié dans le presse-papiers',

  'FILTER_TEXT': 'Voir tous les secteurs',

  'FILTER_TEXT_USE_CASE': 'Filtrer par cas d\'usage',

  'SEARCH_INPUT_PLACEHOLDER': 'Commencez à taper pour rechercher....',

  'RESULT' : 'résultats ',

  'FOR': 'pour ',

  'NO_RESULT': 'Aucun résultat trouvé',

  'NO_RESULT_HEADING': 'Aucun résultat trouvé',

  'NO_RESULT_TEXT': 'Essayez d\'ajuster votre recherche ou votre filtre pour trouver ce que vous cherchez.',

  'HIGHLIGHT_SECTOR_HEADING': 'Sélectionnez un ou plusieurs secteurs pour voir les innovations spécifiques',

  'RELATED_SECTORS': 'Tag(s) suggéré(s)',

  'ALL_SECTORS': 'Sélectionnez un ou plusieurs secteurs',

  'FETCHING_ERROR': 'Nous rencontrons des problèmes lors de la récupération des innovations.',

  'FETCHING_ERROR_SUB': 'Cela pourrait être dû à un problème de connexion au serveur.'

};
