export const CAMPAIGNS = {

  'CAMPAIGN-NAME': 'Campaign name',

  'QUIZ': 'Quiz',

  'HISTORY': 'History',

  'SEARCH': 'Search',

  'PROS': 'Pros',

  'MAILS': 'Emails',

  'BATCH': 'Batch',

  'WORKFLOWS': 'Emails',

  'ANSWERS': 'Answers',

  'EXPORT_ANSWERS': 'Export',

  'IMPORT_ANSWERS': 'Import answers',

  'EMAIL_CONFIDENCE': 'Email confidence',

  'ACTIONS': 'Actions',

  'VALIDATED': 'Validated answers',

  'SUBMITTED': 'Submitted answers',

  'TO_COMPLETE': 'Answers to complete',

  'DRAFTS': 'Draft answers',

  'REJECTED': 'Rejected answers',

  'REJECTED_GMAIL': 'Rejected answers by mail',

  'REJECTED_UMIBOT': 'Automatically rejected answers',

  'VALIDATED_UMIBOT': 'Automatically validated answers',

  'DISQUALIFIED': 'Disqualified answers',

  'DELETE_MODAL': {
    'TITLE': 'Are you sure?',
    'CONTENT': 'Are you sure you want to definitively delete this campaign? It may contain professionals that will be removed from the innovation.'
  },

  'SELECT_DEFAULT': {
    'TITLE': 'Are you sure?',
    'CONTENT' : `Some batches can be in process. Are you sure you want to change workflow ? It will change for every batch.`
  },

  'STARTAB' : {
    'TITLE' : 'Are you sure?',
    'CONTENT' : ` A/B test launch is irreversible, you won't be able to undo this configuration.
    The technical team and direction team are relieved of any responsibility. ;)`
  },

  'ADD_BUTTON': 'Add Campaign',

  'BOT_TEXT': {
    'A': 'Don\'t have any campaigns?',
    'B': 'Create the campaign by clicking on "Add Campaign" button.'
  },

  'PROFESSIONAL': 'Professional',

  'STATUS': 'status',

  'UPDATE_BUTTON': 'Update Status',

  'CLONE_BUTTON': 'Clone',

  'DELETE_BUTTON': 'Delete',

  'EDIT_BUTTON': 'edit',

  'STATS': {
    'HEADING': {
      'INSIGHTS': 'Insights',
      'PROFILE': 'Profile',
      'QUALITY': 'Quality',
      'PROFESSIONALS': 'Professionals',
      'EMAILS': 'Emails',
      'COST': 'Cost',
      'RECEPTION': 'Reception',
      'INTERACTION': 'Interaction',
      'DISPLAY': 'Display'
    },
    'TITLE': {
      'LOOK': 'To look at',
      'VALIDATED': 'Validated',
      'VALIDATED_WITHOUT': 'Validated no mail',
      'REJECTED': 'Rejected',
      'STANDARD': 'Standard',
      'TOP': 'Top',
      'FILL_RATE': 'Fill rate',
      'TIME': 'Time',
      'FIND': 'Find',
      'NOT_REACHED': 'Not reached',
      'STARS': 'Stars',
      'DUPLICATE': 'Duplicate',
      'GOOD': 'Good',
      'UNSURE': 'Unsure',
      'BAD': 'Bad',
      'REQUEST': 'Request',
      'EMAILS': 'Emails',
      'GOOD_EMAILS': 'Good emails',
      'RECEIVED': 'Received',
      'SPAM': 'Spam',
      'BOUNCES': 'Bounces',
      'OPENED': 'Opened',
      'CLICKED': 'Clicked',
      'EMAIL': 'Email',
      'QUESTIONNAIRE': 'Questionnaire'
    },

    'TO_CONTACT': 'To contact',
    'CONTACTED': 'Contacted',
    'OPENED': 'Opened',
    'CLICKED': 'Clicked',
    'STARTED': 'Started',
    'SENT': 'Sent',

  },

  'QUIZ_PAGE': {
    'HEADING': 'Visit this link to see the questionnaire:',
    'BOT': 'No quiz has been generated for this campaign.'
  },

  'PROS_PAGE': {
    'BUTTON': {
      'IMPORT': 'Import',
      'EXPORT': 'Export',
      'ADD': 'Add'
    },
    'MODAL': {
      'IMPORT': {
        'TITLE': 'Import Board',
        'PLACEHOLDER': 'Enter the campaign name',
      }
    }
  },

  'WORKFLOW_PAGE': {
    'BUTTON': {
      'IMPORT': 'Import',
      'TEST_WORKFLOW': 'Test Workflow'
    },
    'BOT': {
      'MESSAGE_A': 'Don\'t have any emails?',
      'MESSAGE_B': 'Try to add some by clicking on "Import" button.'
    },
    'MODAL': {
      'CONTENT_A': 'This workflow is already imported. If you import it again it will replace the first one. Do you really want to import this template?',
      'CONTENT_B': 'You are adding a new workflow in the campaign, you will be able to use A/B testing!'
    },
    'NOTE': 'Note: It is advised to you to modify the imported template/emails before launching the batch.',
  },

  'BATCH_PAGE': {
    'NOTE': 'Note: It is not possible to launch the batch for this campaign at the moment because of the following reason(s): ',
    'QUIZ': 'Quiz',
    'INNOVATION': 'Project validation',
    'EMAILS': 'No emails are defined.',
    'WORKFLOW': 'Workflow',
    'BOT': 'Launch auto-batch and take a holiday :)',
    'AUTO_BATCH_LABEL': 'Auto-batch started',
    'NUGGETS': 'Nuggets',
    'TITLE': 'Batch for',
    'MODAL_DELETE_CONTENT': 'Do you really want to delete this batch?',
    'ERROR': 'There has been an error when sending this batch!',
    'CREATING_BATCH': 'We are in progress of creating the batch for this campaign. Please be patient!',
  },

  'ERROR_MESSAGE': {
    'FETCHING': 'We are having trouble while fetching the answers for this campaign. It could be because we could not have the answers.',
    'FETCHING_PROFESSIONALS': 'We are having trouble while fetching the professionals for this campaign. It could be because of server connection problem or no professionals.',
  },

  'NO_ANSWER': 'No answers for this campaign. Try to import some!',

  'NO_PROFESSIONALS': 'No professionals for this campaign. Try to add/import some!',

  'LABEL_IMPORT': 'Import professionals from campaign (only one campaign at a time)',

  'DEFAULT_QUESTION' :{
    'JOBTITLE': 'Which of the following job titles best describes your current position in your company?',
    'ENTERPRISESIZE': "What is the size of your company?",
    'SECTORS': 'In which sector of activity are you currently working?',
    'NA': 'NA'
  },

  'QUESTION_INSTRUCTION': {
    'SCALE': '1 = Not at all / 10 = Totally',
    'STARS': '0 star = Not at all / 5 stars = Totally',
    'CHECKBOX': 'You can select up to 4 items',
    'RANKING': 'Drag and drop to rank items',
    'DEFAULT': 'You can select up to 4 options'
}

};
