export const SIDEBAR_USER_ANSWER = {
  'RECEIVED': {
    'A': 'Received:',
    'B': 'at',
    'C': 'Time spent:'
  },

  'VISIBILITY': 'Visible on client side',

  'STATUS': {
    'REJECTED': 'Rejected',
    'REJECTED_GMAIL': 'Rejected by Mail',
    'VALIDATED': 'Validated',
    'SUBMITTED': 'To look at',
    'DISQUALIFIED': 'Disqualified',
    'EMAIL': 'Send email',
    'DRAFT': 'Draft',
    'VALIDATED_NO_MAIL': 'Validated no mail',
    'VALIDATED_UMIBOT': 'Validated by bot',
    'REJECTED_UMIBOT': 'Rejected by bot',
  },

  'ANSWER': {
    'TARGETING': {
      'OUTSIDE': 'The country of this answer is OUTSIDE the project targeting',
      'FROM_EXCLUDED_COUNTRY': 'The country of this answer is in the EXCLUDED countries list',
      'EMPTY_COUNTRY': 'This answer has no country!'
    }
  },

  'CONFIRMATION_CUSTOM': {
    'TITLE': 'New data label',
    'CONTENT': 'This new data label \"{{value}}\" will be displayed on every professional cards in this innovation.'
  },

  'CONFIRMATION': {
    'TITLE': 'New data label \"{{value}}\" created',
    'CONTENT': 'New data label \"{{value}}\" will apply to all answers on this project.'
  },

  'CONFIRMATION_PREDEFINED_CUSTOM': {
    'TITLE': ' New custom data for predefined data label',
    'CONTENT': 'Do you really want to create the new custom data "\"{{type}}\" : \"{{value}}\""?',
  },

  'REPLACE': {
    'TITLE': 'Changes in the database',
    'CONTENT': 'Do you want to replace existing data in the database: "{{label}} = {{oldValue}}", with "{{label}} = {{newValue}}" ?',
    'REPLACE': 'Replace existing data in the database',
    'KEEP': 'Highlight professional only in this innovation'
  },

  'CONFIRMATION_DELETE' : {
    'TITLE': 'Delete this data label \"{{value}}\"',
    'CONTENT': '\"{{value}}\" will be removed from every professional cards within this innovation.'
  },

  'TOOLTIP': {
    'EDIT': 'Edit : \"{{value}}\"',
    'DELETE': 'Delete : \"{{value}}\"',
  },

  'MODAL':{
    'CANCEL': 'Cancel',
    'CONFIRM': 'Confirm',
  },

  'VALIDATED_ANSWER': 'Answer validated',
  'UNVALIDATED_ANSWER': 'Answer not validated',
  'JOB': 'Job',
  'COMPANY': 'Company',
  'BLACKLISTED_COMPANY': 'The answer may come from a blacklisted company!',
  'EDIT': 'Edit',
  'TIME_SPENT': 'Time spent',
  'REJECT_ANSWER': 'Reject',
  'UNCOMPLETE_ANSWER': 'To Complete',
  'VALID_ANSWER': 'Validate',
  'VALID_ANSWER_NO_MAIL': 'Validate without sending mail',
  'INVALID_ANSWER': 'Invalidate',
  'EDIT_ANSWER': 'Edit the answer',
  'REASSIGN_ANSWER': 'Reassign the answer',
  'ADD_NEW_EMAIL': 'Add a second email'
};
