export const PRESETS = {

  'NO_PRESETS': 'Pas de questionnaire. Essayez d\'en ajouter !',

  'ALREADY_EXIST': 'Le preset du même nom existe déjà.',

  'ADDED': 'Le preset a été ajouté avec succès.',

  'DELETED': 'Le preset a été supprimé avec succès.',

  'CLONED': 'Le preset a été cloné avec succès.',

  'NO_MULTIPLE_CLONE': 'Désolée. Cette fonctionnalité n\'est pas disponible sur plusieurs presets.',

  'MODAL': {
    'DELETE': 'Souhaitez-vous vraiment supprimer le(s) Preset(s) sélectionné(s) ?',
    'CLONE': 'Voulez-vous vraiment cloner le preset sélectionné ?',
  },



  'PRESET': {
    'ADD': 'Ajouter une configuration',
    'FILTER': 'Rechercher',
    'DELETE_MODAL': {
      'TITLE': 'Êtes-vous sûr(e) ?',
      'CONTENT': 'Êtes-vous sûr(e) de vouloir définitivement supprimer cette configuration ? Elle est peut-être utilisée pour un autre projet !'
    },
    'CLONE_MODAL': {
      'TITLE': 'Clone de configuration',
      'CONTENT': 'Donnez un nouveau nom à la configuration clônée'
    },
    'NONE': {
      'TITLE': 'Vous n\'avez pas encore de configuration',
      'CONTENT': 'Cliquez sur le bouton pour ajouter votre première configuration'
    },
    'NEW': 'Nouvelle configuration',
    'GO': 'Créer la configuration',
    'GIVE_NAME': 'Donnez un nom à la configuration',
    'DONT_USE_SPACES': 'Merci de ne pas utiliser d\'espaces dans le nom.',
    'UNIQUE_NAME': 'Attention, ce nom ne doit pas être déjà utilisé dans les configurations existantes',
    'EDIT': {
      'SAVE': 'Sauvegarder la configuration',
      'ADD': 'Ajouter des sections à la configuration',
      'SECTION_PLACEHOLDER': 'marketPotential'
    }
  },
  'SECTION': {
    'ADD': 'Ajouter une section',
    'FILTER': 'Rechercher',
    'DELETE_MODAL': {
      'TITLE': 'Êtes-vous sûr(e) ?',
      'CONTENT': 'Êtes-vous sûr(e) de vouloir définitivement supprimer cette section ? Elle est peut-être utilisée pour une autre configuration !'
    },
    'CLONE_MODAL': {
      'TITLE': 'Clone de section',
      'CONTENT': 'Donnez un nouveau nom à la section clônée'
    },
    'DESCRIPTION': {
      'nothing': 'Rien',
      '1st': 'Problème',
      '2nd': 'Solution'
    },
    'NONE': {
      'TITLE': 'Vous n\'avez pas encore de section',
      'CONTENT': 'Cliquez sur le bouton pour ajouter votre première section'
    },
    'NEW': 'Nouvelle section',
    'GO': 'Créer la section',
    'GIVE_NAME': 'Donnez un nom à la section',
    'DONT_USE_SPACES': 'Merci de ne pas utiliser d\'espaces dans le nom.',
    'UNIQUE_NAME': 'Attention, ce nom ne doit pas être déjà utilisé dans les sections existantes',
    'EDIT': {
      'SAVE': 'Sauvegarder la section',
      'ADD': 'Ajouter des questions à la section',
      'TITLE': 'Titre de la section dans le quiz',
      'DESCRIPTION': 'Description de la section',
      'QUESTION_PLACEHOLDER': 'relevantProblematic'
    }
  },
  'QUESTION': {
    'ADD': 'Ajouter une question',
    'FILTER': 'Rechercher',
    'DELETE_MODAL': {
      'TITLE': 'Êtes-vous sûr(e) ?',
      'CONTENT': 'Êtes-vous sûr(e) de vouloir définitivement supprimer cette question ? Elle est peut-être utilisée pour un autre projet !'
    },
    'CLONE_MODAL': {
      'TITLE': 'Clone de question',
      'CONTENT': 'Donnez un nouveau nom à la question clônée'
    },
    'NONE': {
      'TITLE': 'Vous n\'avez pas encore de question',
      'CONTENT': 'Cliquez-sur le bouton pour ajouter votre première question'
    },
    'NEW': 'Nouvelle question',
    'GO': 'Créer la question',
    'GIVE_NAME': 'Donnez un nom à la question',
    'DONT_USE_SPACES': 'Merci de ne pas utiliser d\'espaces dans le nom.',
    'UNIQUE_NAME': 'Attention, ce nom ne doit pas être déjà utilisé dans les questions existantes',
    'TAKEN_NAME': 'Ce nom est déjà utilisé, merci d\'en choisir un autre',
    'TYPE': {
      'CHOOSE': 'Choisissez le type de question',
      'list': 'Liste d\'items',
      'clearbit': 'Liste d\'entreprises',
      'radio': 'Choix unique',
      'scale': 'Notes',
      'stars': 'Notes étoiles',
      'textarea': 'Texte',
      'toggle': 'Toggle',
      'checkbox': 'Choix multiples',
      'ranking': 'Classement',
      'likert scale': 'Likert scale',
      'yes-no': 'Oui/Non',
      'matrix': 'Matrice',
      'custom-content': 'Bloc de contenu'
    },
    'EDIT': {
      'TITLE': 'Titre dans l\'infographie',
      'SUBTITLE': 'Sous-titre dans l\'infographie',
      'LABEL': 'Intitulé de la question',
      'CAN_COMMENT': 'Possibilité d\'ajouter un commentaire',
      'SAVE': 'Sauvegarder la question',
      'CHOICES': 'Réponses possibles',
      'ADD': 'Ajouter une autre réponse',
      'PARAMETERS': {
        'TITLE': 'Paramètres',
        'UNITY': 'Unité'
      }
    }
  }
};
