export const environment = {
  local: false,
  production: true,
  env: 'prod',
  apiUrl: 'https://appv3.umi.us/api',
  etherpadUrl: 'https://etherpad.umi.us',
  companyName: 'United Motion Ideas',
  clientUrl: 'https://umicli.umi.us',
  companyShortName: 'UMI',
  domain: 'umi',
  quizUrl: 'https://quiz.umi.us',
  grafanaUrl: 'https://grafana.umi.us',
  companyURL: 'https://umi-innovation.com',
  logoURL: 'https://res.cloudinary.com/umi/image/upload/app/default-images/company-logo/logo-umi-animated-2020.gif',
  logoSynthURL: 'https://res.cloudinary.com/umi/image/upload/app/default-images/company-logo/Logotype_blue_background.png',
  background: 'https://res.cloudinary.com/umi/image/upload/f_auto/app/default-images/backgrounds/umi-welcome-picture-2021.jpg',
  commercialContact: 'achampagne@umi-innovation.com',
  secureCookie: true,
  cookieTime: 48 * 3600 * 1000
};
