export const DISCOVERDESCRIPTION = {
  'AVAILABLE': 'Project already available',

  'PATENT': 'Protected by patent',

  'SHARE': 'Do you like the project? Share it, collect opinions and help it come to fruition!',

  'OPINION': 'Give my feedback',

  'MEDIA': 'Media not available.',

  'BENEFITS': 'Benefits',

  'ISSUE': 'Issue',

  'SOLUTION': 'Solution',

  'MEDIAS': 'Medias',

  'STAGE': {
    'A': 'Idea',
    'B': 'Development in progress',
    'C': 'Already available'
  },

  'ERROR': 'We are unable to fetch this innovation at this moment. Please try to reload this innovation!',

  'CONTACT_US': 'Contact us',

  'DISCOVER_OTHER': 'Discover other projects',

  'SIDEBAR_BOT_MESSAGE': 'Describe your project first to preview it.',

  'BACK': 'List of innovations',

  'SHARE_TITLE': 'PLEASE SHARE THIS',

  'RELATED': 'YOU MIGHT ALSO LIKE',

};
