export const MARKET_TEST_OBJECTIVES = {

  'HEADINGS': {
    'CATEGORY': 'What would you like to do?'
  },

  'CATEGORY': {
    'TITLE': {
      'INNOVATE': 'I want to innovate',
      'INNOVATION': 'I have an innovation'
    },
    'DESCRIPTION': {
      'INNOVATE': 'I\'m in an exploration phase for a future project.',
      'INNOVATION': 'I have a specific project that I would like to test.'
    }
  },

  'WHAT_NEXT': 'What next?',

  'ALSO_LIKE': 'I would also like to...',

  'SEVERAL_CHOICES': 'Several choices are possible'

};
