export const LOG_IN = {
  'TITLE': 'Connexion',

  'DOES_NOT_HAVE_ACCOUNT': 'Vous n\'avez pas de compte ?',

  'LOG_IN_BTN': 'Se connecter',

  'HEADING': 'Bienvenue !',

  'LOG_IN_LINKEDIN': 'Se connecter avec LinkedIn',

  'SIGN_UP': 'Inscription',

  'SUCCESS_EMAIL': 'Nous avons reçu votre courriel et notre équipe vous contactera bientôt.',

  'ERROR_EMAIL': 'Désolé, nous n\'avons pas pu traiter votre demande pour le moment, veuillez essayer plus tard.'

};
