/**
 * Created by bastien on 08/12/2017.
 */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class TranslateNotificationsService {

  constructor(private _translateService: TranslateService,
              private _toastrService: ToastrService) {
  }

  public error(title: string, message: string, config?: any) {
    return this._toastrService.error(
      message ? this.formatErrorMessage(message) : '',
      title ? this._translateService.instant(title) : '',
      config
    );
  }


  public warn(title: string, message: string, config?: any) {
    return this._toastrService.warning(
      message ? this.formatErrorMessage(message) : '',
      title ? this._translateService.instant(title) : '',
      config
    );
  }

  public success(title: string, message: string, config?: any) {
    return this._toastrService.success(
      message ? this._translateService.instant(message) : message,
      title ? this._translateService.instant(title) : title,
      config
    );
  }

  public info(title: string, message: string, config?: any) {
    return this._toastrService.info(
      message ? this._translateService.instant(message) : message,
      title ? this._translateService.instant(title) : title,
      config
    );
  }

  /**
   * if the error is not in Error system, display default error
   * @param message
   * @private
   */
  private formatErrorMessage(message: string) {
    message = this._translateService.instant(message);
    if (message.indexOf('ERROR.') !== -1) {
      message = this._translateService.instant('ERROR.OPERATION_ERROR');
    }
    return message;

  }


}
