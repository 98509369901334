import { COMMON } from './common_fr';
import { ERROR } from './error_fr';
import { ADMIN_USERS } from '../modules/user/admin/components/admin-users/i18n/fr';
import {CONTINENTS} from '../modules/shared/components/shared-worldmap/i18n/fr';
import { MY_ACCOUNT } from '../modules/user/client/components/account/i18n/fr';
import { MARKET_REPORT } from '../modules/shared/components/shared-market-report/i18n/fr';
import { PRESETS } from '../modules/user/admin/components/admin-libraries/admin-presets/i18n/fr';
import { CAMPAIGNS } from '../modules/user/admin/components/admin-campaigns/i18n/fr';
import { SEARCH } from '../modules/user/admin/components/admin-search/i18n/fr';
import { PROJECT_MODULE } from '../modules/user/client/components/project/i18n/fr';
import { DISCOVER } from '../modules/public/discover/i18n/fr';
import { DISCOVERDESCRIPTION } from '../modules/public/discover/components/discover-description/i18n/fr';
import { SIDEBAR_USER_ANSWER } from '../modules/sidebars/components/sidebar-user-answer/i18n/fr';
import { SIDEBAR_ENTERPRISE } from '../modules/sidebars/components/sidebar-enterprises/i18n/fr';
import { LOG_IN } from '../modules/common/login/i18n/fr';
import { SIGN_UP } from '../modules/common/signup/i18n/fr';
import { WELCOME } from '../modules/common/welcome/i18n/fr';
import { SHARE } from '../modules/public/share/i18n/fr';
import { FOOTER } from '../modules/common/footer/i18n/fr';
import { HEADER } from '../modules/common/header/i18n/fr';
import { RESET_PASSWORD } from '../modules/common/login/components/reset-password/i18n/fr';
import { FORGET_PASSWORD } from '../modules/common/login/components/forget-password/i18n/fr';
import { EDIT_WORKFLOW } from '../modules/user/admin/components/admin-edit-workflow/i18n/fr';
import { SIDEBAR } from '../modules/sidebars/i18n/fr';
import { SHARED_PROJECT_SETTINGS } from '../modules/shared/components/shared-project-settings-component/i18n/fr';
import { SHOWCASE } from '../modules/demo/components/showcase/i18n/fr';
import { SEARCH_DEMO } from '../modules/demo/components/search-tool/i18n/en';
import { COMMUNITY } from '../modules/user/admin/components/admin-community/i18n/fr';
import { PROJECT } from '../modules/user/admin/components/admin-project/i18n/fr';
import { SHARED_TARGETING_WORLD } from '../modules/shared/components/shared-targeting-world/i18n/fr';
import { SHARED_TARGETING } from '../modules/shared/components/shared-targeting/i18n/fr';
import { SHARED_FOLLOW_UP } from '../modules/shared/components/shared-follow-up/i18n/fr';
import { SIDEBAR_MARKET_REPORT } from '../modules/sidebars/components/sidebar-filter-answers/i18n/fr';
import { SHARED_MAIL_EDITOR } from '../modules/shared/components/shared-mail-editor/i18n/fr';
import { ADMIN_STORYBOARD } from '../modules/user/admin/components/admin-project/admin-project-storyboard/i18n/fr';
import { ADMIN_EXECUTIVE_REPORT } from '../modules/user/admin/components/admin-executive-report/i18n/fr';
import { NEW_PROJECT } from '../modules/user/client/components/new-project/i18n/fr';
import { PROJECT_SETTINGS } from '../modules/user/client/components/project/components/settings/i18n/fr';
import { PROJECT_DOCUMENTS } from '../modules/user/client/components/project/components/documents/i18n/fr';
import { PROJECT_PITCH } from '../modules/user/client/components/project/components/setup/components/pitch/i18n/fr';
import { PROJECT_SETUP } from '../modules/user/client/components/project/components/setup/i18n/fr';
import { PROJECT_COLLECTION } from '../modules/user/admin/components/admin-project/admin-project-collection/i18n/fr';
import {COMMENT_CARD} from '../modules/sidebars/components/comment-card/i18n/fr';
import {SHARED_TEXT_ZONE} from '../modules/shared/components/shared-editors/i18n/fr';
import {MARKET_TEST_OBJECTIVES} from '../modules/user/client/components/market-test-objectives/i18n/fr';
import {MARKET_TEST_METHODOLOGY} from '../modules/user/client/components/market-test-methodology/i18n/fr';
import { TABLE_FR } from './table-fr';
import {SHARED_STANDARD_FILTERS} from "../modules/shared/components/shared-standard-filters/i18n/fr";


export const fr = {
  'COMMON': COMMON,
  'TABLE': TABLE_FR,
  'CONTINENTS': CONTINENTS,
  'ERROR': ERROR,
  'MARKET_REPORT': MARKET_REPORT,
  'USERS': ADMIN_USERS,
  'FOOTER': FOOTER,
  'SIDEBAR_USER_ANSWER': SIDEBAR_USER_ANSWER,
  'SIDEBAR_ENTERPRISE': SIDEBAR_ENTERPRISE,
  'DISCOVER': DISCOVER,
  'DISCOVERDESCRIPTION': DISCOVERDESCRIPTION,
  'LOG_IN': LOG_IN,
  'MY_ACCOUNT': MY_ACCOUNT,
  'SIGN_UP': SIGN_UP,
  'WELCOME': WELCOME,
  'HEADER': HEADER,
  'PROJECT': PROJECT,
  'PRESETS': PRESETS,
  'CAMPAIGNS': CAMPAIGNS,
  'SEARCH': SEARCH,
  'PROJECT_MODULE': PROJECT_MODULE,
  'SHARE': SHARE,
  'RESET_PASSWORD': RESET_PASSWORD,
  'FORGET_PASSWORD': FORGET_PASSWORD,
  'EDIT_WORKFLOW': EDIT_WORKFLOW,
  'SIDEBAR': SIDEBAR,
  'SHARED_PROJECT_SETTINGS': SHARED_PROJECT_SETTINGS,
  'SHOWCASE': SHOWCASE,
  'SEARCH_DEMO': SEARCH_DEMO,
  'COMMUNITY': COMMUNITY,
  'SHARED_TARGETING_WORLD': SHARED_TARGETING_WORLD,
  'SHARED_STANDARD_FILTERS': SHARED_STANDARD_FILTERS,
  'SHARED_TARGETING': SHARED_TARGETING,
  'SHARED_FOLLOW_UP': SHARED_FOLLOW_UP,
  'SIDEBAR_MARKET_REPORT': SIDEBAR_MARKET_REPORT,
  'SHARED_MAIL_EDITOR': SHARED_MAIL_EDITOR,
  'ADMIN_STORYBOARD': ADMIN_STORYBOARD,
  'ADMIN_EXECUTIVE_REPORT': ADMIN_EXECUTIVE_REPORT,
  'NEW_PROJECT': NEW_PROJECT,
  'PROJECT_SETTINGS': PROJECT_SETTINGS,
  'PROJECT_DOCUMENTS': PROJECT_DOCUMENTS,
  'PROJECT_PITCH': PROJECT_PITCH,
  'PROJECT_SETUP': PROJECT_SETUP,
  'PROJECT_COLLECTION': PROJECT_COLLECTION,
  'COMMENT_CARD': COMMENT_CARD,
  'SHARED_TEXT_ZONE': SHARED_TEXT_ZONE,
  'MARKET_TEST_OBJECTIVES': MARKET_TEST_OBJECTIVES,
  'MARKET_TEST_METHODOLOGY': MARKET_TEST_METHODOLOGY,
};
