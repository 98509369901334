export const PROJECT_SETTINGS = {

  'TITLE': 'Titre',

  'PRINCIPAL_OBJECTIVE': 'Objectif principal',

  'SECONDARY_OBJECTIVE': 'Objectifs secondaires',

  'ROADMAP': 'Roadmap',

  'RESTITUTION_DATE': 'Date de livraison',

  'REPORTING_LANG': 'Résultat langue',

  'OWNER': 'Propriétaire',

  'COLLABORATORS': 'Collaborateurs',

  'COLLABORATORS_CONSENT': 'Veuillez noter que les collaborateurs ont accès au projet ainsi que aux résultats et aux autres ' +
    'données sensibles. Voulez vous continuer ?',

  'OPERATOR': 'Project manager',

  'COMMERCIAL': 'Commercial',

  'LANGUAGE': 'Langue',

  'AUTHORISATION': 'Autorisation',

  'HEADING': {
    'TITLE': 'Titre interne du projet',
    'PRINCIPAL_OBJECTIVE': 'Objectif principal',
    'SECONDARY_OBJECTIVE': 'Objectifs secondaires',
    'ROADMAP': 'Roadmap prévisionnelle',
    'REPORTING_LANG': 'Langue des résultats du Test Marché',
    'RESTITUTION_DATE': 'Date de livraison souhaitée',
    'OWNER': 'Propriétaire',
    'COLLABORATORS': 'Collaborateurs',
    'OPERATOR': 'Project manager',
    'COMMERCIAL': 'Commercial',
    'LANGUAGE': 'Langue de travail',
    'AUTHORISATION': 'Autorisation de diffusion'
  },

  'NOT_ASSIGNED': 'Non assigné.',

  'NO_COLLABORATORS': 'Pas de collaborateurs.',

  'NO_SECONDARY': 'Pas d\'objectifs secondaires.',

  'TOOLTIP_PENDING': 'Ce collaborateur n’a pas encore de compte.',

  'PROJECT_IN': 'Projet en ',

  'AUTHORISATION_TEXT': 'Dans le but d\'optimiser les feedbacks, j’autorise UMI à rendre visible mon projet anonymement sur ses réseaux :',

  'SOCIAL': 'Réseaux sociaux',

  'UMI': 'Site web',

  'COMMUNITY': 'Espace communauté',

  'MODAL_DELETE': {
    'COLLABORATOR': 'Voulez-vous vraiment supprimer ce collaborateur ?',
    'CARD': 'Voulez-vous vraiment supprimer le projet dans cette langue ?'
  },

  'MESSAGE': {
    'PENDING_EMAIL': 'L\'invitation est déjà envoyée à cette adresse e-mail.',
    'ALREADY_ADDED': 'L\'adresse e-mail est déjà ajoutée à la liste.',
    'ALREADY_EXISTS': 'Le collaborateur existe déjà.'
  },

  'SELECT_COMMERCIAL': 'Sélectionnez un commercial',

  'SELECT_OPERATOR': 'Sélectionnez un Project manager',

  'RESTITUTION_DATE_COMMENT': 'Avez-vous des précisions à apporter ?',

  'PRIMARY_OBJECTIVE_CONSENT': 'Une modification de votre cas d\'usage peut impacter votre questionnaire. Voulez vous continuer ?',

  'SECONDARY_OBJECTIVE_CONSENT': 'Une modification de vos objectifs complémentaires impactera votre questionnaire. Voulez-vous continuer ?',

};
