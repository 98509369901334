export const COMMENT_CARD = {

  'PROPOSE_REPLACE': 'Propose de remplacer',
  'PROPOSE_MODIFY': 'Proposer une modification',
  'REPLACE_FROM': 'Remplacer',
  'REPLACE_TO': 'par',
  'REPLY': 'reply',
  'REPLIES': 'replies'

};
