export const SIDEBAR_MARKET_REPORT = {

  'MY_VIEWS': 'My views',

  'FORM_VIEW_PLACEHOLDER': 'Name of your view',

  'TOOLTIP_LOAD_VIEW': 'Load view',

  'TOOLTIP_EDIT_VIEW': 'Edit view',

  'TOOLTIP_DELETE_VIEW': 'Delete view',

  'ACTIVATED_VIEW': 'Activated view',

  'SELECT_CONTACTS': 'Select contacts according to your criteria',

  'ERRORS': {
    'VIEWS': 'We are having trouble while fetching your custom views.',
    'VIEW_ALREADY_EXIST': 'The view with this name already exists.',
    'VIEW_ALREADY_ACTIVATED': 'The view is already activated.',
  }

};
