export const MY_ACCOUNT = {

  'TITLE': 'Profil',

  'PARA': 'Ajoutez des informations sur vous-même.',

  'CHANGE_PASSWORD': 'Changer de mot de passe',

  'DELETE': 'Supprimer mon compte',

  'DELETE_MODAL': {
    'BOARD_TITLE': 'Suppression',
    'TITLE': 'Voulez-vous vraiment supprimer votre compte ?',
    'CONTENT': 'Nous sommes tristes de vous voir partir. Toutes vos données personnelles seront supprimées. Quoi qu\'il en soit, si vous voulez revenir, vous êtes les bienvenus !'
  },

  'JOB_TITLE_EXAMPLE': 'Ingénieur en  aérodynamique',

  'CHANGE': 'Changer de mot de passe',
};
