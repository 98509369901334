export const SEARCH_DEMO = {

  'SOME_PROS': 'Quelques professionnels',

  'PROFESSIONALS': 'PROFESSIONNELS TROUVÉS',

  'NO_RESULT_HEADING': 'Pas de résultats',

  'NO_RESULT_TEXT': 'Essayer de sélectionner un autre pays',

  'RESULTS_FOR': 'résultats pour '

};
