export const WELCOME = {

  'WELCOME': 'Welcome',

  'I_UNDERSTAND': 'I understand, and accept the',

  'TERMS': 'end user license agreement.',

  'ADMIN_SENTENCE': 'WAIT! You are an administrator. You have super powers and "With great power comes great responsibility", pay attention to what you do around here. ' +
    'Please enter bellow the code we just sent you by email to verify your identity :',

  'MAIN_SENTENCE': 'Thank you for registering. In this platform, you can create your projects, follow their progress and see the synthesis of the results.'

};
