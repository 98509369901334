export const SIDEBAR_MARKET_REPORT = {

  'MY_VIEWS': 'Mes vues',

  'FORM_VIEW_PLACEHOLDER': 'Nom de votre vue',

  'TOOLTIP_LOAD_VIEW': 'Activer la vue',

  'TOOLTIP_EDIT_VIEW': 'Editer la vue',

  'TOOLTIP_DELETE_VIEW': 'Supprimer la vue',

  'ACTIVATED_VIEW': 'Vue activée',

  'SELECT_CONTACTS': 'Sélectionnez les contacts en fonction de vos critères',

  'ERROR': {
    'VIEWS': 'Nous avons du mal à récupérer vos vues personnalisées.',
    'VIEW_ALREADY_EXIST': 'Une vue existe déjà avec ce nom.',
    'VIEW_ALREADY_ACTIVATED': 'La vue est déjà active.',
  }

};
