export const SHARED_STANDARD_FILTERS = {

  'SELECT_CONTINENTS': 'Sélectionnez les continents que vous voulez inclure.',

  'COUNTRIES_FILTER': 'Pays est inclus dans',
  'SENIORITY_FILTER': 'Niveau hiérarchique est inclus dans',
  'JOBTITLE_FILTER': {
    'CONTAINS_WORDS' : 'Métier contient le mot',
    'CONTAINS' : 'Métier contient'
  },
  'COMPANY_FILTER': {
    'CONTAINS_WORDS' : 'Nom d\'entreprise contient le mot',
    'CONTAINS' : 'Nom d\'entreprise contient'
  },
  'SECTORS_FILTER': {
    'IS_ONE_OF' : 'Secteur est au moins un',
    'IS_INCLUDED_IN' : 'Secteur est inclus dans',
    'IS_EXACTLY' : 'Secteur est exactement'
  },
  'OTHER_FILTER' : 'XX est inclus dans',

  'NEXT': 'Suivant',

  'APPLY': 'Appliquer',

  'PREVIOUS': 'Précédent',

  'EDIT': 'Modifier',

  'DELETE' : 'Supprimer',

  'INCLUDE': 'Inclure',

  'EXCLUDE': 'Exclure',

  'SELECT' : 'Sélectionner la nature de donnée à utiliser :',

  'SELECT_EMPTY' : '--Sélectionner une nature de donnée--',

  'SELECT_ENTRIES' : {
    'COUNTRIES' : 'Pays',
    'JOBTITLE' : 'Métier',
    'SENIORITY': 'Niveau hiérarchique',
    'COMPANY': 'Entreprise',
    'SECTORS': 'Secteur',
  },

  'RULES': {
    'COUNTRIES' : 'est inclus dans',
    'SENIORITY': 'est inclus dans',
    'JOBTITLE' : {
      "0":'contient',
      "1":'contient le mot'
    },
    'COMPANY' : {
      "0":'contient',
      "1":'contient le mot'
    },
    'SECTORS' : {
      "0":'est au moins un',
      "1":'est inclus dans',
      "2":'est exactement'
    }
  },

  'SPECIFY_COUNTRIES_TO_EXCLUDE': 'Y a-t-il des pays spécifiques que vous aimeriez exclure ?',

  'SPECIFY_COUNTRIES_TO_INCLUDE': 'Y a-t-il des pays spécifiques que vous aimeriez inclure ?',

  'MODAL': {
    'HEADING': 'Sélectionnez le pays ou le continent.'
  },

  'PLACEHOLDER': {
    'TO_EXCLUDE_COUNTRY': 'Entrez le nom du pays à exclure',
    'TO_INCLUDE_COUNTRY': 'Entrez le nom du pays à inclure',
  },

  'COUNTRIES_SELECTED': 'Pays sélectionnés',
  'COUNTRY_SELECTED': 'Pays sélectionné',

  'TREE-VIEW' :{
    'SEARCH': 'Commencez à taper pour rechercher',
  },

  'BUBBLES':{
    'SEEMORE': 'Voir le détail',
  }
};
