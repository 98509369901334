export const RESET_PASSWORD = {


  'HEADING_A': 'Update your password.',
  'HEADING_B': 'Enter your email address below and we\'ll get you back on track.',

  'BUTTON_TEXT': 'Continue',

  'SIGN_IN_TEXT': 'Back to sign in',

  'SUCCESS_TEXT': {
    'A': 'If there\'s a',
    'B': 'account linked to this email address,',
    'C': 'we\'ll send over instructions to reset your password.'
  }

};
