export const ADMIN_USERS = {
  'TITLE': 'Users',

  'INVITE_USER': 'Invite a user',

  'WAITING_INVITATIONS': 'Waiting invitations',

  'SEARCH': 'Search a user',

  'LAUNCHED_PROJECTS': 'Launched projects',

  'DOMAIN': 'Domain',

  'DELETE': 'Delete user',

  'PITCH-DELETE': 'Are you sure you want to delete the user(s)?',

  'ADMIN': 'Affect as administrator'

};
