export const SHARED_PROJECT_SETTINGS = {

  'GEOGRAPHY': {
    'LABEL': 'Quelle est la portée géographique de ce projet ?',
    'ENTIRE_WORLD': 'Dans le monde entier',
    'SPECIFY_COUNTRIES_TO_EXCLUDE': 'Y a-t-il des pays spécifiques que vous aimeriez exclure ?',
    'NEW_COUNTRY_TO_EXCLUDE_PLACEHOLDER': 'Nom du pays à exclure',
    'COMMENT': 'D\'autres commentaires sur cette section ?',
    'SPECIFIC_REQUEST_PLACEHOLDER': 'Cibler en priorité les USA, l\'UE, la Corée, le Japon et la Chine',
    'ERROR': 'La zone géographique est requise.'
  },

  'NO': 'Aucun',
  'NOT_ANSWER': 'Vous n\'avez pas répondu à ce champ.',

  'GEOGRAPHYs': 'Sélectionnez la zone géographique du projet',
  'SPECIFIC_REQUEST': 'Demande spécifique',

  'MARKET': {
    'LABEL': 'Quels secteurs de marché souhaitez-vous cibler avec votre projet ?',
    'PLACEHOLDER': 'Ex : Branche micro-électronique, secteur photovoltaïque, sécurité industrielle...',
    'ERROR': 'Le secteur du marché est requis.'
  },

  'PROFESSIONALS': {
    'LABEL': 'Quels types de professionnels aimeriez-vous entendre parler de ce projet ? ',
    'PLACEHOLDER': 'Ingénieur R&D, Responsable Business Development, Directeur de l\'Innovation....\n' +
      'N\'hésitez pas à inclure de vrais profils LinkedIn : John Doe chez IBM http://linkedin.com/in/johndoe',
    'TO_EXCLUDE': 'Y a-t-il des personnes spécifiques que vous aimeriez exclure ?',
    'NEW_PROFESSIONAL_TO_EXCLUDE_PLACEHOLDER': 'John Doe (http://www.linkedin.com/in/johndoe)'
  },

  'COMPANIES': {
    'LABEL': 'Quels types d\'entreprises aimeriez-vous cibler/contacter et y en a-t-il que vous aimeriez exclure ?',
    'PLACEHOLDER': 'Ex : Les équipementiers et constructeurs automobiles, et les sociétés de transport',
    'TO_EXCLUDE': 'Indiquer les entreprises à exclure',
    'NEW_COMPANY_TO_EXCLUDE_PLACEHOLDER': 'Nom de l\'entreprise à exclure',
    'TO_INCLUDE': 'Exemple d\'entreprises à cibler',
    'NEW_COMPANY_TO_INCLUDE_PLACEHOLDER': 'Nom de l\'entreprise à inclure',
    'COMMENT': 'D\'autres commentaires sur cette section ?'
  },

  'OTHER': {
    'LABEL': 'Y a-t-il autre chose qu\'il nous serait utile de savoir ? ',
    'PLACEHOLDER': 'N\'hésitez pas à l\'inclure :\n' +
      'Acronymes (ex : ANDRH)\n' +
      'Normes que vous souhaitez vérifier (ex : ISO27001)\n' +
      'Informations complémentaires sur un produit (ex : Ansys Maxwell est une solution concurrente) \n' +
      'Termes généraux ou jargon (ex : dynamique des fluides numérique, bâtiment LEED, moteurs Brushless, etc.)\n'
  },

  'KEYWORDS': {
    'LABEL': 'Mots-clefs',
    'PLACEHOLDER': 'Ex : Computational fluid dynamics, LEED Building, Moteur brushless'
  },

  'BLACKLIST': {
    'DOMAINS': 'Blacklist domaines',
    'DOMAINS_PLACEHOLDER': 'Nom de domaine à exclure',
    'EMAILS': 'Blacklist emails',
    'EMAILS_PLACEHOLDER': 'Email à exclure'
  },

  'BOT': {
    'HELP': 'Vous avez de la difficulté à décrire votre projet ? Notre équipe est toujours là pour vous aider !'
  },

  'CONTACT_INTERNAL': {
    'LABEL': 'Je souhaite collecter l\'avis de mes collaborateurs',
    'NO': 'Non, je ne veux pas collecter l\'avis de mes collaborateurs ',
    'YES': 'Oui, je voudrais collecter l\'avis de mes collaborateurs.',
    'VALUE_FALSE': 'Non',
    'VALUE_TRUE': 'Oui',
  },

};
