export const RESET_PASSWORD = {
  'TITLE': 'Réinitialiser le mot de passe',

  'HEADING_A': 'Mettre à jour votre mot de passe.',
  'HEADING_B': 'Entrez votre adresse e-mail ci-dessous et nous vous ramènerons sur la bonne voie.',

  'BUTTON_TEXT': 'Continuer',

  'SIGN_IN_TEXT': 'Se connecter',

  'SUCCESS_TEXT': {
    'A': 'Si un compte',
    'B': 'est associé à cette adresse e-mail,',
    'C': 'nous vous enverrons des instructions pour réinitialiser votre mot de passe.'
  }

};
