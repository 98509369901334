<div id="not-found">

  <header class="header__height header__bg-color">
    <div class="container fluid grid-2xl">
      <div class="column col-sm-12 col-11 col-mx-auto">
        <nav class="navbar border-no">
          <div class="navbar-brand">
            <a [routerLink]="'/user/projects'" class="gtm-menu-logo navbar-item m-no">
              <img
                [alt]="companyShortName"
                style="margin-top: -1px"
                [src]="logo"
                height="44.48"
                width="85">
            </a>
          </div>
        </nav>
      </div>
    </div>
  </header>

  <main class="content-wrapper animate-fade is-3">
    <img src="https://res.cloudinary.com/umi/image/upload/app/default-images/not-found.svg" alt="">
    <h1 class="title is-4 m-bottom-10">
      <ng-container *ngIf="currentLang === 'fr'; else titleEn">Whoops - erreur 404</ng-container>
      <ng-template #titleEn>Whoops - error 404</ng-template>
    </h1>
    <p class="m-bottom-30 text-md mxw-500 m-left-auto m-right-auto">
      <ng-container *ngIf="currentLang === 'fr'; else messageEn">
        Désolé, mais la page que vous recherchez n'existe pas, a été supprimée, a changé de nom ou est
        temporairement indisponible.
      </ng-container>
      <ng-template #messageEn>
        Sorry, but the page you're looking for doesn't exist, have been removed, name changed or is temporarily
        unavailable.
      </ng-template>
    </p>
    <button
      [id]="'btn-homepage'"
      [routerLink]="'/user/projects'"
      umiusButton umiSize="large"
      >
      {{ 'COMMON.BUTTON.RETURN_HOMEPAGE' | translate }}
    </button>
  </main>

</div>

