export const ADMIN_EXECUTIVE_REPORT = {

  'INSERT_SNIPPET': 'Insérer un extrait',

  'OBJECTIVE': {
    'OBJECTIVE': 'Objectif',
    'LEADER': 'Porteur de projet',
    'CONTACT': 'Contact'
  },

  'PITCH': {
    'TITLE': 'Project pitch',
    'PROJECT_PITCH': 'Le pitch du projet'
  },

  'TARGETING': {
    'TARGETING': 'Résumé du ciblage',
    'TITLE': 'Professionnels ciblés'
  },

  'PROFESSIONAL': {
    'ABSTRACT': 'Résumé professionnel',
    'SOME_PROFILES': 'Quelques profils',
    'CHOOSE_PRO': 'Choisissez un pro',
  },

  'CONCLUSION': {
    'CONCLUSION': 'Conclusion',
    'OPERATOR': 'Choisir un opérateur'
  },

  'MODAL': {
    'RESET': 'Voulez-vous vraiment réinitialiser cette section ?'
  },

  'SAVE': 'Le rapport exécutif a été sauvegardé avec succès.',

  'WANT_TO_DISPLAY': 'Que voudriez-vous afficher ?',

  'SYNTHESIS_SECTIONS': 'Sections de synthèse',

  'RAW_DATA': 'Raw data',

  'RESET_SECTION': 'Réinitialiser la section',

  'CHANGE_VISUAL': 'Visualisation des changements',

  'TITLE': 'Titre',

  'ABSTRACT': 'Résumé',

  'NAME': 'Nom',

  'JOB': 'Job',

  'CUSTOM_SECTION': 'Section personnalisée',

  'KPI_SECTION': {
    'KPI': 'KPI',
    'LEGEND': 'Legend',
    'EXAMPLES': 'Exemples'
  },

  'QUOTE_SECTION': {
    'QUOTATION': 'Citation',
    'SHOW_QUOTES': 'Afficher les guillemets',
  },

  'BAR_SECTION': {
    'SHOW_EXAMPLES': 'Voir les exemples de cas ci-dessous',
    'CASE_1': 'Cas 1',
    'CASE_2': 'Cas 2',
    'CASE_3': 'Cas 3',
    'EXAMPLE_1': 'Exemple 1',
    'EXAMPLE_2': 'Exemple 2',
    'EXAMPLE_3': 'Exemple 3'
  },

  'RANKING_SECTION': {
    'LEGEND_1': 'Legend 1',
    'LEGEND_2': 'Legend 2',
    'LEGEND_3': 'Legend 3',
    'ELEMENT_1': 'Exemple 1',
    'ELEMENT_2': 'Exemple 2',
    'ELEMENT_3': 'Exemple 3'
  },

  'PIE_SECTION': {
    'LEGEND_1': 'Legend 1',
    'LEGEND_2': 'Legend 2',
    'LEGEND_3': 'Legend 3',
    'LEGEND_4': 'Legend 4',
    'SHOW_RESPONSES': 'Nombre de réponses favorables',
    'FAVOURABLE': ' des réponses sont favorables'
  },

  'LIKERT-SCALE_SECTION': {
    'RESULT': 'Résultat',
    'LEGEND': 'Légende',
    'TOTALLY_INVALIDATED': 'TOTALEMENT INVALIDÉ',
    'INVALIDATED': 'INVALIDÉ',
    'UNCERTAIN': 'INCERTAIN',
    'VALIDATED': 'VALIDÉ',
    'TOTALLY_VALIDATED': 'TOTALEMENT VALIDÉ'
  },

};
