import { Injectable } from '@angular/core';
import { IndexService } from '../index/index.service';
import { first } from 'rxjs/operators';
import { UmiusCountryInterface } from '@umius/umi-common-component';
import { countryContientObject } from '../../models/static-data/country';

@Injectable({providedIn: 'root'})
export class WorldmapService {

  constructor(private _indexService: IndexService) {
  }

  private _continentCountries: { [continent: string]: Array<UmiusCountryInterface> } = {};

  private static _continentsList = ['africa', 'americaNord', 'americaSud', 'asia', 'europe', 'oceania'];

  private _countries: { [country: string]: string } = countryContientObject; // a mapping of countries -> continent
  private _countriesList: Array<UmiusCountryInterface> = [];

  private _countryObjectList: { [code: string]: string } = {};

  public static areAllContinentChecked(selectedContinents: { [c: string]: boolean }): boolean {
    return WorldmapService._continentsList.every((c) => selectedContinents[c] === true);
  }

  public static setContinents(value: boolean): any {
    return WorldmapService._continentsList.reduce((acc, cont) => {
      acc[cont] = value;
      return acc;
    }, {} as any);
  }

  /**
   * Take the list of countries, and group them by continent.
   * We are doing this to avoid having to recompute this list every time
   * we want to display the countries grouped by continent.
   */
  public allContinentsCountries(): void {
    if (this._countriesList?.length) {
      // Reduce the list of countries to group them by continent.
      // The reduce method takes a callback that will be called for each country.
      // The callback takes two arguments: the accumulator (acc), and the current value (country).
      // The callback returns the new value of the accumulator.
      // The reduce method also takes an initial value for the accumulator.
      // In this case, the initial value is an object with the following properties:
      // - africa: an array to store the countries of Africa
      // - americaNord: an array to store the countries of Northern America
      // - americaSud: an array to store the countries of Southern America
      // - antarctic: an array to store the countries of Antarctica
      // - asia: an array to store the countries of Asia
      // - europe: an array to store the countries of Europe
      // - oceania: an array to store the countries of Oceania
      this._continentCountries = this._countriesList.reduce(
        (acc, country) => {
          // Get the continent name of the current country
          const continent_name = country.continent.toLowerCase();

          // If the continent name is 'americas', we need to check if the subcontinent is 'northern america'
          // or 'southern america'.
          if (continent_name === 'americas') {
            // Get the subcontinent name of the current country
            const subcontinent_name = country.subcontinent.toLowerCase();

            // If the subcontinent is 'northern america', add the country to the 'americaNord' array
            if (subcontinent_name === 'northern america') {
              acc['americaNord'].push(country);
            }
            // If the subcontinent is 'southern america', add the country to the 'americaSud' array
            else {
              acc['americaSud'].push(country);
            }
          }
          // If the continent name is not 'americas', just add the country to the corresponding array
          else {
            acc[continent_name].push(country);
          }

          // Return the new value of the accumulator
          return acc;
        },
        {
          // Initialize the accumulator with an empty array for each continent
          africa: [],
          americaNord: [],
          americaSud: [],
          antarctic: [],
          asia: [],
          europe: [],
          oceania: [],
        }
      );
    }
  }

  /*
   * Return a filtered list of all the countries inside the selected continents.
   */
  public isCountryInSelectedContinents(country: string, continentsToExplore: { [continent: string]: boolean }): boolean {
    return continentsToExplore[this._countries[country]];
  }

  /*
   * This function takes an array of country codes and returns an object with the count of countries
   * in each continent. The object also contains an object with the count of each country within each continent.
   *
   * @param {Array<string>} countries - An array of country codes.
   * @return {Object} An object with the count of countries in each continent and the count of each country within each continent.
   */
  public getCountriesRepartitionByContinent(countries: Array<string>): { [continent: string]: { count: number, countries: { [country: string]: { count: number, names: any } } } } {
    return countries.reduce((acc, countryCode) => {
      // Convert the UK country code to GB
      countryCode = countryCode === 'UK' ? 'GB' : countryCode;
      // Get the continent of the country
      const continent = this._countries[countryCode];
      // Get the country object from the list of countries
      const country = this._countriesList.find(c => c.code === countryCode);
      if (continent) {
        // Initialize the count and countries object for the continent if it doesn't exist
        acc[continent] = acc[continent] || {count: 0, countries: {}};
        // Increment the count of countries in the continent
        acc[continent].count = acc[continent].count + 1;
        // Initialize the count object for the country if it doesn't exist
        acc[continent].countries[country?.name] = acc[continent]?.countries[country?.name] || {
          count: 0,
          names: country?.names
        };
        // Increment the count of the country in the continent
        acc[continent].countries[country?.name].count = acc[continent].countries[country?.name].count + 1;
      }
      // Return the updated accumulator
      return acc;
    }, {} as { [continent: string]: { count: number, countries: { [country: string]: { count: number, names: any } } } });
  }

  /**
   * This function takes an optional array of country codes to include and returns an object
   * with the countries grouped by continent. If countryCodesToInclude is provided, only the
   * countries in that array will be included in the result.
   *
   * @param {Array<string>} [countryCodesToInclude=[]] - An optional array of country codes to include in the result.
   * @return {Object} An object with the countries grouped by continent.
   */
  public getCountriesByContinent(countryCodesToInclude: string[] = []): { [continent: string]: Array<UmiusCountryInterface> } {
    // Reduce the _countriesList array to an object with the countries grouped by continent
    return this._countriesList.reduce((acc, country) => {
      // Get the continent of the country
      const continent = this._countries[country.code];

      // If the continent is defined
      if (continent) {
        // Initialize the array for the continent if it doesn't exist
        acc[continent] = acc[continent] || [];

        // If countryCodesToInclude is empty or the country code is in the array of countries to include
        if (countryCodesToInclude.length === 0 || countryCodesToInclude.indexOf(country.code) !== -1) {
          // Add the country to the array for the continent
          acc[continent].push(country);
        }
      }

      // Return the updated accumulator
      return acc;
    }, {} as { [continent: string]: Array<UmiusCountryInterface> });
  }

  private _setCountriesObject(countryList: Array<UmiusCountryInterface>) {
    this._countryObjectList = {};
    for (const country of countryList) {
      this._countryObjectList[country.code] = country.name;
    }
  }


  public get countryObjectList() {
    return this._countryObjectList;
  }

  public async getCountriesList(): Promise<Array<UmiusCountryInterface>> {
    return new Promise(resolve => {
      if (this._countriesList.length === 0) {
        this._indexService.getWholeSet({type: 'countries'}).pipe(first())
          .subscribe(
            (response) => {
              this._countriesList = response.result;
              this._setCountriesObject(this._countriesList as Array<UmiusCountryInterface>);
              this.allContinentsCountries();
              resolve(this._countriesList);
            });
      } else {
        resolve(this._countriesList);
      }
    });
  }


  public get continentCountries(): { [p: string]: Array<UmiusCountryInterface> } {
    return this._continentCountries;
  }

  public get countriesList(): Array<UmiusCountryInterface> {
    return this._countriesList;
  }

  static get continentsList(): Array<string> {
    return WorldmapService._continentsList;
  }

}
