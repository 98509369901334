export const SHARED_TARGETING = {
  'ERROR':'Erreur',
  'SUCCESS': 'Succès',
  'ERROR_TEXT':'Erreur lors de la sauvegarder du ciblage',
  'SUCCESS_TEXT': 'Le ciblage a été mis à jour',

  'ERRORS' : {
    'INNOVATION': 'Erreur lors de la sauvegarder du projet'
  },

  'COMMON': {
    'COMMENT': 'Informations complémentaires',
    'COMMENT_TEXT': 'Saisissez du texte ici',
    'YES': 'Oui',
    'NO': 'Non',
    'NEXT': 'Suivant',
    'PREVIOUS':'Précédent',
    'SAVE':'Sauvegarder'
  },

  'CONNEXIONS': {
    'COLLEAGUES': {
      'TITLE': 'Collaborateurs',
      'SUB_TITLE': 'Les répondants peuvent faire partie de mes collaborateurs : '
    },
    'CONTACT': {
      'TITLE': 'Contacts externes à mon organisation',
      'SUB_TITLE': 'Les personnes suivantes ne peuvent pas faire partie des répondants :',
      'PLACEHOLDER': 'Ecrire les adresses e-mail des personnes, hors de mon organisation, à ne pas contacter'
    },
  },

  'GEOGRAPHY': {
    'TITLE': 'Distribution géographique',
    'SUB_TITLE': 'Les répondants travaillent dans les pays suivants...',
    'PLACEHOLDER' : 'Commencez à taper pour rechercher...'
  },

  'JOBS':{
    'SENIORITYLEVELS': {
      'TITLE': 'Niveau hiérarchique',
      'SUB_TITLE': 'Au sein de leur organisation, les répondants sont...',
      'MANAGER_CHECKBOX': 'Manager',
      'NON_MANAGER_CHECKBOX': 'Opérationnel',
    },
    'JOBSTYPOLOGIES': {
      'TITLE': 'Typologie de métier',
      'SUB_TITLE': 'Les répondants travaillent dans les catégories de métier suivantes...',
      'PLACEHOLDER' : 'Commencez à taper pour rechercher...',
      'OTHER_TEXT':'Veuillez préciser',
      'OTHER':'Autre : ',
    },
    'JOBTITLE': {
      'TITLE': 'Intitulés de poste',
      'SUB_TITLE': 'Les répondants occupent des postes de ...',
      'AUTO_SUGGESTION': 'Commencez à taper pour rechercher...',
    },
    'LEXICALSCOPE': {
      'TITLE': 'Champ lexical métier',
      'SUB_TITLE': 'Dans leur métier, les répondants emploient régulièrement les termes suivants ...'
    },
  },

  'ORGANISATION':{
      'SECTOR': 'Secteur',
      'TEXT': "Commencez à taper pour rechercher...",
      'SECTOR_TEXT': 'On peut classer les organisations des répondants dans les secteurs suivants...',
      'SIZE': 'Taille',
      'SIZE_TEXT': 'Les répondants travaillent dans des organisations de taille suivante...',
      'COMPANY': 'Exemples d\'organisations',
      'COMPANY_TEXT': 'Idéalement, les répondants pourraient travailler dans les organisations suivantes...',
      'STATUS': 'Statut',
      'STATUS_TEXT': 'Les répondants travaillent dans des organisations de statut...',
      'OTHER_TEXT':'Veuillez préciser',
      'OTHER':'Autre : ',
  },

  'PROFILES':{
    'DEFAULTLINKEDIN': {
      'TITLE': 'Profils types',
      'SUB_TITLE': 'Les répondants pourraient être les personnes suivantes ...',
      'ADD_NEW_PROFILE': 'Ajouter un autre profil',
      'ADD_NEW_PROFILE_PLACEHOLDER': 'Entrez un lien LinkedIn valide',
      'NOT_VALID_URL': 'Ce lien LinkedIn n\'est pas valide',
      'EMPTY_URL': 'Le lien LinkedIn ne peut être vide'
    }
  },

  'SUMMARY':{
    'TITLE': 'Panel des répondants souhaité',
    'SUB_TITLE': '',
    'GEOGRAPHY': 'Géographie',
    'JOBS': 'Jobs',
    'PROFILES': 'Profils types',
    'ORGANIZATION': 'Organisations',
    'CONNEXIONS': 'Connexions',
    'COLLEAGUES' : {
      'TRUE':'Les répondants peuvent faire partie de mes collaborateurs',
      'FALSE':'Les répondants ne peuvent pas faire partie de mes collaborateurs',
    },
    'SEE_MORE' : 'Voir tout',
    'OTHER':'Autre',
  },

  'CSM':{
    'TITLE': 'Communication CSM/Prod',
    'SAVE': 'Sauvegarder'
  }


};
