export const MARKET_REPORT = {

  'RESULT': {
    'SOLUTION': 'Ma solution',
    'NEEDS_EXPRESSED': 'Besoins exprimés',
    'VALIDATING_INTEREST': {
      'TITLE': 'Niveau d’intérêt marché',
      'BAR': {
        'LABEL_A': 'Intérêt totalement invalidé',
        'LABEL_B': 'Intérêt invalidé',
        'LABEL_C': 'Intérêt incertain',
        'LABEL_D': 'Intérêt validé',
        'LABEL_E': 'Intérêt totalement validé',
      }
    },
    'VALIDATING_MARKET': {
      'TITLE': 'Niveau de besoins',
      'BAR': {
        'LABEL_A': 'Besoins totalement invalidés',
        'LABEL_B': 'Besoins invalidés',
        'LABEL_C': 'Besoins incertains',
        'LABEL_D': 'Besoins validés',
        'LABEL_E': 'Besoins totalement validés',
      }
    },
    'DETECTING_MARKET': {
      'TITLE': 'Potentiel d\'innovation sur ce sujet / marché',
      'BAR': {
        'LABEL_A': 'Potentiel totalement invalidé ',
        'LABEL_B': 'Potentiel invalidé',
        'LABEL_C': 'Potentiel incertain',
        'LABEL_D': 'Potentiel validé',
        'LABEL_E': 'Potentiel totalement validé',
      }
    }
  },

  'TITLE': 'Potentiel marché de votre projet',

  'CUSTOM_FILTERS': 'Filtres personnalisés',

  'RESET_FILTERS': 'Réinitialiser les filtres',

  'FILTER_EVERYTHING': 'Tout filtrer',

  'MESSAGE': {
    'SUBMITTED': 'Votre innovation est en cours de validation. Nous revenons vers vous rapidement !',
    'EDITING': 'Une fois cette page disponible, vous retrouverez ici les conclusions et les statistiques de votre Test Marché.',
    'REVIEWING': 'Nous vous avons suggéré des modifications du projet par email. Une fois fait, soumettez-le de nouveau.',
    'EVALUATING': 'Nous collectons les feedbacks des professionnels du marché. Revenez bientôt !'
  },

  'MESSAGE_ADMIN': {
    'NOT_SUBMITTED': 'Le client n\'a pas encore soumis le projet. Vous pourrez éditer la synthèse après qu\'il ait soumis son projet.',
    'DONE': 'Ce projet est marqué comme terminé et une synthèse est disponible chez le client.',
    'PREVIEW_MODE': 'La synthèse partielle de ce projet est disponible chez le client.',
  },

  'BUTTON': {
    'DOWNLOAD': 'Télécharger',
    'STATUS_TO_DONE': 'Finaliser la synthèse',
    'STATUS_TO_EVALUATING': 'Modifier la synthèse'
  },

  'HIDDEN':{
    'MESSAGE': 'La question était masquée pour',
    'RESPONDENTS': 'répondant(s)',
  },

  'INSIGHT': 'Réponse',

  'CONCLUSION': 'Conclusion finale',

  'COMMENT_HEADING': 'Espace pour vos commentaires',

  'KEY_LEARNING': 'Connaissances clés',

  'MENU_BAR': {
    'BUTTON_END': 'Clôturer le projet',

    'FILTER': 'Filtres',
    'VIEWS': 'Mes vues'
  },

  'ANONYMOUS': 'Identité confidentielle',

  'NOT_ALLOWED': 'Vous n\'êtes plus autorisé à effectuer cette action.',

  'MESSAGE_SYNTHESIS_VISIBLE': 'La synthèse partielle est visible pour le client.',

  'MESSAGE_SYNTHESIS_NOT_VISIBLE': 'La synthèse n\'est pas visible pour le client.',

  'MESSAGE_SYNTHESIS': 'Ce projet a été terminé avec succès. La synthèse est disponible côté client.',

  'PRINT_DATE': 'Test marché -',

  'MODAL': {
    'END_INNOVATION_TITLE': 'Clôturer le projet',
    'END_INNOVATION_CONTENT': 'Voulez-vous vraiment mettre fin à ce projet ? N\'oubliez pas qu\'après cela, vous ne pourrez plus apporter de modifications.',
    'EXPORT_INNOVATION_TITLE': 'Exporter',
    'EXPORT_INNOVATION_NOTE': 'Nous vous rappelons que selon le règlement nᵒ 2016/679, dit règlement général sur la protection des données, les données à caractère ' +
      'personnel contenues dans la synthèse complète ne doivent pas être utilisées en dehors de votre entreprise.',
    'EXPORT_INNOVATION_CONTENT' : {
      'A': 'Toutes les réponses',
      'B': 'Executive Report',
      'D': 'Partager lien',
      'AROUND': 'Environ',
      'PAGES': 'pages'
    },
    'BUTTON_CONFIRM': 'Confirmer',
  },

  'RESTITUTION_LANG': 'Langue de la restitution',
  'EDIT_ANSWER': 'Modifier la réponse',
  'REASSIGN_ANSWER': 'Réattribuer la réponse',
  'ORIGIN_LANG': 'Langue d\'origine',

  'TRANSLATE_DEEPL_FR': 'Traduire en français',
  'TRANSLATE_DEEPL_EN': 'Traduire en anglais',
  'TRANSLATE_DEEPL_ERROR': 'Impossible de traduire cette langue.',
  'TRANSLATE_DEEPL_SAVE': 'Sauvegarder la traduction manuelle.',
  'TRANSLATE_DEEPL_CLICK_TO': 'Cliquez pour traduire manuellement et coller ci-dessous.',

  'ORIGINAL_TEXT': 'Texte original',

  'NUMBER_ANSWERS': 'répondants ont répondu à la question',
  'POSITIVE_ANSWER': 'Réponse positive',

  'GEOGRAPHICAL_DISTRIBUTION': {
    'TITLE': 'Distribution géographique',
  },

  'EXECUTIVE': {
    'HEADING': 'Executive Report',
    'TITLE': 'Objectif',
    'CHOOSE_TEMPLATE': 'Choisissez un template',
    'VALID_BUTTON': 'Valider',
    'DELETE_BUTTON': 'Supprimer l\'executive report',
    'CONCLUSION': 'Conclusion résumée',
    'ABSTRACT': 'Abstract',
    'NO_TAGS': 'Ajouter des tags à cette rubrique'
  },

  'EXECUTIVE_TEMPLATE': {
    'PROFESSIONAL_HEADING': 'Panel de professionnels',
    'ANSWERS': 'réponses',
    'REMAINING': {
      'AND': 'et',
      'OTHERS': 'autres',
      'MORE': 'suite...'
    },
    'CONCLUSION_HEADING': 'Conclusion',
    'UMI_CONTACT': {
      'A': 'Souhaitez-vous lancer un test marché ?',
      'B': 'Contactez-nous ',
      'C': 'au'
    },
  },

  'EXECUTIVE_PLACEHOLDER': {
    'CONCLUSION': 'Ecrivez votre conclusion en max 1200 mots.',
  },

  'TARGET_COUNTRIES': 'Pays ciblés',

  // 'TITLE': 'Exploration du potentiel marché',
  'COLLAPSE': 'Tout replier',

  'EXPAND': 'Tout déplier',

  'FILTERS': 'Filtres',

  'SYNTHESIS_FRAME': 'des professionnels',

  'PIE_CHART': 'des réponses sont favorables',

  'COMMENTS': 'commentaire(s)',

  'ANSWER': 'réponse',

  'PROS': 'professionnel(s)',

  'ANSWERS': 'réponse(s)',

  'SEE_ANSWERS': 'Voir la réponse',

  'BTN_EDITION_MODE': 'Passer en mode édition',

  'BTN_VIEW_MODE': 'Passer en mode visualisation',

  'BTN_ANSWERS_OPEN': 'Voir les réponses',

  'BTN_ANSWERS_CLOSE': 'Masquer les réponses',

  'BTN_PROFESSIONAL_OPEN': 'Voir les répondants',

  'BTN_PROFESSIONAL_CLOSE': 'Masquer les répondants',

  'TOOLTIP_HIDE_ANSWER': 'Masquer la réponse',

  'BTN_COMMENTS_OPEN': 'Commentaires',

  'TOGGLE_COMMENTS_TRANSLATE': 'Commentaire traduit',

  'TOGGLE_COMMENTS_ORIGINAL': 'Commentaire original',

  'TOGGLE_ANSWERS_TRANSLATE': 'Réponse traduite',

  'TOGGLE_ANSWERS_ORIGINAL': 'Réponse originale',

  'TOGGLE_QUESTIONS_TRANSLATE': 'Questions traduites',

  'TOGGLE_QUESTIONS_ORIGINAL': 'Questions originales',

  'BTN_COMMENTS_CLOSE': 'Masquer les commentaires ({{length}})',

  'BTN_RECOMPUTE': 'Recalculer la synthèse',

  'VOTES': 'votes',

  'GRADE': 'Note',

  'NO_GRADE': 'Pas de note',

  'POPUP': {
    'PROFIL': 'Profil',
    'RECEIVED': 'Reçu le',
    'JOB': 'Profession',
    'COUNTRY': 'Pays',
    'BACK': 'Retour'
  },

  'PROFESSIONAL': {
    'SIDEBAR': 'Professionnels',
    'TITLE': 'Les professionnels',
    'NUMBER': 'réponses de professionnels',
    'QUESTION': 'Origine des réponses'
  },

  'JOBS': {
    'SIDEBAR': 'Job',
    'TITLE': 'Job',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'JOBTYPOLOGY': {
    'SIDEBAR': 'Typologie métier',
    'TITLE': 'Typologie métier',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'SENIORITYLEVEL': {
    'SIDEBAR': 'Niveau hiérarchique',
    'TITLE': 'Niveau hiérarchique',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'COMPANY': {
    'SIDEBAR': 'Organisation',
    'TITLE': 'Organisation',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'ENTERPRISESIZE': {
    'SIDEBAR': 'Taille de l’organisation',
    'TITLE': 'Taille de l’organisation',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'VALUECHAIN': {
    'SIDEBAR': 'Chaîne de valeur',
    'TITLE': 'Chaîne de valeur',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'ENTERPRISETYPE': {
    'SIDEBAR': 'Statut',
    'TITLE': 'Statut',
    'NUMBER': 'Answers from professionals',
    'QUESTION': 'Origin of responses'
  },

  'BUTTON_EXPORT': 'Exporter',

  'BUTTON_SAVE_VIEW': 'Enregistrer la vue',

  'VIEWS': 'Mes vues',

  'EDIT_VIEW': 'Modifier la vue',

  'CHANGE_NAME': 'Changer le nom',

  'TOOLTIP_DELETE_VIEW': 'Effacer la vue',

  'TOOLTIP_LOAD_VIEW': 'Charger la vue',

  'TOOLTIP_EDIT_VIEW': 'Editer la vue',

  'TOOLTIP_UNHIDE_PROFESSIONAL': 'Afficher le professionnel',

  'TOOLTIP_HIDE_PROFESSIONAL': 'Masquer le professionnel',

  'VIEW_PLACEHOLDER': 'Nom de votre vue',

  'SEE_MORE': 'Voir plus',

  'SEE_LESS': 'Voir moins',

  'NO_DATA': 'Aucune donnée',

  'PANEL': 'Panel des répondants',

  'VISIBLE_TAG': 'visible sur \nla carte du pro',

  'NOT_VISIBLE_TAG': 'masqué sur \nla carte du pro',

  'VISIBLE_FILTER': 'visible \ncôté client',

  'NOT_VISIBLE_FILTER': 'masquée \ncôté client',

  'SIDEBAR': {
    'FILTERS': 'Filtres',
    'MAKE_VISIBLE': 'Rendre le projet visible pour le client',
    'MAKE_INVISIBLE': 'Rendre le projet invisible pour le client',
    'GENERATE_REPORT': 'Genérer l\'executive report',
    'RESET_REPORT': 'Réinitialiser l\'executive report',
    'END_PROJECT': 'Terminer le projet',
    'CONFIRMATION': 'Confirmation',
    'REALLY_MAKE_VISIBLE': 'Voulez vous vraiment rendre le projet visible pour le client ?',
    'REALLY_MAKE_INVISIBLE': 'Voulez vous vraiment rendre le projet invisible pour le client ?',
    'REALLY_RESET_REPORT': 'Voulez vous vraiment réinitialiser l\'executive report ?',
    'REALLY_END_PROJECT': 'Voulez vous vraiment terminer le projet ? Vous ne serez plus en mesure d\'apporter des changements au projet.',
  },

  'LIKERT-SCALE_SECTION': {
    'TOTALLY_INVALIDATED': 'TOTALEMENT INVALIDÉ',
    'INVALIDATED': 'INVALIDÉ',
    'UNCERTAIN': 'INCERTAIN',
    'VALIDATED': 'VALIDÉ',
    'TOTALLY_VALIDATED': 'TOTALEMENT VALIDÉ',
  }

};
