export const HEADER = {
  'EXAMPLE': 'Exemple de livrable',
  'MENU': 'Menu',
  'PROFILE': 'Profil',
  'LOG_OUT': 'Se déconnecter',
  'MY_ACCOUNT': 'Mon compte',
  'MY_PROJECTS': 'Mes projets',
  'SHARED_REPORTS': 'Rapports partagés',
  'DISCOVER': 'Showroom des innovations',
  'CONTACT_US': 'Contactez-nous',
  'CLIENT_SIDE': 'Côté client',
  'ADMIN_SIDE': 'Côté Admin'
};
