export const SHARE = {

  'MESSAGE': 'Dans ce rapport, les données personnelles des professionnels ont été cachées.',

  'SYNTHESIS_LIST': {
    'BOT_TEXT': 'Vous trouverez ici les rapports marché que l\'on aura partagés avec vous.',
    'SHARED_TEXT': {
      'A': 'Partagé par',
      'B': 'le'
    }
  },

  'NOT_FOUND': 'Nous rencontrons des problèmes lors de la récupération des données. Veuillez essayer de recharger la page à nouveau !'

};
