export const SHARED_TARGETING = {
  'ERROR':'Error',
  'SUCCESS': 'Success',
  'ERROR_TEXT':'Error while updating targeting',
  'SUCCESS_TEXT': 'The targeting has been updated',

  'ERRORS' : {
    'INNOVATION': 'Error while updating innovation'
  },

  'COMMON': {
    'COMMENT': 'Additional information',
    'COMMENT_TEXT': 'Enter text here',
    'YES': 'Yes',
    'NO': 'No',
    'NEXT': 'Next',
    'PREVIOUS':'Previous',
    'SAVE':'Save'
  },

  'CONNEXIONS': {
    'COLLEAGUES': {
      'TITLE': 'Colleagues',
      'SUB_TITLE': 'My colleagues can be part of the respondent panel:'
    },
    'CONTACT': {
      'TITLE': 'Contacts outside my organization',
      'SUB_TITLE': 'The following people cannot be included as respondents:',
      'PLACEHOLDER': 'Enter the email addresses of the people outside my organization, not to be contacted'
    },
  },

  'GEOGRAPHY': {
    'TITLE': 'Geographic distribution',
    'SUB_TITLE': 'The respondents are based in the following countries...',
    'PLACEHOLDER' : 'Start typing to search...'
  },

  'JOBS':{
    'SENIORITYLEVELS': {
      'TITLE': 'Seniority level',
      'SUB_TITLE': 'Within their organization, respondents are...',
      'MANAGER_CHECKBOX': 'Manager',
      'NON_MANAGER_CHECKBOX': 'Non-Manager',
    },
    'JOBSTYPOLOGIES': {
      'TITLE': 'Job typology',
      'SUB_TITLE': 'The respondents work in the following professions...',
      'PLACEHOLDER' : 'Start typing to search...',
      'OTHER_TEXT':'Please specify',
      'OTHER':'Other : ',
    },
    'JOBTITLE': {
      'TITLE': 'Job titles',
      'SUB_TITLE': 'The respondents have the following positions....',
      'AUTO_SUGGESTION': 'Start typing to search...',
    },
    'LEXICALSCOPE': {
      'TITLE': 'Profession-related lexical field\n',
      'SUB_TITLE': 'In their profession, the respondents regularly use the following terms...'
    },
  },

  'ORGANISATION':{
    'SECTOR': 'Sector',
    'TEXT': "Start typing to search...",
    'SECTOR_TEXT': 'The respondents\' organizations can be categorized into the following sectors...',
    'SIZE': 'Size',
    'SIZE_TEXT': 'The respondents work in organizations that are the following sizes...',
    'COMPANY': 'Examples of organizations',
    'COMPANY_TEXT': 'Ideally, respondents could work in the following organizations...',
    'STATUS': 'Status',
    'STATUS_TEXT': 'The respondents work in the following types of organizations...',
    'OTHER_TEXT':'Please specify',
    'OTHER':'Other : ',
  },

  'PROFILES':{
    'DEFAULTLINKEDIN': {
      'TITLE': 'Profile types',
      'SUB_TITLE': 'The respondents could be like the following people...',
      'ADD_NEW_PROFILE': 'Add another profile',
      'ADD_NEW_PROFILE_PLACEHOLDER': 'Enter a valid LinkedIn link',
      'NOT_VALID_URL': 'This Linkedin link is not valid',
      'EMPTY_URL': 'The LinkedIn link cannot be empty'
    }
  },

  'SUMMARY':{
    'TITLE': 'Desired panel of respondents',
    'SUB_TITLE': '',
    'GEOGRAPHY': 'Geography',
    'JOBS': 'Jobs',
    'PROFILES': 'Profile types',
    'ORGANIZATION': 'Organizations',
    'CONNEXIONS': 'Connections',
    'COLLEAGUES' : {
      'TRUE':'My colleagues can be part of the respondent panel',
      'FALSE':'My colleagues can’t be part of the respondent panel',
    },
    'SEE_MORE' : 'See all',
    'OTHER':'Other',
  },

  'CSM':{
    'TITLE': 'CSM/Prod communication',
    'SAVE': 'Save'
  }


};
