export const FORGET_PASSWORD = {
  'TITLE': 'Request Password Reset',

  'HEADING_A': 'Forgot your password?',
  'HEADING_B': 'Enter your email address below and we\'ll get you back on track.',

  'BUTTON_TEXT': 'Request reset link',

  'SIGN_IN_TEXT': 'Back to Sign in',

  'SUCCESS_TEXT': {
    'A': 'If there\'s a',
    'B': 'account linked to this email address,',
    'C': 'we\'ll send over instructions to reset your password.'
  }
};
