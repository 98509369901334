export const SIDEBAR_USER_ANSWER = {
  'RECEIVED': {
    'A': 'Reçu :',
    'B': 'à',
    'C': 'Temps passé :'
  },

  'VISIBILITY': 'Visible on client side',

  'STATUS': {
    'REJECTED': 'Rejeté',
    'REJECTED_GMAIL': 'Rejeté par Mail',
    'VALIDATED': 'Validé',
    'SUBMITTED': 'A regarder',
    'DISQUALIFIED': 'Disqualifié',
    'EMAIL': 'Envoyer email',
    'DRAFT': 'Draft',
    'VALIDATED_NO_MAIL': 'Validé aucun mail',
    'VALIDATED_UMIBOT': 'Validé par le bot',
    'REJECTED_UMIBOT': 'Rejeté par le bot',
  },

  'ANSWER': {
    'TARGETING': {
      'OUTSIDE': 'Le pays de cette réponse est en dehors du ciblage du projet',
      'FROM_EXCLUDED_COUNTRY': 'Le pays de cette réponse est dans la liste des pays à exclure',
      'EMPTY_COUNTRY': 'Cette réponse n\'a pas de pays !'
    }
  },

  'CONFIRMATION_CUSTOM': { //change to french if CSM ask...
    'TITLE': 'New data label',
    'CONTENT': 'This new data label \"{{value}}\" will be displayed on every professional cards in this innovation.'
  },

  'CONFIRMATION': { // change to french if CSM ask...
    'TITLE': 'New data label \"{{value}}\" created',
    'CONTENT': 'New data label \"{{value}}\" will apply to all answers on this project.'
  },

  'CONFIRMATION_PREDEFINED_CUSTOM': { // change to french if CSM ask...
    'TITLE': ' New custom data for predefined data label',
    'CONTENT': 'Do you really want to create the new custom data "\"{{type}}\" : \"{{value}}\""?',
  },

  'REPLACE': {
    'TITLE': 'Changes in the database',
    'CONTENT': 'Do you want to replace existing data in the database: "{{label}} = {{oldValue}}", with "{{label}} = {{newValue}}" ?',
    'REPLACE': 'Replace existing data in the database',
    'KEEP': 'Highlight professional only in this innovation'
  },

  'CONFIRMATION_DELETE' : {
    'TITLE': 'Delete this data label \"{{value}}\"',
    'CONTENT': '\"{{value}}\" will be removed from every professional cards within this innovation.'
  },

  'TOOLTIP': {
    'EDIT': 'Modifier : \"{{value}}\"',
    'DELETE': 'Supprimer : \"{{value}}\"',
  },

  'MODAL':{
    'CANCEL': 'Annuler',
    'CONFIRM': 'Confirmer',
  },

  'VALIDATED_ANSWER': 'Réponse validée',
  'UNVALIDATED_ANSWER': 'Réponse non validée',
  'JOB': 'Poste',
  'COMPANY': 'Entreprise',
  'BLACKLISTED_COMPANY': 'La réponse provient peut-être d\'une entreprise blacklistée !',
  'EDIT': 'Editer',
  'TIME_SPENT': 'Temps passé',
  'REJECT_ANSWER': 'Rejeter',
  'UNCOMPLETE_ANSWER': 'À compléter',
  'VALID_ANSWER': 'Valider',
  'VALID_ANSWER_NO_MAIL': 'Valider sans mail merci',
  'INVALID_ANSWER': 'Invalider',
  'EDIT_ANSWER': 'Editer la réponse',
  'REASSIGN_ANSWER': 'Réassigner la réponse',
  'ADD_NEW_EMAIL': 'Ajouter un deuxième email'
};
