export const PROJECT_DOCUMENTS = {

  'MESSAGE_BOT': 'Une fois cette page disponible, vous retrouverez ici les documents de votre Test Marché.',

  'NAME': {
    'REPORT': 'Executive Report',
    'SHARE': 'Lien partageable',
    'XLSX': 'Réponses au format Excel',
    'PDF': 'Réponses au format PDF',
    'VIDEO': 'Vidéo Synthèse',
  },

  'DESCRIPTION': {
    'REPORT': 'Partagez les résultats synthétiques de votre Test Marché',
    'SHARE': 'Partagez les résultats complets anonymisés',
    'XLSX': 'Toutes les réponses de votre test marché au format Excel',
    'PDF': 'Toutes les réponses de votre test marché au format PDF',
    'VIDEO': 'Partagez les résultats de votre Test Marché, au format vidéo',
  },

  'IN_PROGRESS': 'en cours de création...',

  'CONSENT': 'Nous vous rappelons que selon le règlement nᵒ 2016/679, dit règlement général sur la protection des données, ' +
    'les données à caractère personnel contenues dans la synthèse complète ne doivent pas être utilisées en dehors de votre entreprise.',

  'POPOVER': {
    'NOT_OWNER': 'Pour télécharger/partager le lien, vous devez être le propriétaire du projet.',
    'NOT_CONSENT': 'Pour télécharger/partager le lien, veuillez accepter le consentement ci-dessus.',
    'NOT_BOTH': 'Pour télécharger/partager le lien, vous devez être le propriétaire du projet et accepter le consentement ci-dessus.'
  }

};
