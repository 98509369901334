export const ADMIN_STORYBOARD = {

  'CHANGE_LANG': {
    'A': 'Want to',
    'B': 'change language',
    'C': '? It will reset everything...'
  },

  'AUTOFILL': {
    'A': 'Are you lucky today? Try',
    'B': 'autofill',
    'C': 'and save time!'
  },

  'SELECT_LANG': {
    'A': 'To start editing the Storyboard,',
    'B': 'select the language',
    'C': 'first...'
  },

  'MODAL':{
    'CHANGE_TITLE': 'Changing Language',
    'SELECT_TITLE': 'Selecting Language',
    'CURRENT_LANG': 'Current language: ',
    'ATTENTION': 'Attention:',
    'ATTENTION_MSG': 'It will reset everything and you won\'t be able to go back.'
  },

  'LAST_GENERATE': 'Last generate',

  'AT': 'at',

  'TOAST': {
    'URL_COPIED': 'The URL has been copied successfully.'
  },

  'BACK_TO': 'Back to project',

  'NO_QUESTIONS_MSG': 'There are no questions to fill the executive report sections.',

  'VIDEO_TYPE': {
    'TEST': 'Test',
    'FINAL': 'Final'
  },

  'MISSING_FIELDS': {
    'INTRO': 'We miss some information to generate the file',
    'LANG': 'You need to select a language',
    'ANSWERS': 'Some validated answers are needed',
    'CONCLUSION': 'We need the conclusion',
    'OBJECTIVE': 'We need the objective',
    'SUMMARY': 'We need the summary',
    'SALE': 'A sale must be associated to the project',
    'OPERATOR': 'An operator must be associated to the project',
    'SECTIONS': 'We need some sections',
    'NAME': 'We need the client\'s name',
    'EMAIL': 'We need the client\'s email',
    'ABSTRACT': 'We need the professionals abstract',
    'LIST': 'We need at least four complete profiles',
    'COUNTRY': 'Le pays est manquant, corriger dans la synthèse',
    'MEDIA': 'We need a principal media',
    'TITLE': 'We need a title... no shit ?!',
  }

};
