<div *ngIf="spinnerState" id="spinner-loader" [ngClass]="isFullHeight ? 'page__full-height': 'page__height'">
  <div *ngIf="isUmiDomain" style="margin-bottom: 20px;">
    <img
      [src]="logoSrc"
      alt="UMI"
      class="img-responsive"
      height="72.75"
      width="139">
  </div>
  <div class="loader-wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
  </div>
</div>
