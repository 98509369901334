export const TABLE_EN = {

    STATUS: {
        TO_MODIFY: 'To modify',
        MODIFIED: 'Modified',
        VALIDATED: 'Validated',
        REJECTEFD: 'Rejected',
        SUBMITTED: 'To look at',
        DISQUALIFIED: 'Disqualified',
        REJECTED_GMAIL: 'Rejected by Mail',
        REJECTED_UMIBOT: 'Auto rejected',
        VALIDATED_UMIBOT: 'Auto validated'
    },

    HEADING: {
        EMAILS: 'Emails',
        STATUS: 'Status',
        SIGNATURES: 'Signatures',
        NAME: 'Name',
        COUNTRY: 'Country',
        EMAIL_ADDRESS: 'Email Address',
        JOB_TITLE: 'Job Title',
        COMPANY: 'Company',
        CAMPAIGNS: 'Campaigns',
        CONTACT: 'Contact',
        DOMAIN: 'Domain',
        CREATED: 'Created',
        TITLE: 'Title',
        OWNER: 'Owner',
        UPDATED: 'Updated',
        SECTORS: 'Sectors',
        INDUSTRY: 'Industry',
        FEEDBACK: 'Feedback',
        SAVED: 'Saved',
        SCORE: 'Validation Score',
        LANGUAGE: 'Language',
        AUTHOR: 'Author',
        CONTENT: 'Content',
        SECTIONS: 'Sections',
        PROJECTS: 'Projects',
        AMBASSADOR: 'Ambassador',
        TTR:  'Release time',
        OBJECTIVE: 'Objective',
        TYPE: 'TYPE'
    },

    TITLE: {
        PROFESSIONALS: 'professionals',
        USERS: 'users',
        PROJECTS: 'projects',
        AMBASSADORS: 'ambassadors',
        REQUESTS: 'requests',
        RESULTS: 'results',
        ANSWERS: 'answers'
    },

    MESSAGES: {
        FETCHING: 'Fetching results...',
        NO_RESULT: 'No results to show.',
        NO_RESULT_SEARCHING: 'No results to show. Try adjusting your search to find what you\'re looking for.'
    },

    SELECTED_ROWS: 'selected(s)',

    SELECT_ALL: 'Select all',

    CLEAR_ALL: 'Clear all',

    CHOOSE: 'Choose',

    AT: 'at',

    PAGINATION: {
        PER_PAGE: 'per page',
        START: 'Start',
        END: 'End',
        ALL: 'All',
        PREVIOUS: 'Previous',
        NEXT: 'Next',
        PAR_PAGE: 'per page'
    }

};
