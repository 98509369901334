export const SHARED_MAIL_EDITOR = {

  'INSERT_FIELD': 'Insert a field',

  'TEST_EMAILS': 'Test the emails',

  'TEST_WITH_CONTACT': 'Test with a contact',

  'SUBJECT': 'Subject',

  'SUBJECT_PLACEHOLDER': 'Enter the subject of the mail',

};
