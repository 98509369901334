/**
 * Created by bastien on 31/01/2018.
 */
export const SEARCH = {
  "COUNTRY_LIST": "Liste de pays",
  "REQUESTS": "requêtes",
  "NORESULT": "Pas de résultat :-(",
  "PRO": "Professionnel",
  "SELECTED": "sélectionné",
  "ADDTOCAMPAIGN": "Ajouter à une campagne",
  "ADDTOCAMPAIGN2": "Ajouter à la campagne",
  "SEARCHMAILS": "Chercher les mails",
  "DELETE": "Supprimer",
  "AUTOMATED": "Automatique",
  "EXPORT": "Exporter",
  "BACK": "Retour",
  "ADD": "Ajouter",
  "SAVE": "Sauvegarder",
  "CONFIRM": "Confirmer",
  "AT": "à",
  "NEW": "Nouvelle recherche",
  "ADDTOQUEUE": "Mettre en file d'attente",
  "STOPREQUEST": "Stopper la requête",
  "SETTINGS": "Paramètres",
  "APPLY": "Appliquer",
  "IGNORE": "Ignorer",
  "STAR_PROFILES": "Profils Stars: ",
  "COUNTRY": "Pays",
  "LANGUAGE": "Langue",
  "NUMBER": "Rechercher :",
  "SMART": "Smart Search",
  "REGIONS": "Régions",
  "ENGINE": "Engine :",
  "REMAINING_REQUESTS": "requêtes restantes",
  "ROBOT": {
    "HELLO": "Bonjour, quelle recherche pour aujourd'hui ?",
    "SPARSE": "Attention : ne pas faire davantage de requêtes pour ce domaine !",
    "NOT_OK": "Attention : ces requêtes ne renvoient pas beaucoup de résultats et/ou beaucoup de doublons !",
    "OK": "Ces requêtes m'ont l'air bien !",
    "ERROR": "Il n'y a pas assez de requêtes pour que CAT fonctionne correctement.",
    "OVEREXPLOITED": "Il y a trop de requêtes similaires, supprimez-en !",
    "SEARCH_USE": "La recherche utilisera",
    "APPROX": "environ",
    "MORE_INFO": "Plus d'info",
    "SUGGESTED": "Voici quelques mots-clés pouvant être utiles: "
  },
  "PERSON": {
    "FULLNAME": "Nom",
    "FIRSTNAME": "Prénom",
    "LASTNAME": "Nom",
    "JOBTITLE": "Poste",
    "EMAIL": "Email",
    "CONFIDENCE": "Conf"
  },
  "COMPANY": {
    "COMPANY": "Entreprise",
    "NAME": "Nom",
    "DOMAIN": "Site internet",
    "INDUSTRY": "Domaine",
    "EMPLOYEES": "Nombre d'employés",
    "PROFILE": "Profil",
    "PATTERNS": "Patterns"
  },
  "INPUT": "Recherche 'Google-like', une requête par ligne",
  "TOOLTIPS": {
    "EDITCOMPANY": "Editer l'entreprise",
    "EDITPRO": "Editer",
    "DELETECOMPANY": "Supprimer l'entreprise",
    "DELETEPRO": "Supprimer"
  },
  "HISTORY": {
    "NORESULT": "Vous n'avez pas encore fait de recherches :-(",
    "SEARCHES": "Recherches",
    "KEYWORDS": "Mots clés",
    "SHOW": "Afficher l'historique",
    "HIDE": "Cacher l'historique",
    "TYPE": "Type",
    "NBR": "Nb",
    "REQUEST": "Requête",
    "METADATA": "Métadonnées",
    "DATE": "Date",
    "TARGETTING": "Ciblage",
    "TIME": "Durée",
    "STATUS": "Statut",
    "COST": "Coût",
    "FLAG": "Statut mails",
    "ALL": "Tous",
    "RESET": "Reset",
    "DONE": "OK",
    "PROCESSING": "En cours",
    "QUEUED": "En attente",
    "CANCELED": "Annulée",
    "CANCEL": "Annuler les requêtes",
    "STOP": "Arrêter les requêtes",
    "BACK_QUEUE": "Remettre en attente",
    "SEARCH_MAILS": "Rechercher les emails",
    "PERPAGE": "par page",
    "PRO": "Professionnel",
    "LAUNCHED_BY": "Lancée par",
    "PERSONBYCOUNTRIES": "Pros par pays",
    "PERSONBYREGIONS": "Pros par régions",
    "EMAILS_FOUND": "Emails trouvés",
    "EMAILS_QUEUED": "Emails en attente",
    "EMAILS_SEARCHING": "Emails en cours",
    "PROS_ADDED": "Pros ajoutés",
    "NBSHIELD": "Shielded",
    "NBAMB": "Ambassadeurs"
  },
  "CAMPAIGN": {
    "TITLE": "Ajoutez à une campagne",
    "TITLE2": "professionnel(s)",
    "ACTIONS": "Actions pour",
    "LANGUAGE": "Langue des professionnels",
    "CHOOSELANGUAGE": "Veuillez choisir une langue avant d'ajouter les professionnels à la campagne.",
    "CREATED": "Créée le",
    "UPDATED": "Actualisée le",
    "ANSWERS": "réponse(s)",
    "NOCAMPAIGN": "Aucune campagne n'est disponible pour ajouter les professionnels.",
    "ADDANDSEE": "Ajouter et voir la campagne"
  },
  "EDITPRO": {
    "TITLE": "Edition du professionnel"
  },
  "EDITCOMPANY": {
    "TITLE": "Edition de l'entreprise"
  },
  "DELETEPRO": {
    "TITLE": "Suppression",
    "WARNING": "Respirez... Prenez un café... <br>Etes-vous sûr de vouloir supprimer ce(s) professionnel(s) des résultats de la recherche ?"
  },
  'POPOVER': {
    'REQUEST': 'Pas de majuscules ni de "s" pour multiplier artificiellement le nombre de requête.',
    'PRACTICE' : 'Bonnes pratiques de requêtage pour que CAT fonctionne.',
    'KEYWORDS' : 'Les mots-clés avec des chiffres ne seront pas pris en compte.',
    'MAX': '100 requêtes max par jour.',
    'SPELLING': 'Les nouveaux mots-clés peuvent être étrange dû à des fautes d\'orthographe ou de frappe dans les descriptions des professionels.'
  },

};
