export const DISCOVERDESCRIPTION = {
  'AVAILABLE': 'Projet déjà disponible',

  'PATENT': 'Protégé par un brevet',

  'SHARE' : 'Vous aimez le projet? Partagez-le, récoltez des avis et aidez-le à se concrétiser!',

  'OPINION': 'Donner mon avis',

  'MEDIA': 'Médias non disponible !',

  'BENEFITS': 'Avantages marché',

  'ISSUE': 'Problématique',

  'SOLUTION': 'Solution',

  'MEDIAS': 'Médias',

  'STAGE': {
    'A': 'Idée',
    'B': 'Développement en cours',
    'C': 'Déjà disponible'
  },

  'ERROR': 'Nous n\'avons pas pu trouver cette innovation. Essayez de recharger la page !',

  'CONTACT_US': 'Contactez-nous',

  'DISCOVER_OTHER': 'Découvrez d\'autres projets',

  'SIDEBAR_BOT_MESSAGE': 'Décrivez d\'abord votre projet pour le prévisualiser.',

  'BACK': 'Liste des innovations',

  'SHARE_TITLE': 'PARTAGER',

  'RELATED': 'VOUS AIMEREZ PEUT-ÊTRE AUSSI'

};
